import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { InstitutionalContextProvider } from '../context/InstitutionalContext';
import { HomeComponent } from '../components/home/HomeComponent';

function HomeScreen() {
  const backImage = `/images/home_cover.png`;
  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <HomeComponent />
    </BaseLayoutComponent>
  );
}

export default HomeScreen;
