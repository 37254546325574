import styled from 'styled-components';
import { Row, Navbar } from 'react-bootstrap';

export const HeaderContainer = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 99;
  border-radius: 0px 0px 1.85vmin 1.85vmin;

  padding-right: 1px !important;
  padding-left: 1px !important;
  @media screen and (max-width: 1200px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
`;

export const HeaderRow = styled(Row)<{ backgroundColor: string }>`
  background: ${(props) => props.backgroundColor};
  border: 1px solid #ffffff33;
  box-shadow: 0px 4px 1.85vmin #4242421a;
  border-radius: 0px 0px 1.85vmin 1.85vmin;
  width: 99.9vw;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5vmin;
  padding-bottom: 0.5vmin;
  transition: 'all 5s ease';
  position: relative;

  .navbar {
    img {
      height: 6.5vmin;
    }
    .navbar-brand {
      color: #fff;
      margin-right: 5vmin;
      &:hover {
        color: #fff;
      }
    }
    .nav-link {
      font-weight: 400;
      font-size: 1.8vmin;

      line-height: 3vmin;
      text-align: center;
      color: #f3f3f3;
      margin-left: 1vmin;
      box-shadow: inset, 10px, 0px, 0px, 10px, #fff;

      &:hover {
        color: #fff;
        font-weight: 500;
      }
    }
    .nav-link.courses {
      width: 145px !important;
      margin-left: 20px;
      padding: 12px 32px !important;

      background: #72b81f;
      border-radius: 100px !important;

      font-weight: 600;
      font-size: 18px !important;
      line-height: 24px !important;
      text-align: center;
      color: #fff;

      &:hover {
        background-color: #fff;
        color: #72b81f;
      }
    }
    .nav-link.my-courses {
      width: 187px !important;
      padding: 12px 24px !important;

      background: #fff;
      border-radius: 100px !important;

      font-weight: 600;
      font-size: 18px !important;
      line-height: 24px !important;
      text-align: center;
      color: #72b81f;

      &:hover {
        background-color: #72b81f;
        color: #fff;
      }
    }
    .nav-link.cart {
      font-size: 30px;

      .cart-icon {
        position: relative;
      }

      .cart-icon .badge {
        position: absolute;
        border-radius: 50%;
        bottom: 16px;
        left: 20px;
        width: 25px;
        height: 25px;
        font-size: 14px;
        background: #ff3939;
        color: #fff;
        text-align: center;
      }
    }
    .dropdown-menu {
      margin-top: 4vmin;
      margin-left: 1.8vmin;

      background: ${(props) => props.backgroundColor};
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
      border-radius: 10px;
      a {
        padding-top: 1.5vmin;
        padding-bottom: 1.5vmin;
        padding-left: 1vmin;

        font-family: 'Steradian';
        font-style: normal;
        font-size: 1.8vmin;
        line-height: 23px;
        color: #ffffff;
        :hover {
          font-weight: 600;
          background-color: rgb(255 255 255 / 16%);
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    background: ${(props) => props.backgroundColor};
    width: 100vw !important;
    margin: 0px !important;
    border-radius: 0px 0px 3vmin 3vmin;
    position: absolute !important;
    z-index: 10 !important;
    .navbar {
      .navbar-collapse {
        padding-top: 3vmin;
        overflow: scroll;
        position: fixed !important;
        max-height: 89%;
        width: 100vw;
        top: 11vmin;
        background: linear-gradient(90deg, #72b81f -471.28%, #282828 198.72%);
        z-index: 1 !important;
        left: 0% !important;
        .nav-link {
          z-index: inherit;

          padding: 5vmin 4vmin;
          text-align: start !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          font-size: 3vmin !important;
          line-height: 3vmin !important;
          margin: 0px !important;
        }
      }
      .nav-item.dropdown {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
      }
      .dropdown-toggle.nav-link {
        border-bottom: none !important;
        &:after {
          position: relative;
          top: 1vmin;
          font-size: 5vmin;
        }
      }
      .dropdown-toggle.nav-link {
        &:after {
          position: absolute;
          left: 80% !important;
          top: 45% !important;
        }
      }

      .dropdown-toggle.nav-link.show {
        &:after {
          transform: rotate(180deg);
          top: 10% !important;
        }
      }
      .dropdown-menu {
        margin: 0px !important;

        background: linear-gradient(
          90deg,
          #72b81f -471.28%,
          #282828 198.72%
        ) !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        padding: 0px !important;
        a {
          padding: 5vmin 4vmin !important;

          font-size: 3vmin !important;
          line-height: 3vmin !important;
          :hover {
            font-weight: 600;
            background-color: rgb(255 255 255 / 16%);
          }
        }
      }
      .navbar-brand {
        img {
          height: 10vmin;
        }
      }
      .navbar-toggler-icon {
        width: 32px;
        height: 32px;
      }
      .nav-link.cart {
        .cart-icon {
          position: relative;
          font-size: 1.6rem;
        }

        .cart-icon .badge {
          position: absolute;
          border-radius: 50%;
          top: 4vmin !important;
          left: 4vmin !important;

          font-size: 3.2vmin !important;
          line-height: 3.2vmin !important;
          padding: 0.65vmin !important;
          background: #ff3939;
          color: #fff;
          text-align: center;
          font-size: 2vmin !important;
          font-family: 'Steradian';
        }
      }

      .nav-link.my-courses,
      .nav-link.courses {
        width: 100% !important;
        padding: 32px !important;
        border-radius: 0px !important;
        font-size: 18px !important;
        line-height: 24px !important;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    background: ${(props) => props.backgroundColor};

    width: 99.9vw;
    margin: 0px;
    border-radius: 0px 0px 4vmin 4vmin;
    position: absolute !important;
    z-index: 2 !important;
    .navbar {
      z-index: 2 !important;
      max-height: 70px;
      position: relative;

      .navbar-collapse {
        overflow: scroll;
        position: absolute !important;
        max-height: 90.5vh;
        width: 100vw;
        top: 0;
        background: linear-gradient(90deg, #72b81f -471.28%, #282828 198.72%);
        z-index: 1 !important;
        margin-top: 70px;

        left: calc(var(--bs-gutter-x) * -0.5 - 1px) !important;
        .nav-link {
          z-index: inherit;
          padding: 32px;
          text-align: start !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          font-size: 18px !important;
          margin: 0px !important;
        }
      }
      .nav-item.dropdown {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
      }
      .dropdown-toggle.nav-link {
        border-bottom: none !important;
        &:after {
          position: relative;
          top: 0;
          font-size: 2rem;
        }
      }

      .dropdown-toggle.nav-link {
        &:after {
          position: absolute;
          left: 87% !important;
          top: 45% !important;
        }
      }

      .dropdown-toggle.nav-link.show {
        &:after {
          transform: rotate(180deg);
          top: 10% !important;
        }
      }
      .dropdown-menu {
        margin: 0px !important;

        background: linear-gradient(
          90deg,
          #72b81f -471.28%,
          #282828 198.72%
        ) !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        padding: 0px !important;
        a {
          padding: 1.5rem 3rem !important;

          font-size: 18px !important;
          line-height: 24px !important;
          :hover {
            font-weight: 600;
            background-color: rgb(255 255 255 / 16%);
          }
        }
      }
      .nav-link.my-courses,
      .nav-link.courses {
        width: 100% !important;
        padding: 32px !important;
        border-radius: 0px !important;
        font-size: 18px !important;
        line-height: 24px !important;
      }

      .navbar-brand {
        img {
          height: 43px;
        }
      }
      .navbar-toggler {
        .navbar-toggler-icon {
          background-image: var(--bs-navbar-toggler-icon-bg);
        }
      }
      .navbar-toggler.collapsed {
        .navbar-toggler-icon {
          background-image: var(--bs-navbar-toggler-icon-bg);
        }
      }
      .navbar-toggler-icon {
        width: 32px;
        height: 32px;
      }
      .nav-link.cart {
        font-size: initial !important;
        border-bottom: none;
        padding: 0px;

        .cart-icon .badge {
          position: absolute;
          border-radius: 50%;
          top: -13px !important;
          left: 17px !important;

          width: 26px !important;
          height: 26px !important;
          padding: 0 !important;

          font-size: 14px !important;

          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
`;

export const Logo = styled.img`
  height: 6.5vmin;
`;

export const StyledNavbarToggle = styled(Navbar.Toggle)`
  border: none;
  &:focus {
    outline: none;
  }
`;

export const StyledNavbarCollapse = styled(Navbar.Collapse)`
  /* 
    padding-left: 5vmin;
    justify-content: flex-end; 
    */
`;
