import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, Col, Row } from 'react-bootstrap';
import { ContentCard, SeeMoreButton } from '../../styles/GlobalStyles';
import { CourseCarouselProps } from '../../domain/props/CourseCarousselProps';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NextArrow = styled.div`
  display: flex !important;
  background: #fff !important;
  border: 1px solid #000 !important;
  box-shadow: 0px 0.4vmin 2vmin rgba(66, 66, 66, 0.1);
  border-radius: 1vmin;
  height: 5vmin !important;
  width: 5vmin !important;
  z-index: 10;

  i {
    font-size: 3vmin;
    color: black;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-next {
    margin-top: 2.1vmin;
    left: 89%;
  }
  .slick-next:before {
    content: '';
  }
  .slick-prev {
    margin-top: 2.1vmin;
    left: -4%;
  }
  .slick-prev:before {
    content: '';
  }
`;

function CustomNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <NextArrow className={className} onClick={onClick}>
      <i className="mx-auto my-auto bi bi-arrow-right" />
    </NextArrow>
  );
}

function CustomPreviewArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <NextArrow className={className} onClick={onClick}>
      <i className="mx-auto my-auto bi bi-arrow-left" />
    </NextArrow>
  );
}

const settings = {
  dots: false,
  infinite: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPreviewArrow />,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CourseCaroussel: React.FC<CourseCarouselProps> = ({ courses }) => {
  return (
    <>
      {courses?.map((course) => (
        <Col md={4}>
          <ContentCard key={course.id} className="border-0">
            <Card.Img
              variant="top"
              src={course.coverImage}
              alt={course.title}
            />
            <Card.Body>
              <Card.Title className="carrousel">{course.title}</Card.Title>
              <Card.Text>
                {course.description.length > 100
                  ? course.description.substring(0, 100) + '...'
                  : course.description}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col md={7} className="pb-2">
                  <SeeMoreButton
                    as={Link}
                    to={`/curso-detalhes?courseId=${course.id}`}
                    className="btn btn-default"
                  >
                    <span className="mx-auto">Ver mais</span>
                  </SeeMoreButton>
                </Col>
              </Row>
            </Card.Footer>
          </ContentCard>
        </Col>
      ))}
    </>
  );
};

export default CourseCaroussel;
