export function FormatWorkload(workload: string): string {
  let workloadVector = workload.split(':');
  let workloadString = '';

  if (workloadVector[0] !== '00') {
    workloadString = workloadString.concat(workloadVector[0]).concat(' h ');
  }

  if (workloadVector[1] !== '00') {
    workloadString = workloadString.concat(workloadVector[1]).concat(' min');
  }
  return workloadString;
}

export function FormatWorkloadHours(workload: string): string {
  let workloadVector = workload.split(':');
  let workloadString = '';

  if (workloadVector[0] !== '00') {
    workloadString = workloadString.concat(workloadVector[0]).concat('h');
  }

  return workloadString;
}
