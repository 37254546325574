import { Col, Row } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';

import { useCourseScreenContext } from '../../context/CourseScreenContext';
import { CourseDetailsCardComponent } from './CourseDetailsCardComponent';
import { NewCoursesButton } from '../../styles/CoursesStyles';
import BrainzPagination from '../BrainzPagination';

export function AvaliableCoursesComponent() {
  const {
    courses,
    isLoadingCourse,
    paging,
    setPaginationParams,
    errorMessage,
  } = useCourseScreenContext();

  return (
    <Row style={{ marginTop: '16px' }}>
      <Col xs={12} xl={8} lg={7} className="mx-md-auto">
        <Row className="mt-5 mb-5">
          <Col>
            <NewCoursesButton>Ordem Alfabética</NewCoursesButton>
          </Col>
        </Row>

        <Row className="mt-5 mb-5" style={{ minHeight: '40vh' }}>
          {isLoadingCourse ? (
            <Col xs={'auto'} className="mx-auto my-auto">
              <TailSpin color={'#72b81f'} height={100} width={100} />
            </Col>
          ) : courses.length ? (
            <>
              {courses?.map((course) => (
                <Col md={6} xl={4}>
                  <CourseDetailsCardComponent course={course} key={course.id} />
                </Col>
              ))}
              <Col md={12}>
                {paging && (
                  <BrainzPagination
                    setPaginationParams={setPaginationParams}
                    paging={paging}
                  />
                )}
              </Col>
            </>
          ) : (
            <Col xs={'auto'} className="mx-auto my-auto">
              {errorMessage ? errorMessage : 'Nenhum curso foi encontrado.'}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
