import BaseLayoutComponent from '../../components/layouts/BaseLayoutComponent';
import { useInstitutionalContext } from '../../context/InstitutionalContext';
import { ContractManagementComponent } from '../../components/research/contractManagement/ContractManagementComponent';

export function ContractManagementScreen({ }: ContractManagementScreenProps) {
  const { institutionalPageInfo } = useInstitutionalContext();

  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <ContractManagementComponent
        contractManagement={institutionalPageInfo!.search.contractManagement}
      />
    </BaseLayoutComponent>
  );
}

interface ContractManagementScreenProps { }
