// @ts-nocheck
import { Row, Col, Card } from 'react-bootstrap';

import { H1Styles, WeOfferCard } from '../../styles/GlobalStyles';
import booksCertified from '../../assets/image/booksCertified.png';
import books from '../../assets/image/book.png';
import diploma from '../../assets/image/diploma.png';
import multipleDevices from '../../assets/image/multipleDevices.png';

export function WhatWeOfferComponent() {
  const data = [
    {
      id: 1,
      title: 'Aprendizado a qualquer hora e em qualquer lugar',
      description: 'No seu celular ou computador',
      image: multipleDevices,
      altImage: 'ícone com vários dispositivos',
    },
    {
      id: 2,
      title: 'Conteúdo constantemente revisado e atualizado',
      description: 'Para diversas áreas de conhecimento',
      image: booksCertified,
      altImage: 'Icone com itens de aprendizagem',
    },
    {
      id: 3,
      title: 'Conteúdo produzido por profissionais altamente qualificados',
      description:
        'Especialista, mestres e doutores com ampla vivência profissional',
      image: books,
      altImage: 'Icone de livros',
    },
    {
      id: 4,
      title: 'Certificado',
      description: 'Para comprovar o aprendizado',
      image: diploma,
      altImage: 'ícone do cerficiado',
      finalText: '*disponível para usuários cadastrados',
    },
  ];
  return (
    <Row className="mb-5">
      <Col xl={9} className="mx-auto  mt-5 mt-8">
        <Row>
          <Col>
            <H1Styles className="mb-4">O que oferecemos</H1Styles>
            <Row>
              {data?.map((item) => {
                return (
                  <Col lg={6} key={item.id}>
                    <WeOfferCard className="my-3">
                      <Card.Body>
                        <Row className="d-flex align-items-center h-100 ">
                          <Col xs={3} className="d-md-flex justify-content-md-center justify-content-xl-start">
                            <img src={item.image} alt={item.altImage} />
                          </Col>
                          <Col md={9} className="">
                            <Row className="mb-1">
                              <Col className="card-title">{item.title}</Col>
                            </Row>
                            <Row>
                              <Col>
                                <span className="bg-color-offer">
                                  {item.description}
                                </span>
                              </Col>
                            </Row>
                            {item.finalText && (
                              <Row>
                                <Col>
                                  <span className="user-account-ok">
                                    {item.finalText}
                                  </span>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </WeOfferCard>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
