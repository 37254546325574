import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';

import { CollapsableList } from '../../domain/util/CollapsableList';
import {
  CourseCertificateTag,
  ModuleCardContainer,
  ModuleCardItem,
  ModuleCardItemTitle,
  ModuleLessonTitle,
  TransparentOkButton,
} from '../../styles/CourseDetailsStyles';
import {
  CardStyles,
  H1Styles,
  H3Styles,
  PBlackStyles,
  SecondaryButton,
} from '../../styles/GlobalStyles';
import { useCourseDetailContext } from '../../context/CourseDetailContext';
import { FormatWorkload } from '../../domain/util/FormatWorkload';

import { CourseDetailMainJumbotronComponent } from './CourseDetailMainJumbotronComponent';
import styled from 'styled-components';
import { SubscribeCourseMobileComponent } from './SubscribeCourseMobileComponent';
import { CourseToCartConfirmationModal } from './CourseToCartConfirmationModal';
import { ReadMore } from '../ReadMore';
import { CourseDetailsViewModel } from '../../domain/models/Course';

export function CourseDetailComponent() {
  const { course, subscribeToCourse, isInCart } = useCourseDetailContext();

  const [reload, callReload] = useState(false);
  const [collapsableListOfModules, setCollapsableList] = useState<
    CollapsableList<any>
  >(new CollapsableList<any>(course.modules));
  const [isFreeCourse, setIsFreeCourse] = useState<boolean>(false);
  const [isZeroCourse, setIsZeroCourse] = useState<boolean>(false);

  function handleCollapseMenuItemChange(index: number) {
    let newModules = collapsableListOfModules;
    newModules.handleCollapseMenuItemAnyCanBeOpen(index);

    setCollapsableList(newModules);
    callReload(!reload);
  }

  useEffect(() => {
    if (course?.priceWithDiscount === 0 || course?.price === 0) {
      setIsZeroCourse(true);

      if (course?.priceWithDiscount === course?.price || course?.price === 0) {
        setIsFreeCourse(true);
      }
    }
  }, []);

  return (
    <CourseDetailStyles fluid={true}>
      <CourseDetailMainJumbotronComponent course={course} />

      <SubscribeCourseMobileComponent />
      <Row>
        <Col xs={11} xl={8} className="mx-auto mt-xl-5">
          <Row>
            <Col xl={7} className="mt-5 mb-5 me-auto">
              <Row>
                <H3Styles>Objetivo do Curso</H3Styles>
                <PBlackStyles>{course.description}</PBlackStyles>
              </Row>

              <Row className="mt-4">
                <H3Styles>Apresentação</H3Styles>
                <ReadMore completeText={course.presentation} />
              </Row>

              <Row className="mt-1">
                <H3Styles>Recursos Didáticos</H3Styles>
                <PBlackStyles className="mb-0">
                  1. Material Didatico
                </PBlackStyles>
                <PBlackStyles>2. Vídeo aulas</PBlackStyles>
              </Row>

              <Row className="mt-4">
                <H3Styles>Critérios de Avaliação</H3Styles>
                <PBlackStyles>
                  Questionário pré e pós (plataforma educacional)
                </PBlackStyles>
              </Row>

              <Row className="mt-4">
                <H3Styles>Módulos</H3Styles>
                <PBlackStyles>
                  O conteúdo do curso é dividido em módulos. Veja o que você vai
                  aprender em cada um deles:
                </PBlackStyles>
              </Row>

              <Row>
                {collapsableListOfModules.list.map((module, index) => {
                  return (
                    <ModuleCardContainer
                      key={'module-' + index}
                      className="my-2 pb-3"
                    >
                      <ModuleCardItem className="w-100 py-1">
                        <TransparentOkButton
                          className={'btn bg-transparent text-left'}
                          onClick={() => handleCollapseMenuItemChange(index)}
                        >
                          <Card.Header className={'py-2'}>
                            <Row>
                              <Col
                                xs={8}
                                xl={10}
                                className="me-auto my-auto text-start"
                              >
                                <ModuleCardItemTitle className="d-none d-xl-block">
                                  {module.item.title}
                                </ModuleCardItemTitle>
                                <ModuleCardItemTitle className="d-block d-xl-none text-left">
                                  {module.item.title.length > 30
                                    ? module.item.title.substring(0, 30) + '...'
                                    : module.item.title}
                                </ModuleCardItemTitle>
                              </Col>
                              {module.item.hasCertificate ? (
                                <Col xs={1} md={1} className="my-auto">
                                  <ModuleCardItemTitle>
                                    <i className="bi bi-award" />
                                  </ModuleCardItemTitle>
                                </Col>
                              ) : (
                                <Col
                                  xs={1}
                                  xl={1}
                                  className="d-xs-none d-sm-none d-xl-block"
                                />
                              )}

                              <Col xs={1} xl={1} className="my-auto">
                                {module.collapsed ? (
                                  <i className="bi bi-chevron-up" />
                                ) : (
                                  <i className="bi bi-chevron-down " />
                                )}
                              </Col>
                            </Row>
                          </Card.Header>
                        </TransparentOkButton>

                        {module.collapsed ? (
                          <>
                            <Card.Body className={'pt-0 pb-1 px-4'}>
                              <ul className="pl-2 pl-lg-4">
                                {module.item.hasCertificate ? (
                                  <li className="ml-3 mxText">
                                    <ModuleCertificateAwardTag />
                                  </li>
                                ) : (
                                  <></>
                                )}

                                {module.item.moduleContents.map(
                                  (content: any, contentIndex: any) => {
                                    return (
                                      <li
                                        key={'lesson-' + contentIndex}
                                        className="my-1"
                                      >
                                        <Row>
                                          <Col xs={12} xl={8}>
                                            <ModuleLessonTitle className="mt-1">
                                              {content.title}
                                            </ModuleLessonTitle>
                                          </Col>
                                        </Row>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </Card.Body>
                          </>
                        ) : (
                          <></>
                        )}
                      </ModuleCardItem>
                    </ModuleCardContainer>
                  );
                })}
              </Row>
              {course.enableCertificate && (
                <Row className="mt-4">
                  <H3Styles>Certificados</H3Styles>
                  <PBlackStyles>
                    Alguns módulos do curso podem oferecer certificados. Ao
                    completar todos os módulos, você também receberá um
                    certificado referente ao curso inteiro.
                  </PBlackStyles>
                </Row>
              )}
            </Col>

            <Col xl={4} className="d-none d-xl-block course-value mt-5 mb-5">
              <Row className="mb-3">
                <Col xl={12}>
                  <SecondaryButton
                    className="w-100"
                    onClick={() => subscribeToCourse(isZeroCourse)}
                    disabled={isInCart()}
                  >
                    {isInCart() ? (
                      <>Adicionado ao carrinho</>
                    ) : isZeroCourse ? (
                      <>Inscrever-se no curso</>
                    ) : (
                      <>Adicionar ao carrinho</>
                    )}
                  </SecondaryButton>
                </Col>
                <Col xl={12} className="mt-5">
                  <H3Styles>Coordenador do curso</H3Styles>
                </Col>
                <Col xl={12}>
                  <PBlackStyles>
                    {course.courseResponsible !== null
                      ? course.courseResponsible
                      : course.responsibleUserCourse}
                  </PBlackStyles>
                </Col>
              </Row>

              <Row className="d-none d-lg-flex">
                <Col xl={6}>
                  <Row>
                    <H3Styles>Carga Horária</H3Styles>
                    <PBlackStyles>
                      {FormatWorkload(course.workLoad)}
                    </PBlackStyles>
                  </Row>
                </Col>
                <Col xl={6}>
                  <Row>
                    <H3Styles>Publicação</H3Styles>
                    <PBlackStyles>
                      {moment(course.publishedDate).format('DD/MM/YYYY')}
                    </PBlackStyles>
                  </Row>
                </Col>
              </Row>
              {!isFreeCourse && (
                <CoursePriceContainer className="d-none d-xl-flex">
                  <Col className="mt-3">
                    <CardStyles>
                      <Card.Body className="px-4 py-4">
                        <Row>
                          <H3Styles className="mb-3">Valor do curso</H3Styles>
                        </Row>
                        {handleRenderPriceContent(course)}
                      </Card.Body>
                    </CardStyles>
                  </Col>
                </CoursePriceContainer>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <CourseToCartConfirmationModal />
    </CourseDetailStyles>
  );
}

export function handleRenderPriceContent(course: CourseDetailsViewModel) {
  // Curso tem desconto
  if (course?.priceWithDiscount || course?.priceWithDiscount === 0) {
    return (
      <>
        <Row>
          <div className="originalPrice">R$ {course.price.toFixed(2)}</div>
        </Row>
        <Row>
          <H1Styles className="newPrice">
            R$ {course?.priceWithDiscount?.toFixed(2)}
          </H1Styles>
        </Row>
      </>
    );
  } else {
    // Curso não tem desconto
    return (
      <Row>
        <H1Styles>R$ {course.price.toFixed(2)}</H1Styles>
      </Row>
    );
  }
}

function ModuleCertificateAwardTag() {
  return (
    <CourseCertificateTag>
      <i className="bi bi-award" /> Este módulo oferece certificado!
    </CourseCertificateTag>
  );
}

const CourseDetailStyles = styled(Container)`
  .course-value {
    top: 12vmin;
    position: sticky !important;
  }
`;

export const CoursePriceContainer = styled(Row)`
  .originalPrice {
    color: #a0a0a0;
    font-size: 20px;
    font-weight: 500;
    text-decoration: line-through;
    margin-bottom: 8px;
  }

  .newPrice {
    color: #24a31a;
  }
`;
