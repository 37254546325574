import { useContext, useEffect, useState } from 'react';
import { Col, Card, Row } from 'react-bootstrap';
import styled from 'styled-components';

import {
  H1Styles,
  H3Styles,
  SecondaryButton,
  TotalValueCardStyles,
} from '../../styles/GlobalStyles';
import { CartContext } from '../../context/CartContext';
import { useInstitutionalAuthContext } from '../../context/InstitutionalAuthContext';
import { CoursePriceContainer } from '../courseDetail/CourseDetailComponent';

export function handlePriceContentCart(amount: any) {
  // Carrinho tem desconto
  if (amount?.TotalAmount !== amount?.TotalAmountOriginal) {
    return (
      <>
        <Row>
          <H1Styles className="mb-2 originalPrice">
            R$ {amount?.TotalAmountOriginal.toFixed(2)}
          </H1Styles>
        </Row>
        <Row>
          <H1Styles className="newPrice mb-4">
            R$ {amount.TotalAmount.toFixed(2)}
          </H1Styles>
        </Row>
      </>
    );
  } else {
    // Curso não tem desconto
    return (
      <H1Styles className="mb-lg-4">
        R$ {amount.TotalAmountOriginal.toFixed(2)}
      </H1Styles>
    );
  }
}

const TotalCardValueComponentStyles = styled(Col)`
  @media screen and (max-width: 1000px) {
    background: #e9e9e9;
  }
`;

export function TotalCardValueComponent() {
  const { showModal } = useInstitutionalAuthContext();
  const { TotalAmountOriginal, TotalAmount, checkIsFreeCart, cartItems } =
    useContext(CartContext);

  const [isFreeCourse, setIsFreeCourse] = useState<boolean>(false);

  const amount = {
    TotalAmountOriginal,
    TotalAmount,
  };

  useEffect(() => {
    setIsFreeCourse(checkIsFreeCart());
  }, [cartItems]);

  const labelBuyButton = isFreeCourse ? 'Continuar' : 'Finalizar compra';

  return (
    <TotalCardValueComponentStyles xl={4} className="py-4 py-lg-0">
      <TotalValueCardStyles>
        <Card.Body
          className={`m-2 m-lg-4 ${isFreeCourse && 'd-none d-lg-flex'}`}
        >
          {!isFreeCourse && (
            <CoursePriceContainer>
              <H3Styles className="mb-lg-4">Total</H3Styles>
              {handlePriceContentCart(amount)}
            </CoursePriceContainer>
          )}
          <SecondaryButton
            onClick={() => showModal()}
            className="d-none d-lg-block w-100"
          >
            {labelBuyButton}
          </SecondaryButton>
        </Card.Body>
      </TotalValueCardStyles>

      <SecondaryButton
        onClick={() => showModal()}
        className="mt-4 d-lg-none w-100"
      >
        {labelBuyButton}
      </SecondaryButton>
    </TotalCardValueComponentStyles>
  );
}
