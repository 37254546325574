import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const InstitutionalInformationComponentStyles = styled(Row)`
  background: #fff;

  padding-top: 145px;
  padding-bottom: 145px;
  a {
    text-decoration: none;
  }
`;

export const SendMessageComponentStyles = styled(Row)`
  background: #e9e9e9;
  padding-top: 55px;
  padding-bottom: 0;
  form {
    width: 90%;

    button {
      margin-top: 1rem;
      height: 50px;
      font-size: 20px;
      line-height: 28px;
    }
    input {
      border: none;
      outline: unset;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(66, 66, 66, 0.1);

      margin-bottom: 24px;
      height: 70px;
      border-radius: 100px;
    }
    input.message {
      border-radius: 20px;
      height: 22vmin;

      div:nth-child(2) {
        height: 100%;
        background: red !important;
      }
    }
  }

  img {
    width: 600px;
  }
  /* Mobile and Tablet */
  @media screen and (max-width: 1210px) {
    padding: 0 12px;

    h1 {
      max-width: 80%;
      padding-top: 2rem;
    }

    form {
      width: 100%;
      margin-top: 15px;

      input {
        margin-bottom: 1rem;
        min-height: 70px;
        font-size: 18px;
      }

      input[type='textarea'] {
        min-height: 215px !important;
      }

      button {
        margin-top: 1rem;
        margin-bottom: 30px;
        height: 50px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
`;
