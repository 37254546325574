import BaseLayoutComponent from "../components/layouts/BaseLayoutComponent";
import { CoremuComponent } from "../components/teaching/CoremuComponent";

export default function CoremuScreen() {
  return (
      <BaseLayoutComponent showInstitutionalInfo>
        <CoremuComponent />
      </BaseLayoutComponent>
  );
}
