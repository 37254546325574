import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { CardStyles, RoundedImage } from './GlobalStyles';

export const ResearchPageTitleStyles = styled(Row)<{
  backGroundImage: string;
  bottom?: string;
}>`
  background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.86) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${(props) => props.backGroundImage});
  min-height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const UnpublishedSientificResearchImage = styled(RoundedImage)`
  width: 60vmin;
  height: 30vmin;
`;
export const UnpublishedSientificResearchComponentStyles = styled(Row)`
  a {
    text-decoration: none;
    width: 20vmin;
    height: 5vmin;

    margin-left: 2vmin;
    padding: 15px 50px;

    background: #000;
    border-radius: 10vmin;

    font-family: 'Steradian', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2vmin;
    line-height: 3vmin;
    text-align: center;
    color: #fff;

    &:hover {
      text-decoration: underline;
      background-color: #fff;
      color: #000;
    }
  }
`;

export const ResearchStructureCarrousselComponentStyles = styled(Row)`
  background: #fff;
  img {
    object-fit: cover;
  }

  .awssld__content {
    border-radius: 10px;
    background: #fff;
  }

  .awssld__prev {
    border-radius: 0 8px 8px 0;
    padding-right: 18px;
  }

  .awssld__next {
    border-radius: 8px 0 0 8px;
    padding-left: 18px;
  }

  @media screen and (max-width: 600px) {
    .awssld__controls {
      display: none;
    }
    .awssld__bullets button {
      width: 12px !important;
      height: 12px !important;
      bottom: -35px !important;
    }

    .descriptionSlider {
      margin-top: 3rem !important;
    }
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    .awssld__controls {
      display: none;
    }
    .awssld__bullets button {
      width: 12px !important;
      height: 12px !important;
      bottom: -35px !important;
    }

    .descriptionSlider {
      margin-top: 3rem !important;
    }
  }

  @media screen and (max-width: 1199px) {
    .carrousselMainCol {
      margin-top: 0;
      padding-top: 0 !important;
    }

    .carrousselTitle {
      text-align: left !important;
      margin-bottom: 21px !important;
    }
  }
`;

export const DevelopedResearchNewsImage = styled.img`
  width: 51vmin;
  height: 32vmin;
  border-radius: 10px;
`;
export const ResearchBigCardStyles = styled(CardStyles)`
  top: -3rem;
  max-height: 57vmin;

  img {
    object-fit: cover;
    max-height: 57vmin;
    height: auto;
    width: 100% !important;
    border-top-left-radius: 1vmin;
    border-bottom-left-radius: 1vmin;
  }

  .researchResumeTextContainer {
    .descriptionTextContainer {
      margin-top: 3vmin;
      max-height: 30vmin;
      overflow-y: auto;
    }
    margin-top: 8vmin;
  }

  .card-body {
    padding: 0 !important;
  }

  @media screen and (max-width: 600px) {
    top: -2.5rem;
    height: auto;
    max-height: unset !important;
    box-shadow: 0px 4px 20px 0px rgba(66, 66, 66, 0.1);
    border-radius: 10px 10px 0 0;

    img {
      height: 139px;
      border-radius: 10px 10px 0 0;
    }

    .researchResumeImageContainer,
    .researchResumeCardContainer {
      margin-bottom: 0;
    }

    .researchResumeTextContainer {
      margin-top: 22px;
      padding: 0 24px !important;

      .descriptionTextContainer {
        padding: 0 24px;
        margin-top: 12px;
        max-height: unset;
        overflow: unset;
      }

      .titleTextContainer {
        font-size: 28px;
        line-height: 37px;
        margin-bottom: 0 !important;
        padding: 0 12px;
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1210px) {
    top: -2.5rem;
    height: auto;
    max-height: unset !important;
    box-shadow: 0px 4px 20px 0px rgba(66, 66, 66, 0.1);

    img {
      height: 180px;
      border-radius: 10px 10px 0 0;
    }

    .researchResumeImageContainer,
    .researchResumeCardContainer {
      margin-bottom: 0;
    }

    .researchResumeTextContainer {
      margin-top: 22px;
      padding: 0 24px !important;

      .descriptionTextContainer {
        padding: 0 24px;
        margin-top: 12px;
        max-height: unset;
      }

      .titleTextContainer {
        font-size: 28px;
        line-height: 37px;
        margin-bottom: 0 !important;
        padding: 0 12px;
      }
    }
  }
`;
