import { Col, Modal, ModalBody, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BaseModalStyles, H1Styles, SecondaryButton } from '../../styles/GlobalStyles';
import { useCourseDetailContext } from '../../context/CourseDetailContext';
import { CourseCartCardComponent } from '../cart/CourseCartCardComponent';

export function CourseToCartConfirmationModal() {
  const navigate = useNavigate();
  const { course, showConfirmationModal, handleCloseConfirmationModal
  } = useCourseDetailContext();

  function redirectToCart() {
    navigate('/carrinho');
  }

  return (
    <BaseModalStyles
      onHide={handleCloseConfirmationModal}
      show={showConfirmationModal}
      size={'lg'}
      centered
    >
      <Modal.Header closeButton>
        <H1Styles className='d-lg-none'>
          Adicionado ao carrinho
        </H1Styles>
      </Modal.Header>
      <ModalBody className='m-3'>
        <Row className='d-none d-lg-block mb-5'>
          <H1Styles>
            Adicionado ao carrinho
          </H1Styles>
        </Row>
        <Row className='mb-5'>
          <CourseCartCardComponent course={course} />
        </Row>
        <Row>
          <Col lg={4} className='mx-auto'>
            <SecondaryButton
              onClick={redirectToCart}
              className='w-100'>
              Ir ao carrinho
            </SecondaryButton>
          </Col>
        </Row>

      </ModalBody>
    </BaseModalStyles>);
}
