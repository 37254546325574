import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { InputStyles, SecondaryButton } from '../../styles/GlobalStyles';
import { useCourseScreenContext } from '../../context/CourseScreenContext';

export function CourseSearchBarComponent() {
  const { setSearchValue } = useCourseScreenContext();
  const [courseInput, setCourseInput] = useState('');

  const handleSearch = () => {
    setSearchValue(courseInput);
  };

  return (
    <CourseSearchBarComponentStyles className="mb-3">
      <section>
        <Col xl={8} className="mx-auto">
          <Row>
            <Col className="search-input">
              <InputStyles
                type="text"
                placeholder="O que você quer aprender hoje?"
                value={courseInput}
                onChange={(e) => setCourseInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSearch();
                  }
                }}
              />
              <i className="bi bi-search" />
              <SecondaryButton
                className="search-button d-none d-xl-block"
                onClick={() => handleSearch()}
              >
                Procurar
              </SecondaryButton>
            </Col>
          </Row>
          <Row className={` ${!isMobile ? 'd-xl-none' : ''}  mt-3`}>
            <Col xs={12}>
              <SecondaryButton
                className="search-button"
                onClick={() => handleSearch()}
              >
                Procurar
              </SecondaryButton>
            </Col>
          </Row>
        </Col>
      </section>
    </CourseSearchBarComponentStyles>
  );
}
const CourseSearchBarComponentStyles = styled(Row)`
  display: flex;
  align-items: center;
  position: relative;

  section {
    position: absolute;
    left: 0;
    right: 0;
    top: -2rem;
  }

  .search-button {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    border-radius: 100px;
    width: 252px;
    height: 70px;

    position: absolute;
    right: 17%;
    top: 0s;
    bottom: 0;
  }
  .bi-search {
    position: absolute;
    z-index: 6;
    top: 25%;
    left: 18.7%;
    font-size: 24px;
    color: #424242;
  }
  input {
    padding-left: 7vmin;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
    box-shadow: 0px 4px 20px 0px rgba(66, 66, 66, 0.1);
  }
  /* @media screen and (max-width: 1199px) {
    .search-input {
      display: flex;
      align-items: center;
      position: relative;
    }
    position: relative;
    top: 23vmin;
    .bi-search {
      position: absolute;
      z-index: 6;
      left: 7%;
      font-size: 6.5vmin;
      color: #424242;
    }
    input {
      height: 16vmin;

      padding-left: 15%;
      padding-right: 4.8vmin;
      border-radius: 10vmin;
      box-shadow: 0px 4px 20px 0px rgba(66, 66, 66, 0.1);
      font-size: 4vmin;
      &::placeholder {
        font-size: 4vmin;
      }
    }
    .search-button {
      font-size: 4vmin;
      text-align: center;
      border-radius: 10vmin;
      width: 100%;
      padding: 4vmin;
      position: relative;
      right: auto;
      top: auto;
      bottom: auto;
    }
  } */

  @media screen and (max-width: 1199px) {
    input {
      height: 70px;
      font-size: 1rem;
      padding-left: 64px;
      padding-right: 32px;

      &::placeholder {
        font-size: 1rem;
      }
    }

    .bi-search {
      position: absolute;
      left: 36px;
      top: 15px;
      font-size: 30px;
    }
    .search-button {
      font-size: 20px;
      text-align: center;
      width: 100%;
      margin-top: 1rem;
      padding: 12px 32px;
      height: 50px;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
    }
  }
`;
