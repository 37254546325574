import { ModalBody } from 'react-bootstrap';
import { BaseModalStyles } from '../../../styles/GlobalStyles';
import styled from 'styled-components';

export function BasePillarsDocumentModal({
  title,
  link,
  showModal,
  onHide,
}: BasePillarsDocumentModalProps) {
  return (
    <BasePillarsDocumentModalStyles
      centered
      show={showModal}
      onHide={onHide}
      className="d-flex justify-content-center"
    >
      <ModalBody className="my-auto text-center">
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <a href={link} target="blank" download>
          Download
        </a>
      </ModalBody>
    </BasePillarsDocumentModalStyles>
  );
}
interface BasePillarsDocumentModalProps {
  title: string;
  link: string;
  showModal: boolean;
  onHide: () => void;
}

const BasePillarsDocumentModalStyles = styled(BaseModalStyles)`
  .modal-body {
    padding: 3rem;
  }
  h2 {
    color: #424242;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin-bottom: 2.5rem;
  }
  a {
    text-decoration: none;
    border: 1px solid #72b81f;
    padding: 1rem 32px;
    border-radius: 100px;

    background: #72b81f;
    color: #fff;

    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 500;

    text-align: center;

    transition: all 0.2s ease-in-out;

    &:hover {
      background: transparent;
      color: #72b81f
    }
  }

  @media screen and (max-width: 1210px) {
    .modal-body {
      padding: 2.5rem;
    }

    h2 {
      font-size: 1.3rem;
      line-height: 2rem;
    }

    a {
      padding: 1rem 32px;
      font-size: 1rem;
    }
  }
`;
