import { Card, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { PBlackStyles } from '../../styles/GlobalStyles';
import { ResearchBigCardStyles } from '../../styles/ResearchStyles';

interface ResearchResumeComponentProps {
  title: string;
  image2: string;
  description: string;
  titleMargin?: boolean;
}

export default function ResearchResumeComponent({
  title,
  image2,
  description,
  titleMargin = true,
}: ResearchResumeComponentProps) {
  return (
    <ResearchResumeRow>
      <Col xs={11} lg={10} className="mx-auto">
        <ResearchBigCardStyles className="border-0">
          <Card.Body className="ps-0 pt-0 researchResumeCardContainer">
            <Row>
              <Col xl={5} className="ps-xl-0 pt-0 researchResumeImageContainer">
                <img src={image2} alt="Fundação Miguel Couto" />
              </Col>
              <Col className="px-lg-5 mx-lg-4 researchResumeTextContainer">
                <Row>
                  <Col>
                    <TitleStyles
                      className={
                        titleMargin
                          ? 'mb-3 titleTextContainer'
                          : 'mb-1 titleTextContainer'
                      }
                    >
                      {title}
                    </TitleStyles>
                  </Col>
                </Row>
                <Row>
                  <PBlackStyles className="descriptionTextContainer">
                    {description}
                  </PBlackStyles>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </ResearchBigCardStyles>
      </Col>
    </ResearchResumeRow>
  );
}

export const ResearchResumeRow = styled(Row)`
  @media screen and (max-width: 1199px) {
    .descriptionTextContainer {
      font-size: 1rem;
      background: transparent;
    }
  }
`;

export const TitleStyles = styled.p`
  font-weight: 600;

  font-size: 40px;
  line-height: 53px;

  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #424242;
`;
