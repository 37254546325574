import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { CourseDetailComponent } from '../components/courseDetail/CourseDetailComponent';
import { CourseDetailContextProvider } from '../context/CourseDetailContext';

export default function CourseDetailScreen() {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('courseId');

  if (id === null) {
    window.location.href = window.location.origin;
  }

  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <CourseDetailContextProvider courseId={id as string}>
        <CourseDetailComponent />
      </CourseDetailContextProvider>
    </BaseLayoutComponent>
  );
}
