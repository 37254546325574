import { Col, Row } from 'react-bootstrap';
import AwesomeSlider from 'react-awesome-slider';

import { OurStructureViewModel } from '../../../domain/models/OurStructureViewModel';
import { H1Styles, H3Styles } from '../../../styles/GlobalStyles';
import { ResearchStructureCarrousselComponentStyles } from '../../../styles/ResearchStyles';
import "./sliderStyles.css";
import { isMobileOnly } from 'react-device-detect';

interface ResearchStructureCarrousselComponentProps
  extends OurStructureViewModel { }

export function ResearchStructureCarrousselComponent({
  title,
  description,
  images,
}: ResearchStructureCarrousselComponentProps) {
  return (
    <ResearchStructureCarrousselComponentStyles>
      <Col xs={12} xl={6} className="mx-xl-auto mt-3 pt-5 carrousselMainCol">
        <Row>
          <Col
            xs={11}
            xl={6}
            className="mx-auto mb-4 text-center carrousselTitle"
          >
            <H1Styles>{title}</H1Styles>
          </Col>
        </Row>
        <Row>
          <Col xs={11} xl={12} className="mx-auto">
            <AwesomeSlider bullets={isMobileOnly ? true : false}>
              {images?.map((imagem) => {
                return <div data-src={imagem.link} />;
              })}
            </AwesomeSlider>
          </Col>

        </Row>
        <Row>
          <Col className="text-center mt-4 mb-5 descriptionSlider">
            <H3Styles> {description} </H3Styles>
          </Col>
        </Row>
      </Col>
    </ResearchStructureCarrousselComponentStyles>
  );
}
