import { useContext } from 'react';
import { CartContext } from '../../context/CartContext';
import { Navbar, Col, Container, NavDropdown, Nav } from 'react-bootstrap';
import {
  HeaderContainer,
  HeaderRow,
  Logo,
  StyledNavbarToggle,
  StyledNavbarCollapse,
} from '../../styles/HeaderStyles';
import { useInstitutionalAuthContext } from '../../context/InstitutionalAuthContext';
import { Link } from 'react-router-dom';

interface HeaderProps {
  isTopScroll: boolean;
}

export default function HeaderComponent({ isTopScroll }: HeaderProps) {
  const { showModal } = useInstitutionalAuthContext();
  const appLogo = `/images/menu_logo.svg`;
  const { cartItems } = useContext(CartContext);
  const backgroundColor = isTopScroll ? '#42424299' : '#424242';

  function redirectToCL() {
    window.location.href = process.env.REACT_APP_TRILHAS_WEBAPP_URL as string;
  }

  return (
    <HeaderContainer as={Container} fluid={true}>
      <HeaderRow backgroundColor={backgroundColor}>
        <Col sm={12} lg={11} xl={11} className="mx-auto">
          <Navbar expand="xl" variant="dark">
            <Nav.Link
              className="cart d-block d-xl-none"
              as={Link}
              to="/carrinho"
            >
              <div className="cart-icon">
                <i className="bi bi-cart2" />
                {cartItems.length > 0 ? (
                  <div className="badge">{cartItems.length}</div>
                ) : null}
              </div>
            </Nav.Link>
            <Navbar.Brand className="mx-auto" as={Link} to="/">
              <Logo src={appLogo} />{' '}
            </Navbar.Brand>
            <StyledNavbarToggle aria-controls="basic-navbar-nav" />
            <StyledNavbarCollapse id="basic-navbar-nav">
              <Nav className="mx-auto d-xl-flex align-items-xl-center">
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/portal-transparencia">
                  Portal da Transparência
                </Nav.Link>
                <Nav.Link as={Link} to="/quem-somos">
                  FMC
                </Nav.Link>
                <NavDropdown title="Ensino" className="ensino">
                  <NavDropdown.Item as={Link} to="/ensino">
                    Apresentação
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/coremu">
                    COREMU
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/programas">
                    Programas
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Pesquisa" className="pesquisa">
                  <NavDropdown.Item as={Link} to="/gestao-convenios-contratos">
                    Gestão de convênios e contratos
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/pesquisa-clinica">
                    Pesquisa Clínica
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/pesquisa">
                    Pesquisa e Inovação
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/eventos">
                  Eventos
                </Nav.Link>
                <Nav.Link as={Link} to="/fale-conosco">
                  Fale Conosco
                </Nav.Link>
                <Nav.Link
                  className="my-courses"
                  onClick={() => {
                    redirectToCL();
                  }}
                >
                  Meus cursos
                </Nav.Link>
                <Nav.Link className="courses" as={Link} to="/cursos">
                  Cursos
                </Nav.Link>
                <Nav.Link
                  className="cart d-none d-xl-block"
                  as={Link}
                  to="/carrinho"
                >
                  <div className="cart-icon">
                    <i className="bi bi-cart2" />
                    {cartItems.length > 0 ? (
                      <div className="badge">{cartItems.length}</div>
                    ) : null}
                  </div>
                </Nav.Link>
              </Nav>
            </StyledNavbarCollapse>
          </Navbar>
        </Col>
      </HeaderRow>
    </HeaderContainer>
  );
}
