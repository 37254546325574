import styled from 'styled-components';

import { PBlackStyles } from '../../styles/GlobalStyles';
import { handleFormatTextToBrokeLine } from '../../utils/functions';
import { PhotoGallery } from '../PhotoGallery';

interface ResumeComponentProps {
  wpData: any;
}

export function ResumeComponent({ wpData }: ResumeComponentProps) {
  const images: any = [];

  if (wpData) {
    wpData?.images_descriptions?.map((image: any) => {
      return images.push({
        original: process.env.REACT_APP_WORDPRESS_API + image?.guid,
        thumbnail: process.env.REACT_APP_WORDPRESS_API + image?.guid,
      });
    });
  }

  return (
      <ResumeStyled className="mx-auto my-5 col-md-10 px-3 px-md-0">
        <div>
          <PBlackStyles
            className={'mt-auto'}
            dangerouslySetInnerHTML={{
              __html: handleFormatTextToBrokeLine(wpData?.description),
            }}
          />
        </div>

        <div>
          <PhotoGallery images={images} />
        </div>
      </ResumeStyled>
  );
}

const ResumeStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  align-items: center;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export const ResumePhoneSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 1rem;
    line-height: 25px;
    color: #424242;
    padding: 29px 27px;
    text-align: justify;
  }

  img {
    max-width: 100%;
    border-radius: 10px;
  }
`;
