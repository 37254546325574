import { Card, Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { CardStyles } from '../../../styles/GlobalStyles';

export function ProjectSuportComponent() {
  const data = [
    {
      id: 1,
      title: 'Identificação (oportunidades para envio de projetos)',
      image: <i className="bi bi-search" />,
    },
    {
      id: 2,
      title: 'Assessoria/apoio na elaboração do projeto',
      image: <img src={'/icons/customer-success.svg'} alt="Assessoria/apoio na elaboração do projeto" />,
    },
    {
      id: 3,
      title: 'Formalização de \n contratos e convênios',
      image: <img src={'/icons/doc-sign.svg'} alt="Formalização de \n contratos e convênios" />,
    },

    {
      id: 4,
      title: 'Assessoria em normas e legislações específicas',
      image: <img src={'/icons/scale-icon.svg'} alt="Assessoria em normas e legislações específicas" />,
    },

    {
      id: 5,
      title: 'Gestão dos processos e recursos financeiros',
      image: <img src={'/icons/arrow-circle.svg'} alt="Gestão dos processos e recursos financeiros" />,
    },
  ];

  return (
    <ProjectSuportRow className="project-suport">
      <Col xl={8} className="mx-auto mt-5 projectSupportMainCol">
        <Row className="d-flex align-items-center">
          <Col xs={12} xl={6}>
            {data?.map((item) => {
              return (
                <Row className="mb-3" key={item.id}>
                  <Col md={11} xl={12} className="mx-auto">
                    <CardStyles>
                      <Card.Body>
                        <Row>
                          <Col
                            xs={10}
                            md={9}
                            xl={8}
                            className="my-auto mx-auto"
                          >
                            <span>{item.title}</span>
                          </Col>
                          <Col
                            xs="auto"
                            className="text-center mx-auto my-auto"
                          >
                            {item.image}
                          </Col>
                        </Row>
                      </Card.Body>
                    </CardStyles>
                  </Col>
                </Row>
              );
            })}
          </Col>
          {!isMobile && (
            <Col>
              <img
                className="w-100 arrowCircle"
                src={'/gif/arrow-circle.gif'}
                alt="Fundação Miguel Couto"
              />
            </Col>
          )}
        </Row>
      </Col>
    </ProjectSuportRow>
  );
}

export const ProjectSuportRow = styled(Row)`
  @media screen and (max-width: 1199px) {
    margin-top: 0px;

    .card-body {
      span {
        font-size: 18px;
      }
    }

    .projectSupportMainCol {
      margin-top: 24px !important;
    }

    .col {
      padding-left: 24px;
      padding-right: 24px;
    }

    .arrowCircle {
      margin-top: 16px;
    }
  }
`;
