import { Row, Col } from 'react-bootstrap';
import { ChevronLeft } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { PageDescription, PageTitle } from '../../styles/GlobalStyles';
import { ChekoutJumbotron } from '../../styles/TransparencyPortalStyles';

export function HeadJumbotron() {
  return (
    <Row>
      <ChekoutJumbotron>
        <Row>
          <Col xs={11} xl={8} className="mx-auto">
            <PageDescription>
              <Link
                to="/cursos"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <ChevronLeft /> Voltar para cursos
              </Link>
            </PageDescription>
            <br />
            <PageTitle>Carrinho de compras</PageTitle>
          </Col>
        </Row>
      </ChekoutJumbotron>
    </Row>
  );
}
