// @ts-nocheck
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ClinicalResearchViewModel } from '../../../domain/models/ResearchCenterPageInfoViewModel';
import { H1Styles, H2Styles, PBlackStyles } from '../../../styles/GlobalStyles';
import InstitutionalService from '../../../services/InstitutionalService';
import { PageLoader } from '../../Loader';

interface SuccessCaseComponentProps {
  clinicalResearch: ClinicalResearchViewModel;
}

export function SuccessCaseComponent({
  clinicalResearch,
}: SuccessCaseComponentProps) {
  const institutionalService = new InstitutionalService();
  const [isLoading, setLoading] = useState(false);
  const [casesData, setCasesData] = useState<any[]>([]);

  useEffect(() => {
    const getCases = async () => {
      try {
        setLoading(true);
        let response: any = await institutionalService.getWordpressData('/clinical_research');
        if (response?.result) {
          setCasesData(JSON.parse(response.result));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Erro ao tentar pegar eventos da API Wordpress. Erro => ',
          error
        );
      }
    };
    getCases();
  }, []);

  return (
    <Row>
      <Col xs={11} xl={9} className="mx-auto my-5">
        <Row className="mt-xl-3">
          <Col xl="auto" className="mx-auto">
            <H1Styles>Cases de Sucesso</H1Styles>
          </Col>
        </Row>

        {!isLoading ? (
          <Row className="py-4 py-xl-5 mt-xl-4 d-flex gap-5">
            {casesData && casesData.length !== 0 ? (
              casesData.map((caseItem) => {
                return (
                  <>
                    <Col
                      xl={{ span: 6, offset: 1 }}
                      className="mx-auto my-auto"
                    >
                      <Row>
                        <H2Styles className="mb-4">
                          {caseItem?.case_title}
                        </H2Styles>
                      </Row>
                      <Row>
                        <PBlackStyles>{caseItem?.description}</PBlackStyles>
                      </Row>
                    </Col>
                    <Col
                      xl={5}
                      className="mx-auto mt-2 mt-xl-0 d-flex justify-content-center"
                    >
                      <img
                        className="w-md-50 w-xl-100"
                        style={{ maxWidth: "100%" }}
                        src={
                          process.env.REACT_APP_WORDPRESS_API +
                          caseItem?.image?.guid
                        }
                        alt={caseItem?.case_title}
                      />
                    </Col>
                  </>
                );
              })
            ) : (
              <Col xl={12} className="text-center">
                <p>Nenhum case foi encontrado.</p>
              </Col>
            )}
          </Row>
        ) : (
          <PageLoader />
        )}
      </Col>
    </Row>
  );
}
