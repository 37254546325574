import { Col, Container, Form, Row } from 'react-bootstrap';
import {
    SecondaryButton,
    TerciaryGreenButton,
} from '../../../styles/GlobalStyles';
import { useInstitutionalAuthContext } from '../../../context/InstitutionalAuthContext';
import InputMask from 'react-input-mask';
import { TailSpin } from 'react-loader-spinner';
import { useCartContext } from '../../../context/CartContext';

export function CodeValidationComponent({ }: CodeValidationComponentProps) {
    const {
        handleCodeValidation,
        handleResendCodeValidation,
        timer,
        validationCode,
        handleCodeValidationChange,
        resendingCode,
        isInvalidCode,
    } = useInstitutionalAuthContext();

    const {TotalAmount} = useCartContext()

    return (
        <Container>
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <h2 className="d-none d-lg-block my-2">Login</h2>
                        </Row>
                        <Row>
                            <span className="mb-4">
                                Verifique o código recebido no seu e-mail e insira abaixo:
                            </span>
                        </Row>
                        <Row>
                            <Col md={3} className="mx-auto">
                                <Form.Group>
                                    <Form.Control
                                        id={'validation-code'}
                                        as={InputMask}
                                        mask="999999"
                                        type="text"
                                        autoComplete="new-password"
                                        aria-autocomplete="none"
                                        value={validationCode}
                                        onChange={(e) => handleCodeValidationChange(e)}
                                        disabled={timer === '00:00'}
                                        isInvalid={isInvalidCode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        O código informado é invalido
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} className="mx-auto">
                                <span className="info-detail mt-4">
                                    O código é válido por 5 min. Verifique caso não tenha recebido
                                    o e-mail na sua caixa de spam e lixeira.
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={'auto'} className="timer mx-auto mt-1 mb-4">
                                {timer}
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={6} className="mb-4">
                                <TerciaryGreenButton
                                    onClick={() => handleResendCodeValidation()}
                                    className="w-100"
                                    type="button"
                                    disabled={resendingCode}
                                >
                                    {resendingCode ? (
                                        <Row>
                                            <Col xs="auto" className="mx-auto">
                                                <TailSpin color={'#72b81f'} height={25} width={25} />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <>Reenviar código</>
                                    )}
                                </TerciaryGreenButton>
                            </Col>
                            <Col md={6} className="mb-4">
                                <SecondaryButton
                                    onClick={() => handleCodeValidation(TotalAmount === 0 ? true : false)}
                                    className="w-100"
                                    type="button"
                                    disabled={timer === '00:00' || validationCode.length < 6}
                                >
                                    Confirmar
                                </SecondaryButton>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
interface CodeValidationComponentProps { }
