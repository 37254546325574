import { Row, Col, Card } from 'react-bootstrap';
import {
  H1Styles,
  ContentCard,
  SeeMoreButton,
} from '../../styles/GlobalStyles';
import { InstitutionalPageInfoViewModel } from '../../domain/models/InstitutionalPageInfoViewModel';
import { MobileCarrouselComponent } from './MobileCarrouselComponent';
import { Link } from 'react-router-dom';

interface OurActivitiesComponentProps {
  institutionalPageInfo: InstitutionalPageInfoViewModel;
}

export function OurActivitiesComponent({
  institutionalPageInfo,
}: OurActivitiesComponentProps) {
  return (
    <Row>
      <Col xl={9} className="mx-auto  mt-5 mt-8">
        <Row>
          <Col>
            <H1Styles className="d-none d-md-block mb-4">
              Nossas atividades
            </H1Styles>
            <H1Styles className="d-md-none mb-4">
              Nossas
              <br />
              atividades
            </H1Styles>
            {/* Desktop */}
            <Row className="d-none d-xl-flex">
              {institutionalPageInfo!.home.ourActivities.map((item) => {
                return (
                  <Col key={item.title}>
                    <ContentCard key="1" className="border-0">
                      <Card.Img variant="top" src={item.image}></Card.Img>
                      <Card.Body>
                        <Row>
                          <Col md="auto" className="mx-auto">
                            <Card.Title className="mb-4 mx-auto text-uppercase">
                              {item.title}
                            </Card.Title>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={10} className="mx-auto">
                            <p className="text-center card-text">
                              {item.description}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer className="mt-2 mb-4">
                        <Row>
                          <Col md={8} className="mx-auto d-flex justify-content-center">
                            <SeeMoreButton
                              as={Link}
                              to={item.redirectUrl}
                              className="btn btn-default "
                            >
                              <span className="mx-auto more-information">
                                Ver mais
                              </span>
                            </SeeMoreButton>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </ContentCard>
                  </Col>
                );
              })}
            </Row>
            {/* Mobile */}
            <MobileCarrouselComponent>
              {institutionalPageInfo!.home.ourActivities?.map((item) => {
                return (
                  <Col md={6} key={item.title}>
                    <ContentCard key="1" className="border-0">
                      <Card.Img variant="top" src={item.image} />
                      <Card.Body>
                        <Row>
                          <Col md="auto" className="mx-auto">
                            <Card.Title className="our-activities-title text-center mb-4 mx-auto text-uppercase">
                              {item.title}
                            </Card.Title>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="text-center card-text our-activities-desc">
                              {item.description}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer className="mt-2 mb-4 ">
                        <Row>
                          <Col md={8} className="mx-auto">
                            <SeeMoreButton
                              as={Link}
                              to={item.redirectUrl}
                              className="btn btn-default"
                            >
                              <span className="mx-auto more-information">
                                Ver mais
                              </span>
                            </SeeMoreButton>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </ContentCard>
                  </Col>
                );
              })}
            </MobileCarrouselComponent>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
