import { Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';

import {
  CardStyles,
  H1Styles,
  InstitutionalInformationIconDiv,
  PBlackStyles,
} from '../../styles/GlobalStyles';
import { MissonAndValuesComponentStyles } from './styles';
import searchIcon from '../../assets/icons/search2.svg';
import lightIcon from '../../assets/icons/light.svg';
import justiceIcon from '../../assets/icons/justice.svg';

interface MissonAndValuesComponentProps {
  wpData: any;
}

export function MissonAndValuesComponent({
  wpData,
}: MissonAndValuesComponentProps) {
  const valuesData = [
    {
      id: 1,
      text: 'Excelência',
      icon: <i className="bi bi-mortarboard-fill" />,
    },
    {
      id: 2,
      text: 'Sustentabilidade',
      icon: <i className="bi bi-recycle" />,
    },
    {
      id: 3,
      text: 'Cooperação',
      icon: <i className="bi bi-people-fill" />,
    },
    {
      id: 4,
      text: 'Ética',
      icon: <img src={justiceIcon} width={38} height={38} alt="Ética" />,
    },
    {
      id: 5,
      text: 'Responsabilidade Social',
      icon: <i className="bi bi-heart-fill" />,
    },
    {
      id: 6,
      text: 'Inovação',
      icon: <img src={lightIcon} width={38} height={38} alt="Inovação" />,
    },
    {
      id: 7,
      text: 'Transparência',
      icon: <img src={searchIcon} width={38} height={38} alt="Transparência" />,
    },
  ];

  return (
    <MissonAndValuesComponentStyles>
      <Col xs={11} xl={8} className="mx-auto">
        <GenericCardRowGrid2>
          <CardStyles>
            <Card.Body>
              <Row>
                <Col md="auto" className="mx-auto p-0">
                  <CardHeader>
                    <InstitutionalInformationIconDiv color="#72B81F">
                      <Row className="h-100">
                        <Col md="auto" className="mx-auto my-auto iconBox">
                          <i className="bi bi-globe-americas" />
                        </Col>
                      </Row>
                    </InstitutionalInformationIconDiv>
                    <H1Styles>{wpData?.title_purpose}</H1Styles>
                  </CardHeader>
                </Col>
              </Row>
              <Row>
                <Col xs={10} xl={8} className="mx-auto mb-2 px-4">
                  <PBlackStyles
                    dangerouslySetInnerHTML={{
                      __html: wpData?.description_purpose,
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </CardStyles>

          <CardStyles>
            <Card.Body>
              <Row>
                <Col md="auto" className="mx-auto p-0">
                  <CardHeader>
                    <InstitutionalInformationIconDiv color="#72B81F">
                      <Row className="h-100">
                        <Col md="auto" className="mx-auto my-auto iconBox">
                          <i className="bi bi-bullseye" />
                        </Col>
                      </Row>
                    </InstitutionalInformationIconDiv>
                    <H1Styles>{wpData?.title_mission}</H1Styles>
                  </CardHeader>
                </Col>
              </Row>
              <Row>
                <Col xs={10} xl={8} className="mx-auto mb-2 px-4">
                  <PBlackStyles
                    dangerouslySetInnerHTML={{
                      __html: wpData?.description_mission,
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </CardStyles>
        </GenericCardRowGrid2>

        <GenericCardRowFlex>
          <Col className="our-values">
            <CardStyles>
              <Card.Body className="main-card-body">
                <CardHeaderOurValues>
                  <InstitutionalInformationIconDiv color="#72B81F">
                    <Row className="h-100">
                      <Col xl="auto" className="mx-auto my-auto iconBox">
                        <i className="bi bi-people-fill" />
                      </Col>
                    </Row>
                  </InstitutionalInformationIconDiv>
                  <H1Styles>{wpData?.title_our_values}</H1Styles>
                </CardHeaderOurValues>
                <ValuesWrapper className="mx-auto mb-2">
                  {valuesData?.map((value) => {
                    return (
                      <Col xs={11} md={5} xl={3} key={value.id}>
                        {value.icon}
                        <span>{value.text}</span>
                      </Col>
                    );
                  })}
                </ValuesWrapper>
              </Card.Body>
            </CardStyles>
          </Col>
        </GenericCardRowFlex>
      </Col>
    </MissonAndValuesComponentStyles>
  );
}

const ValuesWrapper = styled(Row)`
  width: 100%;
  max-width: 100%;
  padding: 0;
  justify-content: center;

  div {
    background: rgba(66, 66, 66, 1);

    display: flex;
    align-items: center;
    gap: 20px;

    padding: 18px;
    border-radius: 10px;
    margin: 8px;

    i {
      color: rgba(114, 184, 31, 1);
      font-size: 38px !important;
    }

    span {
      font-size: 18px !important;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1299px) {
    div {
      padding: 25px;
      margin: 12px;
    }
  }

  @media screen and (min-width: 1300px) {
    div {
      padding: 35px;
      margin: 1rem;
    }
  }
`;

export const CardHeader = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 30px 0 25px;
`;

export const CardHeaderOurValues = styled(CardHeader)`
  @media (min-width: 1211px) {
    justify-content: start;
    padding-left: 60px;
    margin-top: 40px;
  }
`;

export const GenericCardRowFlex = styled.section`
  display: flex;
  gap: 14px;

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    gap: 20px;

    .our-values {
      margin-top: 20px;
    }
  }
`;

export const GenericCardRowGrid2 = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;

  @media screen and (max-width: 1199px) {
    grid-template-columns: 1fr;
    gap: 20px;

    .our-values {
      margin-top: 20px;
    }
  }
`;
