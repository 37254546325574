import { ModalBody } from 'react-bootstrap';
import { BaseModalStyles } from '../../../styles/GlobalStyles';
import styled from 'styled-components';

export function ClinicalResearchInfoModal({
  title,
  description,
  show,
  onHide,
}: ClinicalResearchInfoModalProps) {
  return (
    <ClinicalResearchInfoModalStyles
      centered
      show={show}
      onHide={onHide}
      className="d-flex"
    >
      <ModalBody className="my-auto text-center">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </ModalBody>
    </ClinicalResearchInfoModalStyles>
  );
}
interface ClinicalResearchInfoModalProps {
  title: string;
  description: string;
  show: boolean;
  onHide: () => void;
}

const ClinicalResearchInfoModalStyles = styled(BaseModalStyles)`
  .modal-body {
    padding: 25px 35px;
  }
  h2 {
    color: #72b81f;
    text-align: center;
    font-family: 'Steradian';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: #424242;
    text-align: justify;
    font-family: 'Steradian';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  @media screen and (max-width: 1199px) {
    .modal-body {
      padding: 1rem;
    }
    
    h2 {
      margin-bottom: 1rem;
    }
  }
`;
