import { Col, Row } from 'react-bootstrap';

import { PhotoGallery } from '../PhotoGallery';
import { AllEventsSectionStyles, NewEventSectionStyles } from './styles';
import { ReadMore } from '../ReadMore';
import { handleFormatTextToBrokeLine } from '../../utils/functions';

interface NewEventSectionProps {
  eventsData: any;
}

export function NewEventSection({ eventsData }: NewEventSectionProps) {
  const handleFormatImages = (array: Array<any>) => {
    const dataImages: { original: any; thumbnail: any }[] = [];

    array.map((item) => {
      return dataImages.push({
        original: process.env.REACT_APP_WORDPRESS_API + item,
        thumbnail: process.env.REACT_APP_WORDPRESS_API + item,
      });
    });

    return dataImages;
  };

  return (
    <AllEventsSectionStyles>
      {eventsData?.map((event: any, index: number) => {
        return (
          <NewEventSectionStyles
            style={
              index % 2 === 0
                ? { color: '#fff', backgroundColor: 'rgb(66, 66, 66)' }
                : { color: 'rgb(66, 66, 66)', backgroundColor: '#fff' }
            }
          >
            <Col xs={11} xl={9} className="mx-auto">
              <Row className="mb-4 mb-xl-0">
                <Col
                  xl={5}
                  className="pt-xl-3"
                  style={{ paddingRight: '2rem' }}
                >
                  <h2>{event?.event_title?.value}</h2>

                  <ReadMore
                    completeText={handleFormatTextToBrokeLine(
                      event?.description.value
                    )}
                    color={index % 2 === 0 ? "#fff" : "rgb(66,66,66)"}
                    numberChar={277}
                  />
                </Col>
                <Col xl={7} className="my-auto">
                  {event?.images?.rendered && (
                    <PhotoGallery
                      images={handleFormatImages(
                        event?.images?.rendered?.split(' ')
                      )}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </NewEventSectionStyles>
        );
      })}
    </AllEventsSectionStyles>
  );
}
