import { ApiResponse } from '@brainz_group/webappframework';
import { AxiosResponse } from 'axios';

import ServiceApiInterface from '../interface/ServiceApiInterface';
import { PaymentPayload } from '../context/PurcharseFinalizationContext';

export default class PaymentService {
  public async paymentFinalization(
    payload: PaymentPayload
  ): Promise<ApiResponse<PaymentPayload> | undefined> {
    try {
      let endpoint = `/PaymentIntegration/Payment`;

      let response: AxiosResponse<ApiResponse<any>> =
        await ServiceApiInterface.post(endpoint, payload);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.contactUsEmail()', error);
    }
  }
  public async paymentFinalizationFree(
    payload: any
  ): Promise<ApiResponse<any> | undefined> {
    try {
      let endpoint = `/PaymentIntegration/IntegrationFree`;

      let response: AxiosResponse<ApiResponse<any>> =
        await ServiceApiInterface.post(endpoint, payload);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.contactUsEmail()', error);
    }
  }
}
