import { ReactNode } from 'react';
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import styled from 'styled-components';

interface MobileCarrouselComponentProps {
  children: ReactNode;
}
export function MobileCarrouselComponent({
  children,
}: MobileCarrouselComponentProps) {
  return (
    <Row className="d-xl-none">
      <Col md={8} className="mx-auto">
        <MobileCarrouselComponentStyles
          speed={500}
          slidesToShow={1}
          dots={true}
          infinite={true}
        >
          {children}
        </MobileCarrouselComponentStyles>
      </Col>
    </Row>
  );
}

const MobileCarrouselComponentStyles = styled(Slider)`
  .slick-dots {
    position: relative;
    bottom: 0px;
    padding: 12px 0;
  }

  .slick-dots li {
    padding: 0 4px;
  }

  .slick-dots li button {
    width: 12px;
    height: 12px;
  }

  .slick-dots li button:before {
    font-size: 16px;
  }
`;
