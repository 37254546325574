import { Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import {
  EmptyRow,
  PageDescription,
  PageTitle,
} from '../../styles/GlobalStyles';
import { ResearchPageTitleStyles } from '../../styles/ResearchStyles';

interface HeaderJumbotronCompoentProps {
  title: string;
  backImage: string;
  bottom?: string;
}
export default function HeaderJumbotronComponent({
  title,
  backImage,
  bottom,
}: HeaderJumbotronCompoentProps) {
  return (
    <ResearchPageTitleStyles backGroundImage={backImage} bottom={bottom}>
      <Col>
        <EmptyRow height="25vh" mobileHeight="17rem" />

        <Row>
          <Col xl={10} className={isMobile ? 'mx-0 px-4' : 'mx-auto'}>
            <Row>
              <Col>
                <PageTitle>{title}</PageTitle>
                <PageDescription></PageDescription>
              </Col>
            </Row>
          </Col>
        </Row>

        <EmptyRow height="10vh" mobileHeight="5rem" />
      </Col>
    </ResearchPageTitleStyles>
  );
}
