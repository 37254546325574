import { Card, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import styled from 'styled-components';

import {
  CardStyles,
  H3Styles,
  PBlackStyles,
  SecondaryButton,
} from '../../styles/GlobalStyles';
import { useCourseDetailContext } from '../../context/CourseDetailContext';
import { FormatWorkload } from '../../domain/util/FormatWorkload';
import { useEffect, useState } from 'react';
import {
  CoursePriceContainer,
  handleRenderPriceContent,
} from './CourseDetailComponent';

export function SubscribeCourseMobileComponent() {
  const { course, subscribeToCourse, isInCart } = useCourseDetailContext();
  const [isFreeCourse, setIsFreeCourse] = useState<boolean>(false);
  const [isZeroCourse, setIsZeroCourse] = useState<boolean>(false);

  useEffect(() => {
    if (course?.priceWithDiscount === 0 || course?.price === 0) {
      setIsZeroCourse(true);

      if (course?.priceWithDiscount === course?.price || course?.price === 0) {
        setIsFreeCourse(true);
      }
    }
  }, []);

  return (
    <SubscribeCourseMobileComponentStyles className="d-xl-none pt-4">
      <Col xs={11} className="mx-auto">
        <SecondaryButton
          className="w-100 py-3 py-sm-5 py-md-4 text-center"
          onClick={() => subscribeToCourse(isZeroCourse)}
          disabled={isInCart()}
        >
          {isInCart() ? (
            <>Adicionado ao carrinho</>
          ) : isZeroCourse ? (
            <>Inscrever-se no curso</>
          ) : (
            <>Adicionar ao carrinho</>
          )}
        </SecondaryButton>
      </Col>
      <Col xs={11} className="mx-auto">
        <Row>
          <Col xs={6} className="my-2">
            <Row>
              <H3Styles className="my-2">Carga Horária</H3Styles>
              <PBlackStyles>{FormatWorkload(course.workLoad)}</PBlackStyles>
            </Row>
          </Col>
          <Col xs={6} className="my-2">
            <Row>
              <H3Styles className="my-2">Publicação</H3Styles>
              <PBlackStyles>
                {moment(course.publishedDate).format('DD/MM/YYYY')}
              </PBlackStyles>
            </Row>
          </Col>
        </Row>
      </Col>

      {!isFreeCourse && (
        <CoursePriceContainer>
          <Col xs={11} className="mx-auto">
            <CardStyles>
              <Card.Body className="px-4 py-4">
                <Row>
                  <H3Styles className="mb-3">Valor do curso</H3Styles>
                </Row>
                {handleRenderPriceContent(course)}
              </Card.Body>
            </CardStyles>
          </Col>
        </CoursePriceContainer>
      )}

      <Col xs={11} className="mt-4 mb-3 mx-auto">
        <H3Styles>Coordenador do curso</H3Styles>
        <PBlackStyles>{course.responsibleUserCourse}</PBlackStyles>
      </Col>
    </SubscribeCourseMobileComponentStyles>
  );
}

const SubscribeCourseMobileComponentStyles = styled(Row)`
  background: #e9e9e9;
  button {
    font-size: 20px;
    margin-bottom: 12px;
  }
`;
