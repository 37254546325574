import { Row, Col } from 'react-bootstrap';
import {
  H1Styles,
  PBlackStyles,
  SecondaryButton,
} from '../../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';

export function EmptyCartComponent() {
  const navigate = useNavigate();

  function redirectToCourses() {
    navigate('/cursos');
  }
  return (
    <>
      <Row className="mt-5">
        <Col xs="auto" className="mx-auto">
          <img src={'/icons/empty-cart.svg'} alt='Carrinho vazio' />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="auto" className="mx-auto">
          <H1Styles>Carrinho vazio!</H1Styles>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto" className="mx-auto">
          <PBlackStyles className="text-center">
            Você não adicionou nenhum curso no seu carrinho,
            <br />
            veja mais cursos para se inscrever e<br />
            finalizar sua compra.
          </PBlackStyles>
        </Col>
      </Row>
      <Row className="mt-2 mb-5">
        <Col xs="auto" className="mx-auto pb-5">
          <SecondaryButton
            onClick={() => redirectToCourses()}
          >
            Ver todos os cursos
          </SecondaryButton>
        </Col>
      </Row>
    </>
  );
}
