import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { CoursesComponent } from '../components/course/CoursesComponent';
import { CourseScreenContextProvider } from '../context/CourseScreenContext';

export default function CourseScreen() {
  return(
    <BaseLayoutComponent
      showInstitutionalInfo
    >
      <CourseScreenContextProvider>
        <CoursesComponent/>
      </CourseScreenContextProvider>
    </BaseLayoutComponent>
  )
}


