import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { ClinicalResearchViewModel } from '../../../domain/models/ResearchCenterPageInfoViewModel';
import { H1Styles, PBlackStyles } from '../../../styles/GlobalStyles';
import PhasesImg from '../../../assets/image/phases.svg';


interface ClinicalResearchStagesComponentProps {
  clinicalResearch: ClinicalResearchViewModel;
}

export function ClinicalResearchStagesComponent({
  clinicalResearch,
}: ClinicalResearchStagesComponentProps) {
  return (
    <ClinicalResearchStagesComponentStyles>
      <Col xs={11} xl={8} className="mx-auto">
        <Row>
          <Col xl={'auto'} className="mx-auto">
            <H1Styles>{clinicalResearch.stageClinicalResearchTitle}</H1Styles>
          </Col>
        </Row>
        <Row>
          <Col xl={'auto'} className="mx-auto mt-3">
            <h3>{clinicalResearch.stageClinicalResearchSubtitle}</h3>
          </Col>
        </Row>
        <Row>
          <Col xl={'auto'} className="mx-auto mt-4">
            {isMobile ? (
              <SpecialMobileImageSlider>
                <img src={PhasesImg} alt="Fases da Pesquisa" />
              </SpecialMobileImageSlider>
            ) : (
              <img
                src={clinicalResearch.stageClinicalResearchImage}
                alt="Fases da Pesquisa"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto mt-xl-5">
            <PBlackStyles>
              {clinicalResearch.stageClinicalResearchDescription}
            </PBlackStyles>
          </Col>
        </Row>
      </Col>
    </ClinicalResearchStagesComponentStyles>
  );
}
const ClinicalResearchStagesComponentStyles = styled(Row)`
  padding: 5vmin 0px;

  img {
    width: 100%;
  }

  h3 {
    color: #424242;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
  }

  @media screen and (max-width: 1199px) {
    h3 {
      font-size: 18px;
      text-align: left;
    }
  }
`;

export const SpecialMobileImageSlider = styled.div`
  overflow-x: auto;
  scrollbar-width: none;

  margin-bottom: 2rem;

  ::-webkit-scrollbar {
    display: none;
  }

  img {
    width: auto;
  }
`;

