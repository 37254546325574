import axios from 'axios';

const ServiceAccountInterface = axios.create({
    baseURL: process.env.REACT_APP_ACCOUNT_URL+"/api/v1"
})

ServiceAccountInterface.interceptors.response.use(
    function (config) {
        return config
    },
    function (error: any) {        
        console.error("ServiceAccountInterfaceError",error);
        return error
    });

export default ServiceAccountInterface;



