import styled from 'styled-components';

export const NotFoundContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  margin: 2rem 0;

  img {
    max-width: 30%;
    @media screen and (max-width: 600px) {
      max-width: 80%;
    }
  }

  h3 {
    margin-top: 1.5rem;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    font-weight: 600;
    color: #424242;

    @media screen and (max-width: 600px) {
      font-size: 28px;
    }
  }

  span {
    font-size: 20px;
    color: #424242;
  }

  button {
    max-width: 300px;

    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;
