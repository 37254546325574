import * as yup from 'yup';
import * as formik from 'formik';
import { Row, Col, Form } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';

import {
  H1Styles,
  InputStyles,
  SecondaryButton,
  TextAreaStyles,
} from '../../styles/GlobalStyles';
import { ContactUsEmailPayload } from '../../domain/payload/ContactUsEmailPayload';
import InstitutionalService from '../../services/InstitutionalService';
import { SendMessageComponentStyles } from './styles';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

const institutionalService = new InstitutionalService();

function SendMensageForm(props: any) {
  function handleSubmit(e: any, values: any) {
    e.preventDefault();

    institutionalService
      .contactUsEmail(values)
      .then((result) => {
        if (result?.success) {
          toast.success('Mensagem enviada com sucesso!');
        } else {
          toast.error('Erro ao enviar mensagem, tente novamente.');
        }

        props.resetForm({
          email: '',
          content: '',
          name: '',
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error('Erro ao enviar mensagem, tente novamente.');
      });
  }
  return (
    <Form onSubmit={(e: any) => handleSubmit(e, props.values)}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Row>
        <Col>
          <InputStyles
            type="text"
            name="name"
            placeholder="Seu Nome"
            value={props.values.name}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputStyles
            name="email"
            type="text"
            placeholder="Seu e-mail"
            value={props.values.email}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextAreaStyles
            rows={10}
            name="content"
            className="message pb-auto"
            placeholder="Escreva sua mensagem"
            value={props.values.content}
            onBlur={props.handleBlur}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={'auto'} className="ms-auto">
          <SecondaryButton
            disabled={!(props.isValid && Object.keys(props.touched).length > 0)}
            type="submit"
          >
            Enviar Mensagem
          </SecondaryButton>
        </Col>
      </Row>
    </Form>
  );
}

export function SendMessageComponent() {
  const { Formik } = formik;

  const image = `/images/contactUs/sendMessageImage.png`;

  const schema: yup.ObjectSchema<ContactUsEmailPayload> = yup.object().shape({
    content: yup.string().required('É obrigatório informar o nome'),

    email: yup.string().email().required('É obrigatório informar o CPF'),

    name: yup.string().required('É obrigatório informar o CPF'),
  });

  return (
    <SendMessageComponentStyles>
      <Col
        xl={{
          span: 8,
          offset: 2,
        }}
      >
        <Row>
          <Col className="mb-3">
            <H1Styles>Ou mande uma mensagem</H1Styles>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Formik
              validationSchema={schema}
              onSubmit={console.log}
              initialValues={{
                name: '',
                email: '',
                content: '',
              }}
            >
              {({
                setErrors,
                setValues,
                handleBlur,
                handleChange,
                isValid,
                values,
                touched,
                errors,
                resetForm,
              }) => (
                <SendMensageForm
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  resetForm={resetForm}
                  isValid={isValid}
                  touched={touched}
                  values={values}
                />
              )}
            </Formik>
          </Col>

          {!isMobile && (
            <Col
              className="d-none d-xl-block"
              xl={{
                span: '5',
                offset: 1,
              }}
            >
              <img src={image} alt="Fale conosco" />
            </Col>
          )}
        </Row>
      </Col>
    </SendMessageComponentStyles>
  );
}
