import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CourseCardViewModel } from '../domain/models/CourseCardViewModel';
import TrilhasIntegrationService from '../services/TrilhasIntegrationService';
import { PaginationProps } from '../components/BrainzPagination';

const trilhasIntegrationService = new TrilhasIntegrationService();

const CourseScreenContext = createContext({} as CourseScreenContextData);

interface CourseScreenContextProviderProps {
  children: ReactNode;
}

export function CourseScreenContextProvider(
  props: CourseScreenContextProviderProps
) {
  const [courses, setCourses] = useState<CourseCardViewModel[]>([]);
  const [isLoadingCourse, setIsLoadingCourse] = useState(false);
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    pageSize: 6,
  });
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    loadCourses();
  }, [paginationParams]);

  useEffect(() => {
    setPaginationParams({
      page: 1,
      pageSize: 6,
    });
  }, [searchValue]);

  async function loadCourses() {
    setIsLoadingCourse(true);
    let response =
      await trilhasIntegrationService.listInstitutionalCoursesPaginated(
        paginationParams.page,
        paginationParams.pageSize,
        searchValue
      );

    if (response?.result?.items) {
      setCourses(response.result.items);
      setPaging({
        currentPage: response.result.currentPage,
        currentRecord: response.result.recordsOnPage,
        pageSize: response.result.pageSize,
        recordsOnPage: response.result.recordsOnPage,
        totalPages: response.result.totalPages,
        totalRecords: response.result.totalRecords,
        hasNext: response.result.hasNext,
        hasPrevious: response.result.hasPrevious,
      });
      setIsLoadingCourse(false);
    } else {
      setErrorMessage('Não foi possível buscar cursos, tente novamente.');
      console.error('Erro ao buscar lista de cursos.');
      setIsLoadingCourse(false);
    }
  }

  return (
    <>
      <CourseScreenContext.Provider
        value={{
          courses,
          isLoadingCourse,
          paging,
          setPaginationParams,
          setSearchValue,
          errorMessage,
        }}
      >
        {props.children}
      </CourseScreenContext.Provider>
    </>
  );
}

export function useCourseScreenContext() {
  return useContext(CourseScreenContext);
}

interface CourseScreenContextData {
  courses: CourseCardViewModel[];
  isLoadingCourse: boolean;
  setPaginationParams: any;
  paging: PaginationProps | undefined;
  setSearchValue: any;
  errorMessage: string;
}
