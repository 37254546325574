import BaseLayoutComponent from '../../components/layouts/BaseLayoutComponent';
import { ClinicalResearchComponent } from '../../components/research/clinicalResearchCenter/ClinicalResearchComponent';
import { ClinicalResearchContextProvider } from '../../context/ClinicalResearchContext';
import { useInstitutionalContext } from '../../context/InstitutionalContext';

export function ClinicalResearchScreen({ }: ClinicalResearchScreenProps) {
  const { institutionalPageInfo } = useInstitutionalContext();
  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <ClinicalResearchContextProvider>
        <ClinicalResearchComponent
          clinicalResearch={institutionalPageInfo!.search.clinicalResearch}
        />
      </ClinicalResearchContextProvider>
    </BaseLayoutComponent>
  );
}

interface ClinicalResearchScreenProps { }
