import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { CartComponent } from "../components/cart/CartComponent";
import { isMobile } from 'react-device-detect';

export default function CartScreeen() {
    return (
        <BaseLayoutComponent
            showInstitutionalInfo={!isMobile}
        >
            <CartComponent />
        </BaseLayoutComponent>    
    )
}


