import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import InstitutionalService from '../services/InstitutionalService';
import { InstitutionalPageInfoViewModel } from '../domain/models/InstitutionalPageInfoViewModel';
import { CourseCardViewModel } from '../domain/models/CourseCardViewModel';
import TrilhasIntegrationService from '../services/TrilhasIntegrationService';

interface InstitutionalContextData {
  verifyPageScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  isTopScroll: boolean;
  isLoading: boolean;
  isWaiting: boolean;
  setIsWaiting: React.Dispatch<React.SetStateAction<boolean>>;
  institutionalPageInfo: InstitutionalPageInfoViewModel | undefined;
  courses: CourseCardViewModel[];
  getHomeCourses: () => void;
}

const InstitutionalContext = createContext({} as InstitutionalContextData);

export function useInstitutionalContext() {
  return useContext(InstitutionalContext);
}

interface InstitutionalContextProviderProps {
  children: ReactNode;
}

const institutionalService = new InstitutionalService();
const trilhasIntegrationService = new TrilhasIntegrationService();

export function InstitutionalContextProvider(
  props: InstitutionalContextProviderProps
) {
  const [isTopScroll, setIsTopScroll] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);

  const [institutionalPageInfo, setInstitutionalPageInfo] =
    useState<InstitutionalPageInfoViewModel>();
  const [courses, setCourses] = useState<CourseCardViewModel[]>([]);

  const InstitutionalInfoName = 'InstitutionalInfo';
  const HomeCourseName = 'HomeCourseInfo';

  useEffect(() => {
    getInstitutionalInfo();
  }, []);

  function getInstitutionalInfo() {
    const institutionalInfoJson = localStorage.getItem(InstitutionalInfoName);

    if (institutionalInfoJson != null) {
      let pageInfo: InstitutionalPageInfoViewModel = JSON.parse(
        institutionalInfoJson
      );
      if (pageInfo.updateDateTime > Date.now() - 60 * 60 * 1000) {
        //1hora
        setInstitutionalPageInfo(pageInfo);
        setIsLoading(false);
        return;
      } else {
        localStorage.removeItem(InstitutionalInfoName);
      }
    }

    institutionalService.getInstitutionalPageInfo().then((response) => {
      if (response?.success) {
        let pageInfo = response.result;

        pageInfo.updateDateTime = Date.now();
        localStorage.setItem(InstitutionalInfoName, JSON.stringify(pageInfo));

        setInstitutionalPageInfo(pageInfo);
        setIsLoading(false);
      }
    });
  }

  function getHomeCourses() {
    const homeCourseInfoJson = sessionStorage.getItem(HomeCourseName);

    if (homeCourseInfoJson != null) {
      let homeCourseInfo: CourseCardViewModel[] =
        JSON.parse(homeCourseInfoJson);
      setCourses(homeCourseInfo);
    } else {
      trilhasIntegrationService
        .listInstitutionalCoursesPaginated(1, 3)
        .then((response) => {
          if (response?.result?.items) {
            let homeCourseInfo = response.result.items;
            localStorage.setItem(
              HomeCourseName,
              JSON.stringify(homeCourseInfo)
            );
            setCourses(homeCourseInfo);
          }
        });
    }
  }

  function verifyPageScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
    var wrapperElement = document.getElementById('PageWrapper');
    if (wrapperElement === null) {
      return;
    }
    if (wrapperElement.scrollTop > 50) {
      setIsTopScroll(false);
      return;
    }
    setIsTopScroll(true);
  }

  return (
    <>
      <InstitutionalContext.Provider
        value={{
          verifyPageScroll,
          isWaiting,
          setIsWaiting,
          isTopScroll,
          isLoading,
          institutionalPageInfo,
          courses,
          getHomeCourses,
        }}
      >
        {institutionalPageInfo !== undefined && props.children}
      </InstitutionalContext.Provider>
    </>
  );
}
