import { Badge, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const MainJumbotron = styled(Col)<{ backgroundImage: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: top;
  @media screen and (max-width: 900px) {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.86) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      url('/mobile/course/mobile-course-jumbotron.png');
    background-position: top !important;
  }
`;

export const AccessMyCoursesButton = styled.button`
  font-family: 'Steradian';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;

  color: #000000;

  width: 303px;
  height: 72px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
  border-radius: 10px;
`;

export const PopuleCoursesButton = styled.button`
  font-family: 'Steradian';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vmin;
  line-height: 21px;
  text-align: center;
  color: #ffffff;

  width: 252px;
  height: 40px;

  background: #424242;
  box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
  border-radius: 100px;
  border: 0;
`;

export const NewCoursesButton = styled.button`
  font-weight: 400;
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
  color: #fff;
  font-weight: 500;

  width: 252px;
  height: 40px;

  background: #72b81f;
  box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
  border-radius: 100px;
  border: 0;
`;

export const WorkLoad = styled.span`
  font-family: 'Steradian';
  font-weight: 600;
  margin-bottom: 0px;
  color: #424242;
  font-size: 15px;

  @media screen and (max-width: 1400px) {
    font-size: 12px;
  }
`;

export const FaqRow = styled(Row)`
  background: #424242;
`;

export const CourseFooterContainer = styled.div`
  display: flex !important;
  gap: 1rem;
  align-items: center;

  .clock {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`;

export const PriceContainer = styled.div`
  padding-left: 1rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  .originalPrice {
    color: #a0a0a0;
    text-decoration: line-through;

    display: flex;
    align-items: center;
    gap: 8px;
  }

  .newPrice {
    color: #24a31a;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .bold {
    font-weight: 600 !important;
  }

  @media screen and (max-width: 1199px) {
    padding-left: 0;
    gap: 10px;

    span {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1300px) {
    padding-left: 0;

    grid-template-columns: 1fr;

    gap: 8px;

    &.mobileFlex {
      grid-template-columns: 1fr 1fr;
    }

    span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1600px) {
    padding-left: 0;
  }
`;

export const BadgeSuccess = styled.section`
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  background: #24a31a;
  width: max-content;

  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;

  padding: 4px 16px;
`;
