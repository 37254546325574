import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import HeaderJumbotronComponent from '../HeaderJumbotronComponent';
import { ClinicalResearchViewModel } from '../../../domain/models/ResearchCenterPageInfoViewModel';
import ResearchResumeComponent from '../ResearchResumeComponent';
import { ResearchStructureCarrousselComponent } from './ResearchStructureCarrousselComponent';
import { ClinicalResearchInfoComponent } from './ClinicalResearchInfoComponent';
import { ClinicalResearchStagesComponent } from './ClinicalResearchStagesComponent';
import { SuccessCaseComponent } from './SuccessCaseComponent';
import { EthicalPillarsComponent } from './EthicalPillarsComponent';
import { useClinicalResearchContext } from '../../../context/ClinicalResearchContext';
import { ClinicalResearchInfoModal } from './ClinicalResearchInfoModal';
import { BasePillarsDocumentModal } from './BasePillarsDocumentModal';

interface ClinicalResearchComponentProps {
  clinicalResearch: ClinicalResearchViewModel;
}

export function ClinicalResearchComponent({
  clinicalResearch,
}: ClinicalResearchComponentProps) {
  const {
    setShowClinicalResearchModal,
    showClinicalResearchModal,

    setShowAdvantagesModal,
    showAdvantagesModal,

    setShowWhoConductsResearchModal,
    showWhoConductsResearchModal,

    setShowWhoCanParticipateModal,
    showWhoCanParticipateModal,

    setShowHowToParticipateModal,
    showHowToParticipateModal,

    showCIOMSModal,
    setShowCIOMSModal,

    showHelsinqueModal,
    setShowHelsinqueModal,

    showNurembergModal,
    setShowNurembergModal,
  } = useClinicalResearchContext();

  return (
    <ClinicalResearchComponentStyles fluid>
      <HeaderJumbotronComponent
        title={'Pesquisa Clínica'}
        backImage={clinicalResearch.image}
      />
      <ResearchResumeComponent
        title={clinicalResearch.subTitle}
        description={clinicalResearch.description}
        image2={'/images/clinical-research-resume-image.png'}
      />
      {/* Carrousel */}
      <ResearchStructureCarrousselComponent
        title={clinicalResearch.structure.title}
        description={clinicalResearch.structure.description}
        images={clinicalResearch.structure.images}
      />

      <ClinicalResearchInfoComponent />

      <ClinicalResearchStagesComponent clinicalResearch={clinicalResearch} />

      <EthicalPillarsComponent />

      <SuccessCaseComponent clinicalResearch={clinicalResearch} />

      {/* Modals */}
      <ClinicalResearchInfoModal
        title={'O que é Pesquisa Clínica?'}
        description={
          'A pesquisa clínica é um processo de investigação científica que envolve seres humanos e permite desenvolver novos tratamentos ou melhorar os já existentes.'
        }
        show={showClinicalResearchModal}
        onHide={() => setShowClinicalResearchModal(false)}
      />
      <ClinicalResearchInfoModal
        title={'Quem conduz a pesquisa clinica?'}
        description={
          'A pesquisa clínica é conduzida por médicos especializados – o investigador principal – em instituições: hospitais, clínicas, consultórios, dependendo do desenho do protocolo. Ele conta com uma equipe de sua escolha para ajudá-lo na condução do estudo.'
        }
        show={showWhoConductsResearchModal}
        onHide={() => setShowWhoConductsResearchModal(false)}
      />
      <ClinicalResearchInfoModal
        title={'Quem pode participar?'}
        description={
          'Qualquer pessoa pode participar de uma pesquisa, desde que apresente as características (clínicas, laboratoriais ou outras exigências do estudo) que permitam a sua inclusão em determinado estudo específico.'
        }
        show={showWhoCanParticipateModal}
        onHide={() => setShowWhoCanParticipateModal(false)}
      />
      <ClinicalResearchInfoModal
        title={'Como participar?'}
        description={
          'Para participar, a pessoa e/ou seu representante legal, deverá ser orientado sobre todos os aspectos do estudo e, se estiver plenamente de acordo, assinar um documento chamado Termo de Consentimento Livre e Esclarecido (TCLE) antes de iniciar sua participação ou ser submetido a qualquer procedimento relacionado à pesquisa.'
        }
        show={showHowToParticipateModal}
        onHide={() => setShowHowToParticipateModal(false)}
      />
      <ClinicalResearchInfoModal
        title={'Quais as vantagens de participar de uma<br/>pesquisa clinica?'}
        description={
          'Como participante de pesquisa você poderá ter acesso a tratamentos inovadores ainda não disponíveis no mercado nacional e/ou internacional, além de contribuir para o desenvolvimento da Medicina e auxiliar a saúde de outras pessoas no futuro.'
        }
        show={showAdvantagesModal}
        onHide={() => setShowAdvantagesModal(false)}
      />
      <BasePillarsDocumentModal
        title="Código de Nuremberg"
        link={clinicalResearch.nuremberg}
        showModal={showNurembergModal}
        onHide={() => setShowNurembergModal(false)}
      />
      <BasePillarsDocumentModal
        title="Declaração de Helsinque"
        link={clinicalResearch.helsinque}
        showModal={showHelsinqueModal}
        onHide={() => setShowHelsinqueModal(false)}
      />
      <BasePillarsDocumentModal
        title="Diretrizes para Pesquisa em Seres Humanos do CIOMS"
        link={clinicalResearch.cioms}
        showModal={showCIOMSModal}
        onHide={() => setShowCIOMSModal(false)}
      />
    </ClinicalResearchComponentStyles>
  );
}

const ClinicalResearchComponentStyles = styled(Container)``;
