import { ApiResponse } from '@brainz_group/webappframework';
import { AxiosResponse } from 'axios';
import ServiceApiInterface from '../interface/ServiceApiInterface';
import {
  CourseCardViewModel,
  PagedListViewModel,
} from '../domain/models/CourseCardViewModel';
import { CourseDetailsViewModel } from '../domain/models/Course';

export default class TrilhasIntegrationService {
  public async listInstitutionalCoursesPaginated(
    pageNumber: number,
    pageSize: number,
    search = ''
  ): Promise<ApiResponse<PagedListViewModel<CourseCardViewModel>> | undefined> {
    try {
      let endpoint =
        '/TrilhasIntegration/ListInstitutionalCoursesPaginated?PageNumber=' +
        pageNumber +
        '&PageSize=' +
        pageSize +
        '&search=' +
        search;

      let response: AxiosResponse<
        ApiResponse<PagedListViewModel<CourseCardViewModel>>
      > = await ServiceApiInterface.get(endpoint);

      return response.data;
    } catch (error) {
      console.error(
        'TrilhasIntegrationService.listInstitutionalCoursesPaginated()',
        error
      );
    }
  }
  public async getCourseDetails(
    id: string
  ): Promise<ApiResponse<CourseDetailsViewModel> | undefined> {
    try {
      let endpoint = '/TrilhasIntegration/GetCourseDetails?id=' + id;

      let response: AxiosResponse<ApiResponse<CourseDetailsViewModel>> =
        await ServiceApiInterface.get(endpoint);

      return response.data;
    } catch (error) {
      console.error('TrilhasIntegrationService.getCourseDetails()', error);
    }
  }
}
