import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const MainJumbotron = styled(Col) <{ backgroundImage: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

export const MainJumbotronSpecialMobile = styled(Col) <{
  backgroundImage: string;
}>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 600px) {
    background-image: url(${(props) => props.backgroundMobile});
    background-size: contain !important;
    background-position: bottom;
  }
  @media screen and (min-width: 601px) and (max-width: 1210px) {
    background-image: url(${(props) => props.backgroundTablet});
    background-size: contain !important;
    background-position: bottom;
  }
`;

export const ChekoutJumbotron = styled(Col)`
  padding-top: 25vh;
  padding-bottom: 10vh;

  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #9e9e9e;
  @media screen and (max-width: 1199px) {
    padding-top: 40vmax;
    padding-bottom: 25vmax;
  }
`;

export const DocumentCard = styled.a`
  text-decoration: none !important;
  cursor: pointer;

  .card {
    min-height: 145px;
    box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;

    .card-body {
      display: flex;
      align-items: center;
      padding: 38px 24px;
    }

    &:hover {
      box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.2);
    }
  }

  .card-title,
  p {
    text-align: left;
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    color: #000;
    margin: 0;
  }

  p {
    text-align: center;
  }

  .card-text {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #424242;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .card-footer {
    border: none;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 20px;
  }

  /* Mobile */
  @media screen and (max-width: 1210px) {
    .card {
      margin-bottom: 1rem;
      min-height: 120px;
    }

    .card-title,
    p {
      font-size: 20px;
      line-height: 28px;
    }

    .card-body {
      padding: 24px;
    }
  }
`;

export const TopicStyles = styled.div`

cursor: pointer;
color: #72b81f;
text-decoration: none;
`