/* eslint-disable no-empty-pattern */
import { Container } from 'react-bootstrap';
import BaseLayoutComponent from '../../components/layouts/BaseLayoutComponent';
import { useInstitutionalContext } from '../../context/InstitutionalContext';
import styled from 'styled-components';
import { ResearchInovationComponent } from '../../components/research/researchInovation/ResearchInovationComponent';

export default function ResearchInovationScreen({ }: ResearchInovationScreenProps) {
    const { institutionalPageInfo } = useInstitutionalContext();

    return (
        <BaseLayoutComponent showInstitutionalInfo>
            <ResearchInovationComponent
                innovationResearch={institutionalPageInfo!.search.innovationResearch}
            />
        </BaseLayoutComponent>
    );
}

interface ResearchInovationScreenProps { }

export const ResearchInovationScreenStyles = styled(Container)`
  video {
    width: 100% !important;
  }
`;
