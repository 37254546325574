import { Col, Form, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import { FaqRow } from '../../styles/CoursesStyles';
import {
  H1WhiteStyles,
  InputStyles,
  TextAreaStyles,
  SecondaryButton,
} from '../../styles/GlobalStyles';
import InstitutionalService from '../../services/InstitutionalService';
import 'react-toastify/dist/ReactToastify.css';

const institutionalService = new InstitutionalService();

export function FaqComponent(props: any) {
  /*CONTACT FORM*/
  function handleSubmit(e: any, values: any) {
    e.preventDefault();
    institutionalService
      .contactUsEmail(values)
      .then((result) => {
        if (result?.success) {
          toast.success('Mensagem enviada com sucesso!');
        } else {
          toast.error('Erro ao enviar mensagem, tente novamente.');
        }

        props.resetForm({
          email: '',
          content: '',
          name: '',
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error('Erro ao enviar mensagem, tente novamente.');
      });
  }

  return (
    <FaqRow>
      <Col xl={8} className="mx-auto">
        <Row className=" mt-4 mt-xl-5 mb-5">
          <Col xs={12} xl={6}>
            <H1WhiteStyles className="d-none d-md-block my-md-5 my-md-5">
              Dúvidas sobre cursos? Nos mande uma mensagem!
            </H1WhiteStyles>
            <H1WhiteStyles className="d-md-none mb-5">
              Dúvidas sobre
              <br />
              cursos?
              <br />
              Nos mande uma
              <br />
              mensagem!
            </H1WhiteStyles>
          </Col>
          <Col>
            <Form onSubmit={(e: any) => handleSubmit(e, props.values)}>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
              <Row className="mb-3">
                <Col>
                  <InputStyles
                    name="name"
                    type="text"
                    placeholder="Seu nome"
                    value={props.values.name}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <InputStyles
                    type="email"
                    name="email"
                    placeholder="Seu e-mail"
                    value={props.values.email}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <TextAreaStyles
                    rows={10}
                    name="content"
                    placeholder="Escreva sua mensagem"
                    value={props.values.content}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Col>
              </Row>
              {/* {errorMessage && <Row className="mb-3">
              {errorMessage}
              </Row>} */}
              <Row className="mb-xl-3">
                <Col xl="auto" className="ms-auto">
                  <SecondaryButton
                    disabled={
                      !(props.isValid && Object.keys(props.touched).length > 0)
                    }
                    type="submit"
                  >
                    Enviar Mensagem
                  </SecondaryButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </FaqRow>
  );
}
