import BaseLayoutComponent from "../components/layouts/BaseLayoutComponent";
import { TeachingComponent } from "../components/teaching/TeachingComponent";

export default function TeachingScreen() {
  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <TeachingComponent />
    </BaseLayoutComponent>
  );
}
