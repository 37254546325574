import { BrainzAuthContextProvider } from '@brainz_group/webappframework';
import { BrowserRouter } from 'react-router-dom';
import BrainzRoutes from "./BrainzRoutes";
import styled from 'styled-components';
import { InstitutionalContextProvider } from './context/InstitutionalContext';
import { CartProvider } from './context/CartContext';
import { InstitutionalAuthContextProvider } from './context/InstitutionalAuthContext';
import "./styles/mobileStyles.css";

export default function App() {
  return (
    <AppBaseStyle>
      {/* <BrainzAuthContextProvider
            waitAuthentication={false}
        >
        </BrainzAuthContextProvider> */}
      <InstitutionalContextProvider>
        <InstitutionalAuthContextProvider>
          <CartProvider>
            <BrowserRouter >
              <BrainzRoutes />
            </BrowserRouter>
          </CartProvider>
        </InstitutionalAuthContextProvider>
      </InstitutionalContextProvider>
    </AppBaseStyle>
  )
}

const AppBaseStyle = styled.div`
    min-height:100vh;
    width: 100%;
    position: relative;
`