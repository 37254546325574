import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { ContractManagementViewModel } from '../../../domain/models/ResearchCenterPageInfoViewModel';
import { H1Styles, TerciaryGreenButton } from '../../../styles/GlobalStyles';
import HeaderJumbotronComponent from '../HeaderJumbotronComponent';
import ResearchResumeComponent from '../ResearchResumeComponent';
import { ProjectSuportComponent } from './ProjectSuportComponent';

interface ContractManagementComponentProps {
  contractManagement: ContractManagementViewModel;
}

export function ContractManagementComponent({
  contractManagement,
}: ContractManagementComponentProps) {
  const navigate = useNavigate();

  function redirectToContactUs() {
    navigate('/fale-conosco');
  }
  return (
    <ContractManagementComponentStyles fluid>
      <HeaderJumbotronComponent
        title={contractManagement.title}
        backImage={contractManagement.image}
      />

      <ResearchResumeComponent
        title={contractManagement.subTitle}
        description={contractManagement.description}
        image2={contractManagement.imageDescription}
        titleMargin={false}
      />

      <Row>
        <Col
          xl={8}
          xs={11}
          className={`mx-auto ${isMobile ? 'mt-2 px-6' : 'mt-5 text-center'}`}
        >
          <H1Styles className="headlineContractH1">
            {contractManagement.title2}
          </H1Styles>
        </Col>
      </Row>

      <Row>
        <Col xl={8} xs={11} className="mx-auto mt-5">
          <h5>{contractManagement.subTitle2}</h5>
        </Col>
      </Row>

      <ProjectSuportComponent />

      <Row className="ready-to-start-with-us">
        <Col xl={8} xs={11} className="mx-auto">
          <Row>
            <Col xs={11} className="mx-auto text-center">
              <h2>
                Pronto para iniciar seu projeto <br /> com nosso apoio?
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={'auto'} className="mx-auto text-center">
              <span>
                Entre em contato com a equipe Fundação para mais informações.
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={'auto'} className="mx-auto mt-4">
              <TerciaryGreenButton onClick={() => redirectToContactUs()}>
                Entre em contato
              </TerciaryGreenButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContractManagementComponentStyles>
  );
}

const ContractManagementComponentStyles = styled(Container)`
  h5 {
    color: #424242;
    text-align: center;
    font-size: 2.2vmin;
    font-weight: 600;
  }
  span {
    font-weight: 400;
    font-size: 2vmin;
    line-height: 30px;
    color: #424242;
  }
  .bi-search {
    font-size: 7.5vmin;
  }
  .project-suport {
    .card-body {
      .row {
        min-height: 10vmin;
        img {
          width: 7vmin;
        }
      }
    }
  }
  .ready-to-start-with-us {
    margin-top: 5vmin;
    padding: 4vmin 0px;
    background-color: #72b81f;
    h2 {
      color: #fff;
      text-align: center;
      font-size: 3.2vmin;
      font-weight: 600;
    }
    span {
      color: #fff;
      font-size: 2vmin;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 1210px) {
    h1 {
      font-size: 28px;
      line-height: 32px;
    }
    h5 {
      text-align: left;
      font-size: 18px;
    }

    .ready-to-start-with-us {
      padding: 100px 24px;

      h2 {
        font-size: 28px;
        font-weight: 500;
      }
      span {
        margin-top: 1rem;
        font-size: 18px;
        font-weight: 500;
      }

      button {
        margin-top: 1rem;
        width: 100%;
        height: 60px;
        font-size: 20px;
        line-height: 26px;
      }

      @media screen and (max-width: 600px) {
        padding: 60px 0 ;

        h2 {
          font-size: 26px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }
`;
