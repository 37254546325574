import { Row, Col } from 'react-bootstrap';

import {
  MainJumbotron,
  MainJumbotronSpecialMobile,
} from '../../styles/TransparencyPortalStyles';
import { EmptyRow, PageTitle } from '../../styles/GlobalStyles';
import { isMobile } from 'react-device-detect';
import { MobileTopRedirect } from '../MobileTopRedirect';

export interface CoverTitleProps {
  backgroundImage: string;
  backgroundTablet?: string;
  backgroundMobile?: string;
  isSpecialMobile?: boolean;
  pageTitle: string;
  topEmptyRow?: EmptyRowProps;
  bottomEmptyRow?: EmptyRowProps;
  hasGoBack?: boolean;
  goBackOptions?: {
    url: string;
    text: string;
  };
}

export interface EmptyRowProps {
  mobileHeight?: string;
  height?: string;
}

export const CoverTitle = ({
  backgroundImage,
  backgroundTablet,
  backgroundMobile,
  pageTitle,
  topEmptyRow,
  bottomEmptyRow,
  hasGoBack,
  goBackOptions,
  isSpecialMobile,
}: CoverTitleProps) => {
  let customStyle = {};

  return (
    <Row>
      {isSpecialMobile ? (
        <MainJumbotronSpecialMobile
          backgroundImage={backgroundImage}
          backgroundTablet={backgroundTablet}
          backgroundMobile={backgroundMobile}
          style={customStyle}
        >
          <EmptyRow
            height={topEmptyRow?.height}
            mobileHeight={topEmptyRow?.mobileHeight}
          />
          {hasGoBack && (
            <MobileTopRedirect
              url={goBackOptions!.url}
              goBackText={goBackOptions!.text}
            />
          )}
          <Row>
            <Col xl={8} className={isMobile ? 'mx-0 px-4' : 'mx-auto'}>
              <PageTitle>{pageTitle}</PageTitle>
            </Col>
          </Row>
          <EmptyRow
            height={bottomEmptyRow?.height}
            mobileHeight={bottomEmptyRow?.mobileHeight}
          />
        </MainJumbotronSpecialMobile>
      ) : (
        <MainJumbotron
          backgroundImage={backgroundImage}
          backgroundTablet={backgroundTablet}
          backgroundMobile={backgroundMobile}
          style={customStyle}
        >
          <EmptyRow
            height={topEmptyRow?.height}
            mobileHeight={topEmptyRow?.mobileHeight}
          />
          {hasGoBack && (
            <MobileTopRedirect
              url={goBackOptions!.url}
              goBackText={goBackOptions!.text}
            />
          )}
          <Row>
            <Col xl={8} className={isMobile ? 'mx-0 px-4' : 'mx-auto'}>
              <PageTitle>{pageTitle}</PageTitle>
            </Col>
          </Row>
          <EmptyRow
            height={bottomEmptyRow?.height}
            mobileHeight={bottomEmptyRow?.mobileHeight}
          />
        </MainJumbotron>
      )}
    </Row>
  );
};
