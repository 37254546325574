import * as yup from 'yup';
import * as formik from 'formik';
import { cpf } from 'cpf-cnpj-validator';

import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { PurcharseFinalizationComponent } from '../components/purcharseFinalization/PurcharseFinalizationComponent';
import { PurcharseFinalizationContextProvider } from '../context/PurcharseFinalizationContext';
import { PurcharseFormModel } from '../domain/models/PurcharseFormModel';
import { useInstitutionalAuthContext } from '../context/InstitutionalAuthContext';

export function PurcharseFinalizationScreen({}: PurcharseFinalizationScreenProps) {
  const { email } = useInstitutionalAuthContext();

  const { Formik } = formik;
  const schema: yup.ObjectSchema<PurcharseFormModel> = yup.object().shape({
    name: yup.string().required('É obrigatório informar o nome'),

    email: yup.string(),

    cpf: yup
      .string()
      .required('É obrigatório informar o CPF')
      .test('is-valid', 'CPF informado não é válido', (value) => {
        let cpfNumber = value.replaceAll('-', '').replaceAll('.', '');
        return cpf.isValid(cpfNumber);
      }),

    phone: yup
      .string()
      .required('É obrigatório informar o telefone com DDD')
      .test('is-valid', 'O telefone informado não é valido', (value) => {
        const phoneNumber = value?.replace(/\D/g, '');
        return phoneNumber?.length === 10 || phoneNumber?.length === 11;
      }),

    birthDate: yup
      .date()
      .required('É obrigatório informar a data de nascimento')
      .test('is-over-18', 'É necessário ser maior de 18 anos', (value) => {
        var today = new Date();
        var age = today.getFullYear() - value.getFullYear();
        var m = today.getMonth() - value.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < value.getDate())) {
          age--;
        }

        return age >= 18;
      }),

    cep: yup
      .string()
      .required('É obrigatório informar o CEP')
      .test('is-valid', 'CEP informado não é válido', async (value) => {
        let cep = value.replaceAll('-', '').replaceAll('_', '');
        if (cep.length === 8) {
          return true;
        } else {
          return false;
        }
      }),

    neighborhood: yup.string().required('É obrigatório informar o bairro'),

    city: yup.string().required('É obrigatório informar a cidade'),

    state: yup.string().required('É obrigatório informar o estado.'),

    address: yup.string().required('É obrigatório informar o endereço'),

    complement: yup
      .string()
      .required('É obrigatório informar o complemento do endereço'),
  });

  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <Formik
        validationSchema={schema}
        onSubmit={console.log}
        initialValues={{
          name: '',
          email: email,
          cpf: '',
          phone: '',
          birthDate: null as any,
          cep: '',
          neighborhood: '',
          city: '',
          state: '',
          address: '',
          complement: '',
        }}
      >
        {({
          setErrors,
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
          values,
          touched,
          errors,
        }) => (
          <PurcharseFinalizationContextProvider
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            isValid={isValid}
            setErrors={setErrors}
          >
            <PurcharseFinalizationComponent />
          </PurcharseFinalizationContextProvider>
        )}
      </Formik>
    </BaseLayoutComponent>
  );
}

interface PurcharseFinalizationScreenProps {}
