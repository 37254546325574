import { Button, Card, Col } from 'react-bootstrap';
import styled from 'styled-components';

export const MainJumbotron = styled(Col)<{ backgroundImage: string }>`
  background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.86) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

export const ModuleCardContainer = styled.li`
  list-style: none !important;
  text-decoration: none;

  @media screen and (max-width: 990px) {
    margin: 0 !important;
    padding: 0 !important;

    .mxText {
      max-width: auto;
    }
  }
`;

export const ModuleCardItem = styled(Card)`
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  .card-header {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-bottom: none;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .card-footer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-top: none;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }

  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
  }

  .card-body > ul {
    list-style: none !important;
    text-decoration: none;
    padding-left: 0px !important;
  }

  @media screen and (max-width: 990px) {
    margin-bottom: 10px !important;
  }
`;

export const ModuleCardItemTitle = styled.h4`
  color: #514f4e;

  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 0;

  i {
    color: #72b81f;
    font-size: 24px;
    margin-left: 1rem;
  }

  @media screen and (max-width: 1210px) {
    font-size: 20px;
    line-height: 24px;

    i {
      /* display: none; */
      margin-left: -10px;
    }
  }
`;

export const ModuleLessonTitle = styled.h4`
  color: #514f4e;

  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
`;

export const ChevronButton = styled.img<{ rotate: string }>`
  transform: rotate(${(props) => props.rotate});
  @media screen and (max-width: 990px) {
    width: 22px !important;
    position: relative;
    top: 0px;
  }
`;

export const CourseCertificateTag = styled.div`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  gap: 10px;

  padding: 12px;
  min-height: 58px;
  height: auto;
  width: 100%;

  background: #f9f9f9;
  border: 1px solid #e0e1f4;
  border-radius: 10px;

  font-size: 18px;
  line-height: 24px;

  i {
    font-size: 24px;
    color: #72b81f;
  }

  @media screen and (max-width: 1210px) {
  }
`;

export const TransparentOkButton = styled(Button)`
  background-color: #fff0 !important;
  border: none;

  :hover {
    font-weight: 700 !important;
  }
  :focus {
    box-shadow: none !important;
  }
  :active {
    box-shadow: none !important;
  }
  :disabled {
    background-color: #fff0 !important;
  }
  .bi-chevron-down {
    font-size: 1rem;
    color: #72b81f;
  }

  .bi-chevron-up {
    font-size: 1rem;
    color: #72b81f;
  }
`;
