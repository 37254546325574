import { Route, Routes } from "react-router-dom";
import CartScreeen from "./screens/CartScreeen";
import ContactUsScreen from "./screens/ContactUsScreen";
import CourseDetailScreen from "./screens/CourseDetailScreen";
import CourseScreen from "./screens/CourseScreen";
import EventScreen from "./screens/EventScreen";
import HomeScreen from "./screens/HomeScreen"
import TransparencyPortalScreen from "./screens/TransparencyPortalScreen";
import WhoWeAreScreen from "./screens/WhoWeAreScreen";
import TeachingScreen from './screens/TeachingScreen';
import CoremuScreen from './screens/CoremuScreen';
import ProgramsScreen from './screens/ProgramsScreen';
import { PurcharseFinalizationScreen } from "./screens/PurcharseFinalizationScreen";
import ResearchInovationScreen from "./screens/research/ResearchInovationScreen";
import { ClinicalResearchScreen } from "./screens/research/ClinicalResearchScreen";
import { ContractManagementScreen } from "./screens/research/ContractManagementScreen";
import { PurchaseSuccess } from "./screens/PurchaseSuccess";
import { NotFound } from "./screens/NotFound";

function BrainzRoutes() {
  return (
    <Routes>
      <Route key={1} path="/" element={<HomeScreen />} />
      <Route key={2} path="/cursos" element={<CourseScreen />} />
      <Route key={3} path="/curso-detalhes" element={<CourseDetailScreen />} />
      <Route key={4} path="/pesquisa" element={<ResearchInovationScreen />} />
      <Route key={5} path="/pesquisa-clinica" element={<ClinicalResearchScreen />} />
      <Route key={6} path="/gestao-convenios-contratos" element={<ContractManagementScreen />} />

      <Route key={7} path="/eventos" element={<EventScreen />} />
      <Route key={8} path="/portal-transparencia" element={<TransparencyPortalScreen />} />
      <Route key={9} path="/quem-somos" element={<WhoWeAreScreen />} />
      <Route key={10} path="/ensino" element={<TeachingScreen />} />
      <Route key={11} path="/coremu" element={<CoremuScreen />} />
      <Route key={12} path="/programas" element={<ProgramsScreen />} />
      <Route key={13} path="/fale-conosco" element={<ContactUsScreen />} />
      <Route key={14} path="/carrinho" element={<CartScreeen />} />
      <Route key={15} path="/purchase-finalization" element={<PurcharseFinalizationScreen />} />
      <Route key={16} path="/purchase-success" element={<PurchaseSuccess />} />
      <Route key={20} path="*" element={<NotFound />} />

    </Routes>
  );
}

export default BrainzRoutes;