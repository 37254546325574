import { createRoot } from 'react-dom/client';
import './fonts/Steradian/steradian-bold.otf'
import './fonts/Steradian/steradian-regular.otf'
import reportWebVitals from './appConfigs/reportWebVitals';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import './appConfigs/index.css';
import './assets/css/styles.css'

const container = document.getElementById('root');
if(container !== null) {
  const root = createRoot(container);
  root.render(
      <App/>  
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();