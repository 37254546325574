import { useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';

import { TransparentButton } from '../../styles/GlobalStyles';
import { CartContext } from '../../context/CartContext';
import { CourseCartCardComponent } from './CourseCartCardComponent';
import { HeadJumbotron } from './HeadJumbotron';
import { EmptyCartComponent } from './EmptyCartComponent';
import { TotalCardValueComponent } from './TotalCardValueComponent';
import { BadgeSuccess, PriceContainer } from '../../styles/CoursesStyles';
import { BsCashStack } from 'react-icons/bs';
import { CourseDetailsViewModel } from '../../domain/models/Course';
import { isMobile } from 'react-device-detect';

export function CartComponent() {
  const { cartItems, removeFromCart } = useContext(CartContext);

  return (
    <CartComponentStyles fluid={true}>
      <HeadJumbotron />
      <Row>
        <Col xl={8} className="mx-auto">
          {cartItems.length === 0 ? (
            <EmptyCartComponent />
          ) : (
            <Row
              className="cart-list"
              style={{ position: 'relative', top: '-10vh' }}
            >
              <Col xs={11} xl={7} className="mx-auto">
                <Row>
                  <Col>
                    {cartItems.map((course, i) => (
                      <>
                        <Row>
                          <CourseCartCardComponent course={course} />
                        </Row>
                        <Row className="my-3">
                          <Col xs={12} lg={'auto'} className="px-0 me-auto">
                            <TransparentButton
                              onClick={() => removeFromCart(course)}
                            >
                              <Row>
                                <Col xs="auto" className="my-auto pe-0">
                                  <i className="bi bi-trash" />
                                </Col>
                                <Col className="my-auto">Remover Curso</Col>
                              </Row>
                            </TransparentButton>
                          </Col>
                          <Col xs={12} lg={'auto'} className="my-auto">
                            <Row
                              style={
                                isMobile
                                  ? { padding: '12px 32px' }
                                  : { padding: 0 }
                              }
                            >
                              <Col xs="auto" className="d-lg-none my-auto ps-0">
                                <i className="bi bi-tags" />
                              </Col>
                              <Col xs="auto" className="my-auto px-0">
                                {handleRenderPriceContent(course)}
                              </Col>
                              <Col
                                xs="auto"
                                className="d-none d-lg-flex my-auto pe-0"
                              >
                                <i className="bi bi-tags" />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {i !== cartItems.length - 1 && <hr />}
                      </>
                    ))}
                  </Col>
                </Row>
              </Col>
              <TotalCardValueComponent />
            </Row>
          )}
        </Col>
      </Row>
    </CartComponentStyles>
  );
}

const handleRenderPriceContent = (course: CourseDetailsViewModel) => {
  if (course?.priceWithDiscount || course?.priceWithDiscount === 0) {
    if (course.price === 0 || course.price === 0.0) {
      return <BadgeSuccess>Curso Gratuito</BadgeSuccess>;
    }
    return (
      <PriceContainer className="mobileFlex">
        <Col className="my-auto">
          <span className="originalPrice bold">
            <BsCashStack size={18} />
            R$ {course.price.toFixed(2)}
          </span>
        </Col>

        <Col className="my-auto">
          <span className="newPrice bold">
            <BsCashStack size={18} />
            R$ {course.priceWithDiscount.toFixed(2)}
          </span>
        </Col>
      </PriceContainer>
    );
  } else {
    return (
      <PriceContainer className="mobileFlex">
        <Col className="my-auto">
          <span className="newPrice bold">
            <BsCashStack size={18} />
            R$ {course.price.toFixed(2)}
          </span>
        </Col>
      </PriceContainer>
    );
  }
};

const CartComponentStyles = styled(Container)`
  .cart-list {
    position: relative;
    top: -10vh;
  }
  @media screen and (max-width: 1199px) {
    font-size: 1.2rem;

    .bi-trash {
      font-size: 1.5rem;
    }
    .bi-tags {
      font-size: 1.8rem;
    }
    .cart-list {
      position: relative;
      top: auto;
    }
  }
`;
