import { Container } from 'react-bootstrap';
import { BsHouseDoor } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import { SecondaryButton } from '../../styles/GlobalStyles';
import image from '../../assets/image/unplugged.svg';
import BaseLayoutComponent from '../../components/layouts/BaseLayoutComponent';
import { PurcharseFinalizationJumbotronComponent as HeaderJumbotron } from '../../components/purcharseFinalization/PurcharseFinalizationJumbotronComponent';
import { NotFoundContainer } from './styles';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <Container fluid>
        <HeaderJumbotron
          pageTitle="Página não encontrada"
          backLink="/"
          backTitle="VOLTAR PARA A PÁGINA INICIAL"
        />

        <NotFoundContainer>
          <img src={image} alt="Página não encontrada" />
          <h3>Página não encontrada</h3>
          <span className="text-lg text-center lg:text-left ">
            Tente novamente ou clique no botão abaixo para voltar ao início.
          </span>
          <SecondaryButton onClick={() => navigate('/')}>
            <BsHouseDoor size={22} />
            Voltar ao Início
          </SecondaryButton>
        </NotFoundContainer>
      </Container>
    </BaseLayoutComponent>
  );
};
