import { TailSpin } from "react-loader-spinner";

export const PageLoader = () => {
  return (
    <div id="loader" className="loader-background">
      <TailSpin
        color="#72b81f"
        height={100}
        width={100}
        wrapperStyle={{ margin: "auto" }}
      />
    </div>
  );
};

