import { ApiResponse, PublicApiInterface } from '@brainz_group/webappframework';
import { AxiosResponse } from 'axios';
import { InstitutionalPageInfoViewModel } from '../domain/models/InstitutionalPageInfoViewModel';
import ServiceApiInterface from '../interface/ServiceApiInterface';
import { VerifiCodePayload } from '../domain/payload/VerifiCodePayload';
import { ContactUsEmailPayload } from '../domain/payload/ContactUsEmailPayload';
import { PaymentPayload } from '../context/PurcharseFinalizationContext';

export default class InstitutionalService {
  public async getInstitutionalPageInfo(): Promise<
    ApiResponse<InstitutionalPageInfoViewModel> | undefined
  > {
    try {
      let endpoint =
        `/Institutional/List?id=` + process.env.REACT_APP_INSTITUTION_ID;

      let response: AxiosResponse<ApiResponse<InstitutionalPageInfoViewModel>> =
        await ServiceApiInterface.get(endpoint);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.getInstitutionalPageInfo()', error);
    }
  }
  public async sendVerifyCode(
    email: string
  ): Promise<ApiResponse<InstitutionalPageInfoViewModel> | undefined> {
    try {
      let endpoint = `/Institutional/SendCode?email=` + email;

      let response: AxiosResponse<ApiResponse<any>> =
        await ServiceApiInterface.post(endpoint);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.sendVerifyCode()', error);
    }
  }

  public async verifyCode(
    payload: VerifiCodePayload
  ): Promise<ApiResponse<VerifiCodePayload> | undefined> {
    try {
      let endpoint = `/Institutional/VerifyCode`;

      let response: AxiosResponse<ApiResponse<any>> =
        await ServiceApiInterface.post(endpoint, payload);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.verifyCode()', error);
    }
  }

  public async contactUsEmail(
    payload: ContactUsEmailPayload
  ): Promise<ApiResponse<ContactUsEmailPayload> | undefined> {
    try {
      let endpoint = `/Institutional/SendContactUsEmail`;

      let response: AxiosResponse<ApiResponse<any>> =
        await ServiceApiInterface.post(endpoint, payload);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.contactUsEmail()', error);
    }
  }

  public async paymentFinalization(
    payload: PaymentPayload
  ): Promise<ApiResponse<ContactUsEmailPayload> | undefined> {
    try {
      let endpoint = `/PaymentIntegration/Payment`;

      let response: AxiosResponse<ApiResponse<any>> =
        await ServiceApiInterface.post(endpoint, payload);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.contactUsEmail()', error);
    }
  }

  public async getWordpressData(
    parameter: string
  ): Promise<ApiResponse<InstitutionalPageInfoViewModel> | undefined> {
    try {
      let endpoint = `/Institutional/Wordpress?parameter=${parameter}`;

      let response: AxiosResponse<ApiResponse<InstitutionalPageInfoViewModel>> =
        await ServiceApiInterface.get(endpoint);

      return response.data;
    } catch (error) {
      console.error('InstitutionalService.getInstitutionalPageInfo()', error);
    }
  }
}
