import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ContactUsViewModel } from '../../domain/models/ContactUsViewModel';
import {
  H3Styles,
  InstitutionalInformationIconDiv,
  PBlackStyles,
} from '../../styles/GlobalStyles';
import { InstitutionalInformationComponentStyles } from './styles';

export function InstitutionalInformationComponent({
  address,
  phone,
  corporateWhatsapp,
  email,
}: ContactUsViewModel): JSX.Element {
  return (
    <InstitutionalInformationComponentStyles className="d-none d-lg-block footerInfoRow">
      <Col
        md={{
          span: 8,
          offset: 2,
        }}
      >
        <Row className="mb-3">
          <Col>
            <Row>
              <Col md="auto">
                <InstitutionalInformationIconDiv color="#72B81F">
                  <Row className="h-100">
                    <Col md="auto" className="mx-auto my-auto">
                      <i className="bi bi-signpost-split" />
                    </Col>
                  </Row>
                </InstitutionalInformationIconDiv>
              </Col>
              <Col>
                <Row>
                  <H3Styles>ENDEREÇO</H3Styles>
                </Row>
                <Row>
                  <Col md={10}>
                    <PBlackStyles
                      dangerouslySetInnerHTML={{ __html: address }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md="auto">
                <InstitutionalInformationIconDiv color="#72B81F">
                  <Row className="h-100">
                    <Col md="auto" className="mx-auto my-auto">
                      <i className="bi bi-at" />
                    </Col>
                  </Row>
                </InstitutionalInformationIconDiv>
              </Col>
              <Col>
                <Row>
                  <H3Styles>EMAIL</H3Styles>
                </Row>
                <Row>
                  <Col md={10}>
                    <PBlackStyles>{email}</PBlackStyles>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col md="auto">
                <InstitutionalInformationIconDiv color="#72B81F">
                  <Row className="h-100">
                    <Col md="auto" className="mx-auto my-auto">
                      <i className="bi bi-telephone" />
                    </Col>
                  </Row>
                </InstitutionalInformationIconDiv>
              </Col>
              <Col>
                <Row>
                  <H3Styles>TELEFONE</H3Styles>
                </Row>
                <Row>
                  <Col md={10}>
                    <PBlackStyles>{phone}</PBlackStyles>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <a href="https://wa.me/5567999965117">
              <Row>
                <Col md="auto">
                  <InstitutionalInformationIconDiv color="#72B81F">
                    <Row className="h-100">
                      <Col md="auto" className="mx-auto my-auto">
                        <i className="bi bi-whatsapp" />
                      </Col>
                    </Row>
                  </InstitutionalInformationIconDiv>
                </Col>
                <Col>
                  <Row>
                    <H3Styles>WHATSAPP</H3Styles>
                  </Row>
                  <Row>
                    <Col md={10}>
                      <PBlackStyles>{corporateWhatsapp}</PBlackStyles>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </a>
          </Col>
        </Row>
      </Col>
    </InstitutionalInformationComponentStyles>
  );
}
