import jwt_decode from "jwt-decode";
import { BrainzAuthTokenViewModel } from "@brainz_group/webappframework";
import ServiceAccountInterface from "./ServiceAccountInterface";

class ServiceAccountTokenProvider {
    private static instance: ServiceAccountTokenProvider;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() { }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): ServiceAccountTokenProvider {
        if (!ServiceAccountTokenProvider.instance) {
            ServiceAccountTokenProvider.instance = new ServiceAccountTokenProvider();
        }

        return ServiceAccountTokenProvider.instance;
    }

    /**
     * Finally, any singleton should define some business logic, which can be
     * executed on its instance.
     */

    setToken = (query: string) => {
        try {
            sessionStorage.setItem(String(process.env.REACT_APP_BRAINZ_SERVICE_TOKEN_NAME), query);
        } catch (error) {
            console.error("error at BrainzTokenService.setToken():",error);
            
        }
        
    };

    getToken = async(): Promise<string|null> => {
        try {
            let token = sessionStorage.getItem(String(process.env.REACT_APP_BRAINZ_SERVICE_TOKEN_NAME) as string)
            if(token!== null){
                let decoded =  jwt_decode<BrainzAuthTokenViewModel>(token);

                let remainingSeconds = decoded.exp - Date.now() / 1000;

                if(remainingSeconds <0){

                    token = await this.renewToken()
                }
            }else{
                token = await this.renewToken()
            }
            return token
        } catch (error) {
            console.error("error at BrainzTokenService.getToken():",error);
        }
        return null
    };

    async getDecodedToken(): Promise<BrainzAuthTokenViewModel|null> {
        try {

            let stringToken = await this.getToken()
            
            if(stringToken!== null){
                return jwt_decode<BrainzAuthTokenViewModel>(stringToken);
            }
        } catch (error) {
            console.error("error at BrainzTokenService.getDecodedToken():",error);
        }
        return null
    }

    async renewToken():Promise<string>{
        try {        
            let payload = {
                login:String(process.env.REACT_APP_BRAINZ_SERVICE_ACCOUNT_LOGIN) as string,
                password:String(process.env.REACT_APP_BRAINZ_SERVICE_ACCOUNT_PASS) as string,
                applicationId: process.env.REACT_APP_APPLICATION_ID,
                type: 1,
              
            }

            let response = await ServiceAccountInterface.post("/Account/Authorization", payload)
            this.setToken(response.data.result.token)
            return response.data.result.token
        } catch (error) {
            console.error("error at BrainzTokenService.refreshToken():",error);   
        }
        return ""
    }      
}


export default ServiceAccountTokenProvider 


