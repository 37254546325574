import axios, { AxiosRequestConfig } from "axios";
import ServiceAccountTokenProvider from "./ServiceAccountTokenProvider";

const tokenService = ServiceAccountTokenProvider.getInstance()

const ServiceApiInterface = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api/v1",
  headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      "Content-Type": "application/json",
      "accept": "/"
  }
})

ServiceApiInterface.interceptors.request.use(
  async function (config):Promise<AxiosRequestConfig> {
    try {      
        let token = await tokenService.getToken()
        config = {
            ...config,
            headers:{
                "Authorization": "Bearer " + token
            }
        }
      return config

    } catch (error) {
      console.error("ServiceApiInterface Error:",error);
      return config
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

ServiceApiInterface.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error: any) {
    if (error.response.status === 401) {
        console.error("ServiceApiInterface:","Não autorizado");
    }
    if (error.response.status === 403) {
        console.error("ServiceApiInterface:","Acesso não permitido");
    }
    if (error.response.status === 404) {
        console.error("ServiceApiInterface:","Houve um erro, favor tentar novamente mais tarde");
    }
    return error;
  }
);

export default ServiceApiInterface;