import { Card, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { CardStyles } from '../../../styles/GlobalStyles';
import { useClinicalResearchContext } from '../../../context/ClinicalResearchContext';

export function ClinicalResearchInfoComponent() {
  const {
    setShowAdvantagesModal,
    setShowHowToParticipateModal,
    setShowWhoCanParticipateModal,
    setShowClinicalResearchModal,
    setShowWhoConductsResearchModal,
  } = useClinicalResearchContext();
  return (
    <ClinicalResearchInfoComponentStyles>
      <Col xs={11} lg={7} className="mx-auto">
        <Row>
          <Col md={6} xl={4}>
            <ClinicalResearchInfoCardStyles
              onClick={() => setShowClinicalResearchModal(true)}
            >
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <span>O que é Pesquisa Clínica?</span>
                  </Col>
                </Row>
                <Row className="bottom">
                  <Col md={'auto'} className="mx-auto">
                    <img src={'/icons/clinicalResearch/dna-research.svg'} />
                  </Col>
                </Row>
              </Card.Body>
            </ClinicalResearchInfoCardStyles>
          </Col>
          <Col md={6} xl={4}>
            <ClinicalResearchInfoCardStyles
              onClick={() => setShowHowToParticipateModal(true)}
            >
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <span>Como participar?</span>
                  </Col>
                </Row>
                <Row className="bottom">
                  <Col md={'auto'} className="mx-auto">
                    <img src={'/icons/clinicalResearch/group-of-people.svg'} />
                  </Col>
                </Row>
              </Card.Body>
            </ClinicalResearchInfoCardStyles>
          </Col>
          <Col md={12} xl={4}>
            <ClinicalResearchInfoCardStyles
              onClick={() => setShowWhoConductsResearchModal(true)}
            >
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <span>
                      Quem conduz a<br />
                      pesquisa clínica?
                    </span>
                  </Col>
                </Row>
                <Row className="bottom">
                  <Col md={'auto'} className="mx-auto">
                    <img src={'/icons/clinicalResearch/scientist.svg'} />
                  </Col>
                </Row>
              </Card.Body>
            </ClinicalResearchInfoCardStyles>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6} xl={4}>
            <ClinicalResearchInfoCardStyles
              onClick={() => setShowWhoCanParticipateModal(true)}
            >
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <span>Quem pode participar?</span>
                  </Col>
                </Row>
                <Row className="bottom">
                  <Col md={'auto'} className="mx-auto">
                    <img src={'/icons/clinicalResearch/head-question.svg'} />
                  </Col>
                </Row>
              </Card.Body>
            </ClinicalResearchInfoCardStyles>
          </Col>
          <Col md={6} xl={4}>
            <ClinicalResearchInfoCardStyles
              onClick={() => setShowAdvantagesModal(true)}
            >
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <span>
                      Quais as vantagens de participar de uma pesquisa clínica?
                    </span>
                  </Col>
                </Row>
                <Row className="bottom">
                  <Col md={'auto'} className="mx-auto">
                    <img src={'/icons/clinicalResearch/medical-symbol.svg'} />
                  </Col>
                </Row>
              </Card.Body>
            </ClinicalResearchInfoCardStyles>
          </Col>
        </Row>
      </Col>
    </ClinicalResearchInfoComponentStyles>
  );
}
const ClinicalResearchInfoCardStyles = styled(CardStyles)`
  cursor: pointer;
  min-height: 17.5vmin;
  margin: 2vmin;
  span {
    color: #72b81f;
    text-align: center;
    font-size: 1.7vmin;
    font-weight: 600;
  }
  img {
    width: 7vmin;
  }
  .bottom {
    position: absolute;
    width: 100%;
    bottom: 3vmin;
  }

  @media screen and (max-width: 1199px) {
    min-height: 180px;
    margin: 1rem 0;

    .bottom {
      position: initial;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
    }

    img {
      width: 67px;
    }

    span {
      font-size: 18px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
const ClinicalResearchInfoComponentStyles = styled(Row)`
  background: #e9e9e9;
  padding: 3vmin 0px;
`;
