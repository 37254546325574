import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const FooterContainer = styled(Container)`
  font-family: 'Steradian', sans-serif;
  background: #e9e9e9;
  padding: 0 !important;
  a {
    text-decoration: none;
  }
  p {
    -webkit-user-select: auto !important; /* Safari */
    -ms-user-select: auto !important; /* IE 10 and IE 11 */
    user-select: auto !important;

    font-size: 20px;
    line-height: 26px;
    color: #424242;
  }
  h3 {
    -webkit-user-select: auto !important; /* Safari */
    -ms-user-select: auto !important; /* IE 10 and IE 11 */
    user-select: auto !important;

    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 2.3px;
    color: #424242;
  }

  @media screen and (max-width: 600px) {
    p {
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1210px) {
    p {
      font-size: 16px;
    }
  }
`;

export const DevelopedBy = styled.section`
  background: #ededed;
  width: 100%;
  height: 75px;

  padding: 28px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: rgba(66, 66, 66, 1);
    font-size: 14px;
  }

  @media screen and (max-width: 1210px) {
    text-align: center;

    border-radius: 20px 20px 0 0;
    border: 1px solid #fff;
    padding: 29px auto;

    span {
      padding-left: 40px;
      padding-right: 40px;
      font-size: 15px;
    }
  }
`;
