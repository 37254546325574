import * as yup from 'yup';
import * as formik from 'formik';
import { Container } from 'react-bootstrap';

import { CourseMainJumbotronComponent } from './CourseMainJumbotronComponent';
import { FaqComponent } from './FaqComponent';
import { AvaliableCoursesComponent } from './AvaliableCoursesComponent';
import { ContactUsEmailPayload } from '../../domain/payload/ContactUsEmailPayload';
import { useInstitutionalContext } from '../../context/InstitutionalContext';

export function CoursesComponent() {

  const { Formik } = formik;

  const {institutionalPageInfo} = useInstitutionalContext()


  const schema: yup.ObjectSchema<ContactUsEmailPayload> = yup.object().shape({
    content: yup.string().required('É obrigatório informar o nome'),

    email: yup.string()
    .email()
    .required('É obrigatório informar o CPF'),

    name: yup
      .string()
      .required('É obrigatório informar o CPF')
  });

  return (
    <Container fluid={true}>
      <CourseMainJumbotronComponent backImage={institutionalPageInfo!.home.imageInstitutional} />
      <AvaliableCoursesComponent />
      <Formik
            validationSchema={schema}
            onSubmit={console.log}
            initialValues={{
              name: '',
              email: '',
              content: ''
            }}
          >
            {({
              setErrors,
              setValues,
              handleBlur,
              handleChange,
              isValid,
              values,
              touched,
              errors,
              resetForm
            }) => (
              <FaqComponent 
                handleBlur={handleBlur} 
                handleChange={handleChange} 
                resetForm={resetForm}
                isValid={isValid} 
                touched={touched}
                values={values}/>
            )}
          </Formik>
    </Container>
  );
}
