import { ReactNode, createContext, useContext, useRef, useState } from "react";
import { InstitutionalUserAuthModalStateEnum } from "../domain/enums/InstitutionalUserAuthModalStateEnum";
import { auth, googleAuthProvider, microsoftAuthProvider } from "../provider/Firebase";
import { GoogleAuthProvider, OAuthCredential, OAuthProvider, signInWithPopup } from "firebase/auth";
import jwtDecode from "jwt-decode";
import InstitutionalService from "../services/InstitutionalService";

const InstitutionalAuthContext = createContext({} as InstitutionalAuthContextData)
const institutionalService = new InstitutionalService();


interface InstitutionalAuthContextProviderProps {
    children: ReactNode;
}

export function InstitutionalAuthContextProvider(props: InstitutionalAuthContextProviderProps) {
    const [showAuthModal, setShowAuthModal] = useState(false)
    const [resendingCode, setResendingCode] = useState(false)
    const [isInvalidCode, setIsInvalidCode] = useState(false)


    const [isIndentifing, setIsIndentifing] = useState(false)
    const [validationCode, setValidationCode] = useState<string>('')

    const buyerEmailStorageName = 'authBuyerEmail'

    const email = getAuthEmail()


    const [modalState, setModalState] = useState<InstitutionalUserAuthModalStateEnum>(InstitutionalUserAuthModalStateEnum.SignIn)

    async function signUpWithGoogle() {
        try {
            setIsIndentifing(true);
            googleAuthProvider.setCustomParameters({
                prompt: "select_account",
            });

            let result = await signInWithPopup(auth, googleAuthProvider);

            const credential = GoogleAuthProvider.credentialFromResult(result);

            if (credential != null) {
                let decodedToken = jwtDecode<GoogleIdTokenModel>(credential.idToken!)
                window.sessionStorage.setItem(buyerEmailStorageName, decodedToken.email);
                await SendVerificationCode(decodedToken.email)
            }

        }
        catch (error: any) {
            console.error(error);
            if (error.code === 'auth/account-exists-with-different-credential') {
                const credential = GoogleAuthProvider.credentialFromResult(error.customData) as OAuthCredential;
                if (credential != null) {
                    let decodedToken = jwtDecode<MicrosoftIdTokenModel>(credential.idToken!)
                    window.sessionStorage.setItem(buyerEmailStorageName, decodedToken.email);
                    await SendVerificationCode(decodedToken.email)
                }
                else {
                    console.error(error);
                }
            }
        }
        setIsIndentifing(false);
    }

    async function signUpWithMicrosoft() {
        try {
            setIsIndentifing(true);
            microsoftAuthProvider.setCustomParameters({
                prompt: "select_account",
            });

            let result = await signInWithPopup(auth, microsoftAuthProvider);

            const credential = OAuthProvider.credentialFromResult(result);

            if (credential != null) {
                let decodedToken = jwtDecode<MicrosoftIdTokenModel>(credential.idToken!)
                window.sessionStorage.setItem(buyerEmailStorageName, decodedToken.email);
                await SendVerificationCode(decodedToken.email)

            }
        }
        catch (error: any) {
            if (error.code === 'auth/account-exists-with-different-credential') {
                const credential = OAuthProvider.credentialFromResult(
                    error.customData
                ) as OAuthCredential;

                if (credential != null) {
                    let decodedToken = jwtDecode<GoogleIdTokenModel>(credential.idToken!)
                    window.sessionStorage.setItem(buyerEmailStorageName, decodedToken.email);
                    await SendVerificationCode(decodedToken.email)
                } else {
                    console.error(error);
                }
            }
        }
        setIsIndentifing(false);

    }

    async function SendVerificationCode(email: string) {
        let response = await institutionalService.sendVerifyCode(email)
        if (response?.success) {
            setModalState(InstitutionalUserAuthModalStateEnum.CodeValidation)
            clearTimer(getDeadTime())
        }
    }
    function handleCodeValidationChange(e: React.ChangeEvent<any>) {
        let code = e.target.value.replaceAll('_', '')
        setValidationCode(code)
        setIsInvalidCode(false)
    }

    async function handleCodeValidation(isFreeCourse = false) {

        let email = window.sessionStorage.getItem(buyerEmailStorageName)!
        let payload = {
            email: email,
            code: validationCode
        }

        let response = await institutionalService.verifyCode(payload)

        if (response?.success) {
            setShowAuthModal(false)

            const url = {
                origin: window.location.origin,
                host: window.location.host,
                pathname: window.location.pathname,
                search: window.location.search
            }
            if (isFreeCourse) {
                window.location.assign(`${url.origin}/purchase-finalization${url.search}&subscription=true`)
            } else {
                window.location.assign(`${url.origin}/purchase-finalization${url.search}`)
            }
        } else {
            setIsInvalidCode(true)
        }

    }
    async function handleResendCodeValidation() {
        setResendingCode(true)

        let email = window.sessionStorage.getItem(buyerEmailStorageName) as string
        await SendVerificationCode(email)
        clearTimer(getDeadTime())

        setResendingCode(false)

    }

    function onModalClose() {
        window.sessionStorage.removeItem(buyerEmailStorageName)
        if (currentTimer != null) {
            resetTimer()
        }
        setShowAuthModal(false)
        setIsIndentifing(false)
    }

    function showModal() {
        setModalState(InstitutionalUserAuthModalStateEnum.SignIn)
        setShowAuthModal(true)
    }

    function getAuthEmail(): string {
        return window.sessionStorage.getItem(buyerEmailStorageName)!
    }

    const [timer, setTimer] = useState('05:00');
    const [currentTimer, setCurrentTimer] = useState<NodeJS.Timer>(null as any)

    const getTimeRemaining = (e: string) => {
        const total = Date.parse(e) - Date.parse(String(new Date()));
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e: string) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);

        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'  // check if less than 10 then we need to 
                + (seconds > 9 ? seconds : '0' + seconds)  // add '0' at the beginning of the variable
            )
        }
    }

    const clearTimer = (e: string | Date) => {
        if (currentTimer != null) {
            resetTimer()
        }
        const id = setInterval(() => {
            return startTimer(String(e));
        }, 1000)

        setCurrentTimer(id)
    }

    const resetTimer = () => {
        const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
        }
        setTimer('05:00')
        setCurrentTimer(null as any)
    }

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 300); // 5 minutos
        return deadline;
    }

    // useEffect(() => {
    //     if (showNotEnteredModal) clearTimer(getDeadTime());
    // }, [showNotEnteredModal]);

    return (
        <>
            <InstitutionalAuthContext.Provider value={{
                buyerEmailStorageName,
                showAuthModal, setShowAuthModal,
                modalState, setModalState,
                isIndentifing, setIsIndentifing,
                timer,
                email,
                getAuthEmail,
                validationCode, setValidationCode,
                resendingCode,
                isInvalidCode,

                signUpWithMicrosoft,
                signUpWithGoogle,
                handleCodeValidation,
                handleResendCodeValidation,
                handleCodeValidationChange,

                onModalClose,
                showModal
            }}>
                {
                    props.children
                }
            </InstitutionalAuthContext.Provider>
        </>
    )
}

export function useInstitutionalAuthContext() {
    return useContext(InstitutionalAuthContext);
}

interface InstitutionalAuthContextData {
    buyerEmailStorageName: string

    showAuthModal: boolean
    setShowAuthModal: React.Dispatch<React.SetStateAction<boolean>>

    isIndentifing: boolean
    setIsIndentifing: React.Dispatch<React.SetStateAction<boolean>>

    validationCode: string,
    setValidationCode: React.Dispatch<React.SetStateAction<string>>

    modalState: InstitutionalUserAuthModalStateEnum
    setModalState: React.Dispatch<React.SetStateAction<InstitutionalUserAuthModalStateEnum>>

    resendingCode: boolean
    isInvalidCode: boolean

    timer: string
    email: string
    getAuthEmail: () => string

    showModal: () => void;
    onModalClose: () => void;

    signUpWithGoogle: () => void;
    signUpWithMicrosoft: () => void;
    handleCodeValidation: (isFreeCourse?: any) => void;
    handleResendCodeValidation: () => void;
    handleCodeValidationChange: (e: React.ChangeEvent<any>) => void;
}

export interface MicrosoftIdTokenModel {
    iss: string;
    iat: number;
    nbf: number;
    exp: number;
    email: string;
    name: string;
    oid: string;
    preferred_username: string;
    rh: string;
    sub: string;
    tid: string;
    uti: string;
    ver: string;
}

export interface GoogleIdTokenModel {
    iss: string;
    azp: string;
    aud: string;
    sub: string;
    hd: string;
    email: string;
    email_verified: boolean;
    at_hash: string;
    iat: number;
    exp: number;
}

