import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import { H1Styles, PBlackStyles } from '../../styles/GlobalStyles';
import { useInstitutionalContext } from '../../context/InstitutionalContext';
import { CoverTitle } from '../CoverTitle';

export function TeachingComponent() {
  const { institutionalPageInfo } = useInstitutionalContext();

  if (institutionalPageInfo === undefined) {
    return <></>;
  }

  const backImage = institutionalPageInfo?.teaching?.image;
  const { title, description } = institutionalPageInfo?.teaching?.presentation;

  return (
    <Container fluid={true}>
      <CoverTitle
        backgroundImage={backImage}
        pageTitle={title}
        topEmptyRow={{
          height: '25vh',
          mobileHeight: '20rem',
        }}
        bottomEmptyRow={{
          height: '20vh',
          mobileHeight: '10rem',
        }}
      />

      <Row>
        <Col xl={8} className="mx-auto mt-5 insitutionalPortalMainCol">
          <Row>
            <Col className="insitutionalPortalMainHeading">
              <H1Styles>Apresentação</H1Styles>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5 mb-5 insitutionalPortalDescriptionCol">
              <PBlackStyles>{description}</PBlackStyles>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
