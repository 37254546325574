import { Col, Row } from 'react-bootstrap';
import { H1Styles, H2Styles, PBlackStyles } from '../../../styles/GlobalStyles';
import styled from 'styled-components';
import { useClinicalResearchContext } from '../../../context/ClinicalResearchContext';

export function EthicalPillarsComponent() {
  const { setShowCIOMSModal, setShowHelsinqueModal, setShowNurembergModal } =
    useClinicalResearchContext();
  return (
    <EthicalPillarsComponentStyles>
      <Col xs={11} xl={8} className="mx-auto">
        <Row>
          <Col xs={11} xl={6} className="mx-xl-auto text-xl-center">
            <H1Styles className="mb-4">
              Pilares Básicos que constituem a Ética em Pesquisa em seres
              humanos
            </H1Styles>
          </Col>
        </Row>

        <section className="trianglesContainer">
          <Row className="mt-xl-5">
            <GreenTriangleButton
              sm={12}
              xl={'auto'}
              className="mx-auto triangleContainer1"
            >
              <div
                className="triangle"
                onClick={() => setShowNurembergModal(true)}
              />
              <span onClick={() => setShowNurembergModal(true)} role="button">
                Código de Nuremberg
              </span>
            </GreenTriangleButton>
          </Row>
          <Row className="mt-5">
            <BlackTriangleButton
              xl={'auto'}
              className="ms-auto triangleContainer2"
            >
              <div
                className="triangle"
                onClick={() => setShowCIOMSModal(true)}
              />
              <span role="button" onClick={() => setShowCIOMSModal(true)}>
                Diretrizes para Pesquisa em Seres Humanos do CIOMS
              </span>
            </BlackTriangleButton>
            <Col xl={'auto'} className="pillarsContainer">
              <span className="pillars">
                Pilares
                <br /> Básicos
              </span>
            </Col>
            <GrayTriangleButton
              xl={'auto'}
              className="me-auto triangleContainer3"
            >
              <div
                className="triangle"
                onClick={() => setShowHelsinqueModal(true)}
              />
              <span role="button" onClick={() => setShowHelsinqueModal(true)}>
                Declaração de <br /> Helsinque
              </span>
            </GrayTriangleButton>
          </Row>
        </section>
      </Col>
    </EthicalPillarsComponentStyles>
  );
}

const EthicalPillarsComponentStyles = styled(Row)`
  background: #e9e9e9;
  padding: 5vmin 0px;
  .top-row {
    margin-bottom: 2vmin;
  }
  .base-row {
    position: relative;
    top: -6vmin;
  }
  .pillars {
    position: relative;
    top: 12%;
    width: 15vmin;
    color: #424242;
    text-align: center;
    font-size: 2vmin;
    line-height: 2.3vmin;
    font-weight: 700;
    text-transform: uppercase;
  }

  .trianglesContainer {
    position: relative;

    @media screen and (max-width: 600px) {
      height: 24rem;
      .triangleContainer1,
      .triangleContainer2,
      .triangleContainer3 {
        position: absolute;

        span {
          font-size: 12px;
          line-height: 16px;
          max-width: 5.6rem;
        }
      }

      .triangleContainer1 {
        top: 2rem;
        left: 7rem;

      }

      .triangleContainer2 {
        left: 1rem;
        top: 13rem;

        span {
          top: -28%;
          max-width: 6.1rem;
        }
      }

      .triangleContainer3 {
        left: 13rem;
        top: 13rem;

        span {
          top: -26%;
          max-width: 5.9rem;
        }
      }

      .pillarsContainer {
        position: absolute;
        top: 12rem;
        left: 7rem;
        width: auto;

        .pillars {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 1.61px;
          width: auto;
        }
      }
    }
    @media screen and (min-width: 600px) and (max-width: 1210px) {
      height: 40rem;
      .triangleContainer1,
      .triangleContainer2,
      .triangleContainer3 {
        position: absolute;
      }

      .triangleContainer1,
      .triangleContainer2,
      .triangleContainer3 {
        span {
          font-size: 18px;
          line-height: 28px;
        }
      }

      .triangleContainer1 {
        top: 3rem;
        left: 16rem;

        span {
          max-width: 10.5rem;
          top: -38%;
        }
      }

      .triangleContainer2 {
        left: 5rem;
        top: 23rem;

        span {
          max-width: 11rem;
          top: -35%;
          font-size: 18px;
        }
      }

      .triangleContainer3 {
        left: 27rem;
        top: 23rem;

        span {
          max-width: 10.8rem;
          top: -30%;
        }
      }

      .pillarsContainer {
        position: absolute;
        top: 22rem;
        left: 17.4rem;
        width: auto;

        .pillars {
          font-size: 24px;
          line-height: 40px;
          letter-spacing: 1.61px;
          width: auto;
        }
      }
    }
  }
`;
const TriangularButtonCol = styled(Col)`
  height: 28vmin;
  .triangle {
    padding: 0;
    cursor: pointer;
    z-index: 0;
    position: relative;
    width: 20vmin;
    max-width: 20vmin;
    height: 20vmin;
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    border-top-right-radius: 20%;
    box-shadow: 4px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .triangle:after {
    margin: 0;
    padding: 0;
    z-index: 0;
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: inherit;
    border-top-right-radius: inherit;
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translateY(-50%);
    box-shadow: -4px -2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .triangle:before {
    margin: 0;
    padding: 0;
    z-index: 1;
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: inherit;
    border-top-right-radius: inherit;
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translateX(50%);
    box-shadow: 2px -4px 4px 0px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    .triangle {
      box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .triangle:after {
      box-shadow: -10px -10px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .triangle:before {
      box-shadow: 10px -10px 10px 0px rgba(0, 0, 0, 0.1);
    }
    span {
      font-weight: 600;
    }
  }
  span {
    text-align: center;
    position: relative;
    max-width: 20vmin;
    width: 100%;
    top: -30%;

    color: #fff;
    font-size: 2vmin;
    line-height: 2.3vmin;
    font-weight: 500;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    height: 10rem;
    justify-content: center;
    .triangle {
      width: 6rem;
      max-width: 6rem;
      height: 6rem;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1210px) {
    justify-content: center;
    height: 16rem;

    .triangle {
      width: 11rem;
      max-width: 11rem;
      height: 11rem;
    }
  }
`;
export const GreenTriangleButton = styled(TriangularButtonCol)`
  .triangle {
    background: #72b81f;
  }
`;

export const BlackTriangleButton = styled(TriangularButtonCol)`
  .triangle {
    background: #424242;
  }
`;

export const GrayTriangleButton = styled(TriangularButtonCol)`
  .triangle {
    background: #9e9e9e;
  }
`;
