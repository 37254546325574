import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  BadgeSuccess,
  CourseFooterContainer,
  PriceContainer,
  WorkLoad,
} from '../../styles/CoursesStyles';
import { ContentCard } from '../../styles/GlobalStyles';
import { CourseCardViewModel } from '../../domain/models/CourseCardViewModel';
import {
  FormatWorkload,
  FormatWorkloadHours,
} from '../../domain/util/FormatWorkload';
import { BsAlarm, BsCashStack } from 'react-icons/bs';

interface CourseDetailsCardComponentProps {
  course: CourseCardViewModel;
}

export function CourseDetailsCardComponent({
  course,
}: CourseDetailsCardComponentProps) {
  const handleRenderPriceContent = () => {
    if (course?.priceWithDiscount || course?.priceWithDiscount === 0) {
      if (course.price === 0 || course.price === 0.0) {
        return <BadgeSuccess>Curso Gratuito</BadgeSuccess>;
      }
      return (
        <PriceContainer>
          <Col className="my-auto">
            <WorkLoad className="originalPrice">
              <BsCashStack size={18} />
              R$ {course.price.toFixed(2)}
            </WorkLoad>
          </Col>

          <Col className="my-auto">
            <WorkLoad className="newPrice">
              <BsCashStack size={18} />
              R$ {course.priceWithDiscount.toFixed(2)}
            </WorkLoad>
          </Col>
        </PriceContainer>
      );
    } else {
      return (
        <PriceContainer>
          <Col className="my-auto">
            <WorkLoad className="newPrice">
              <BsCashStack size={18} />
              R$ {course.price.toFixed(2)}
            </WorkLoad>
          </Col>
        </PriceContainer>
      );
    }
  };

  return (
    <Link
      to={`/curso-detalhes?courseId=${course.id}`}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <ContentCard key={course.id} className="border-0">
        <Card.Img variant="top" src={course.coverImage} alt={course.title} />
        <Card.Body>
          <h5 className="card-title">{course.title}</h5>
          <p className="card-text">
            {course.description.length > 100
              ? course.description.substring(0, 100) + '...'
              : course.description}
          </p>
        </Card.Body>
        <Card.Footer>
          <CourseFooterContainer className="d-none d-md-grid">
            <Col className="my-auto col-md-auto clock">
              <BsAlarm size={18} />
              <WorkLoad>{FormatWorkloadHours(course.workLoad)}</WorkLoad>
            </Col>
            {handleRenderPriceContent()}
          </CourseFooterContainer>
        </Card.Footer>
      </ContentCard>
    </Link>
  );
}
