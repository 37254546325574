import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { isMobile } from 'react-device-detect';

import { TitleStyles } from '../../styles/GlobalStyles';
import { useInstitutionalContext } from '../../context/InstitutionalContext';
import { CoverTitle } from '../CoverTitle';

export function ProgramsComponent() {
  const { institutionalPageInfo } = useInstitutionalContext();

  const backImage = institutionalPageInfo?.teaching.image ?? '';

  if (institutionalPageInfo === undefined) {
    return <></>;
  }

  return (
    <Container fluid={true}>
      <CoverTitle
        backgroundImage={backImage}
        pageTitle="Programas"
        topEmptyRow={{
          height: '25vh',
          mobileHeight: '16rem',
        }}
        bottomEmptyRow={{
          height: '20vh',
          mobileHeight: '8rem',
        }}
        hasGoBack
        goBackOptions={{
          text: 'Voltar para Residência Multiprofissional',
          url: '/ensino',
        }}
      />

      <Row>
        <Col
          xl={6}
          className="mx-auto mt-5 insitutionalPortalMainCol"
          style={
            isMobile ? { paddingTop: '146px', paddingBottom: '146px' } : {
              paddingTop: '133px', paddingBottom: '215px'
            }
          }
        >
          <TitleStyles
            style={isMobile ? { fontSize: '2rem', lineHeight: '38px' } : {}}
          >
            Em breve, aguarde
            <br /> novidades!
          </TitleStyles>
        </Col>
      </Row>
    </Container >
  );
}
