import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { BaseModalStyles, CardStyles } from '../../styles/GlobalStyles';

export const PaymentConfirmationCardStyles = styled(CardStyles)`
  margin-top: -12vmin;
  padding: 2vmin;
  top: 12vmin;
  position: sticky;
  button {
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 1199px) {
    margin-top: 1rem;
    top: 0px;
  }
`;

export const PaymentInformationFormStyles = styled(Row)`
.text-observation{
  font-family: Roboto  !important;
  font-size: 14px  !important;
  font-weight: 400  !important;
  line-height: 16.41px  !important;
  text-align: left  !important;


}
  /* margin: 0 -12px 12rem -12px; */
  margin-bottom: 2rem;
  .react-datepicker__current-month {
    display: none;
  }
  form {
    .form-label,
    .form-check-label {
      color: #424242;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .form-check-input {
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.2);
      }
    }
    .form-control {
      color: #424242;
      font-size: 1.7vmin;
      font-weight: 400;
      padding: 1vmin 2vmin;
      border-radius: 6px;
      border: 1px solid #ccc;
      background: #fff;

      &:focus {
        border: 1px solid #ccc;
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.2);
      }
      &.is-invalid {
        border: 1px solid #dc3545;
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.2);
      }
    }
    .form-select {
      color: #424242;
      font-size: 1.7vmin;
      font-style: normal;
      font-weight: 400;
      padding: 1vmin 2vmin;
      border-radius: 6px;
      border: 1px solid #ccc;
      background: #fff;
      &:focus {
        border: 1px solid #ccc;
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.2);
      }
      &.is-invalid {
        border: 1px solid #dc3545;
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.2);
      }
    }
    .invalid-feedback {
      font-size: 1.5vmin;
    }
    .react-datepicker__input-container {
      input {
        line-height: inherit;
        color: rgb(66, 66, 66);
        font-family: Steradian;
        font-size: 1.7vmin;
        font-style: normal;
        font-weight: 400;
        padding: 1vmin 2vmin;
        border-radius: 6px;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        &:focus {
          border: 1px solid #ccc;
          box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.2);
        }
        &.is-invalid {
          border: 1px solid #dc3545;
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.2);
        }
      }
    }
    .is-invalid .invalid-feedback {
      display: block !important;
    }
  }
  @media screen and (max-width: 1199px) {
    form {
      .row {
        margin: 0px -12px;
      }
      .form-label {
        font-size: 0.8rem;
      }
      .form-check-label {
        padding: 0.3rem 0.4rem;
        font-size: 1.1rem;
      }
      .form-check-input {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;

        width: 1.5rem;
        height: 1.5rem;
      }
      .form-control {
        font-size: 1rem;
        padding: 1vmax 2vmax;
        border-radius: 0.4rem;
        border: 1px solid #ccc;
        background: #fff;
        &:focus {
          border: 1px solid #ccc;
          box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.2);
        }
        &.is-invalid {
          border: 1px solid #dc3545;
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.2);
        }
      }
      .invalid-feedback {
        font-size: 0.8rem;
      }
      .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container {
          width: 100%;

          input {
            width: 100%;
            padding: 1vmax 2vmax;
            font-size: 1rem;
          }
        }
      }
      .payment-confirmation {
        background: #e9e9e9;
      }
    }
  }
`;

export const CheckboxSection = styled.section`
  display: flex;
  gap: 3rem;

  .form-check {
    display: flex;
    align-items: center;
    gap: 8px;

    label {
      font-size: 1rem;
      margin-top: 3px;
    }
  }
  .form-check-input {
    width: 20px;
    height: 20px;

    &:focus {
      outline: unset;
      box-shadow: unset;
    }

    &:checked {
      background-color: #424242;
      outline: unset;
      box-shadow: unset;
    }
  }
`;

export const PaymentConfirmationModalStyles = styled(BaseModalStyles)`
  .img-row {
    max-height: 20vh;
  }
  i {
    font-size: 10vmin;
    color: #424242;
  }
  @media screen and (max-width: 1199px) {
    .img-row {
      max-height: 18vmax;
    }
    img {
      width: 8rem;
    }
    i {
      font-size: 4rem;
    }
  }
`;
