import { Col, Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

import BaseLayoutComponent from "../../components/layouts/BaseLayoutComponent"
import { PurcharseFinalizationJumbotronComponent } from "../../components/purcharseFinalization/PurcharseFinalizationJumbotronComponent"
import computerImage from "../../assets/image/computer-success.svg"
import { SecondaryButton } from "../../styles/GlobalStyles"
import { SuccessContainer } from "./styles"

export const PurchaseSuccess = () => {
  const navigate = useNavigate()
  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <Container fluid>
        <PurcharseFinalizationJumbotronComponent hasBackLink={false} pageTitle="Pagamento Aprovado" />

        <SuccessContainer>
          <div className="mx-auto">

            <img src={computerImage} alt="Sucesso" />
            <h2>Parabéns por sua escolha!</h2>
            <span>
              Sua compra está sendo processada, você receberá um e-mail com informações para acesso ao seu curso.
            </span>

            <SecondaryButton
              onClick={() => navigate('/cursos')}
            >Ver outros cursos
            </SecondaryButton>
          </div>
        </SuccessContainer>
      </Container>
    </BaseLayoutComponent>
  )
}