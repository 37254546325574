import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PageTitle } from '../../styles/GlobalStyles';
import { useCartContext } from '../../context/CartContext';

type Props = {
  backTitle?: string;
  backLink?: string;
  pageTitle?: string;
  hasBackLink?: boolean;
};

export function PurcharseFinalizationJumbotronComponent({
  backLink = '/carrinho',
  backTitle = 'VOLTAR PARA CARRINHO DE COMPRAS',
  pageTitle = 'Pagamento',
  hasBackLink = true,
}: Props) {

  const { TotalAmount } = useCartContext();
  return (
    <PurcharseFinalizationJumbotronComponentStyles>
      <Col>
        {hasBackLink && (
          <Row>
            <Col xs={11} xl={8} className="mx-auto mb-2">
              <Link to={backLink} className="d-flex">
                <i className="bi bi-chevron-left" />
                <span className="my-auto ms-1">
                  {backTitle}
                </span>
              </Link>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={11} xl={8} className="mx-auto">
            <PageTitle>{TotalAmount === 0 ? "Inscrição" : "Pagamento"}</PageTitle>
          </Col>
        </Row>
      </Col>
    </PurcharseFinalizationJumbotronComponentStyles>
  );
}

const PurcharseFinalizationJumbotronComponentStyles = styled(Row)`
  padding-top: 25vh;
  padding-bottom: 10vh;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #9e9e9e;
  a {
    text-decoration: none;
    color: #fff;
  }
  span {
    color: #fff;
    font-family: 'Steradian';
    font-size: 1.7vmin;
    letter-spacing: 2.17px;
    font-style: normal;
    font-weight: 400;
    &:hover {
      font-weight: 600;
    }
  }
  i {
    font-size: 2.5vmin;
  }
  @media screen and (max-width: 1199px) {
    padding-top: 40vmax;
    padding-bottom: 25vmax;
    i {
      font-size: 1.8rem;
    }
    span {
      font-size: 0.9rem;
    }
  }
`;
