import { Row, Col } from "react-bootstrap";
import {
  SeeMoreCoursesButton,
  FoundationRow
} from "../../styles/HomeStyles";
import {
  H1Styles,
  PBlackStyles
} from "../../styles/GlobalStyles";
import { useInstitutionalContext } from "../../context/InstitutionalContext";
import { useNavigate } from "react-router-dom";

export function WhoWeAreComponent() {
  const { institutionalPageInfo } = useInstitutionalContext();
  const navigate = useNavigate();

  function redirectTo(path: string) {
    navigate(path);
  }

  return (
    <FoundationRow>
      <Col xl={9} className="mx-auto mt-5 mt-8">
        <Row>
          <Col xs={7} xl={6} className="my-auto">
            <H1Styles>Fundação Miguel Couto</H1Styles>
            <p className="sub-title mt-4 mt-md-none">QUEM SOMOS</p>
            <PBlackStyles className="d-none d-xl-block who-we-are-p">
              {institutionalPageInfo?.home.whoWeAre}
            </PBlackStyles>
          </Col>
          <Col xl="auto" className="mx-auto my-auto d-flex justify-content-center">
            <img src={institutionalPageInfo?.home.imageWhoWeAre} alt="Fundalçao Miguel Couto" />
          </Col>
        </Row>
        <Row>
          <Col md={8} className="d-flex justify-content-center mx-auto">
            <PBlackStyles className="d-xl-none mt-2">
              {institutionalPageInfo?.home.whoWeAre}
            </PBlackStyles>
          </Col>
        </Row>
        <Row>
          <Col md={6} xl={4} className="mx-auto mb-4 mt-md-5 mb-md-5 d-flex justify-content-center">
            <SeeMoreCoursesButton onClick={() => redirectTo("/quem-somos")} className="btn btn-default">
              Ler mais sobre a fundação
            </SeeMoreCoursesButton>
          </Col>
        </Row>
      </Col>
    </FoundationRow>);
}
