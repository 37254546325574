import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { TransparencyPortalComponent } from '../components/transparencyPortal/TransparencyPortalComponent';

export default function TransparencyPortalScreen() {
  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <TransparencyPortalComponent />
    </BaseLayoutComponent>
  );
}
