export class CollapsableList<T>{

    list: CollapsableItem<T>[] = [];

    constructor(item?: T | T[]) {
        if (Array.isArray(item)) {
            item.forEach(element => {
                this.list = this.list.concat(new CollapsableItem<T>(element))
            });

        } else if (item !== undefined) {
            this.list = this.list.concat(new CollapsableItem<T>(item))
        }
    }

    handleCollapseMenuItemOnlyOneItemOpen(index: number) {

        this.list.forEach(item => {
            item.collapsed = false
        })

        this.list[index].collapsed = !(this.list[index].collapsed)

    }

    handleCollapseMenuItemAnyCanBeOpen(index: number) {
        this.list[index].collapsed = !(this.list[index].collapsed)
    }

    collapseAllItems() {

        this.list.forEach(module => {
            module.collapsed = false
        })
    }

}

export class CollapsableItem<T>{
    item: T
    collapsed: boolean = false;
    constructor(item: T, collapsed: boolean = false) {
        this.item = item;
        this.collapsed = collapsed;
    }
}
