import { Col, Row } from 'react-bootstrap';

import { H2Styles } from '../../styles/GlobalStyles';
import { InstitutionalOrganizationChartComponentStyles } from './styles';

interface InstitutionalOrganizationChartComponentProps {
  wpData: any;
}

export function InstitutionalOrganizationChartComponent({
  wpData
}: InstitutionalOrganizationChartComponentProps) {
  return (
    <InstitutionalOrganizationChartComponentStyles>
      <Col md="auto" className="mx-auto">
        <Row>
          <Col md="auto" className="mx-auto mb-3">
            <H2Styles>{wpData?.title_chart_institutional}</H2Styles>
          </Col>
        </Row>
        <Row>
          <Col md="auto" className="mx-auto">
            <img src={process.env.REACT_APP_WORDPRESS_API + wpData?.image_chart_institutional?.guid} alt="Organograma" />
          </Col>
        </Row>
      </Col>
    </InstitutionalOrganizationChartComponentStyles>
  );
}
