import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';

import { useInstitutionalContext } from '../../context/InstitutionalContext';
import { ResumeComponent } from './ResumeComponent';
import { MissonAndValuesComponent } from './MissonAndValuesComponent';
import { InstitutionalOrganizationChartComponent } from './InstitutionalOrganizationChartComponent';
import { ExecutiveBoardComponent } from './ExecutiveBoardComponent';
import { CoverTitle } from '../CoverTitle';
import InstitutionalService from '../../services/InstitutionalService';
import { PageLoader } from '../Loader';

export function WhoWeAreComponent() {
  const { institutionalPageInfo } = useInstitutionalContext();
  const institutionalService = new InstitutionalService();

  const [fmcData, setFmcData] = useState<any>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getFmcData = async () => {
      try {
        setLoading(true);
        let response: any = await institutionalService.getWordpressData('/fmc');
        if (response?.result) {
          setFmcData(JSON.parse(response.result)[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Erro ao tentar pegar COREMU da API Wordpress. Erro => ',
          error
        );
      }
    };
    getFmcData();
  }, []);

  if (!institutionalPageInfo) return <></>;

  const { whoAreWe } = institutionalPageInfo;

  return (
    <Container fluid={true}>
      <CoverTitle
        backgroundImage={whoAreWe.image}
        backgroundTablet="/mobile/fmc/who-are-tablet.jpg"
        backgroundMobile="/mobile/fmc/who-are-mobile.jpg"
        isSpecialMobile={true}
        pageTitle={whoAreWe.title}
        topEmptyRow={{
          height: '25vh',
          mobileHeight: '18rem',
        }}
        bottomEmptyRow={{
          height: '20vh',
          mobileHeight: '8rem',
        }}
      />

      {isLoading || !fmcData ? (
        <PageLoader />
      ) : (
        <>
          <ResumeComponent wpData={fmcData} />

          <MissonAndValuesComponent wpData={fmcData} />

          <InstitutionalOrganizationChartComponent wpData={fmcData} />

          <ExecutiveBoardComponent wpData={fmcData} />
        </>
      )}
    </Container>
  );
}
