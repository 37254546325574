import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const SuccessContainer = styled(Row)`
  margin: 3rem 0;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    h2 {
      color: #424242;
      font-size: 36px;
      text-align: center;
    }

    span {
      font-size: 20px;
      color: #424242;
    }

    button {
      max-width: 300px;
    }
  }
`;
