import { useState } from 'react';
import styled from 'styled-components';

import { PBlackStyles } from '../../styles/GlobalStyles';
import { SeeMoreCoursesButton } from '../../styles/HomeStyles';

interface ReadMoreProps {
  completeText: string;
  color?: string;
  numberChar?: number;
}

export const ReadMore = ({
  completeText,
  color = '#222',
  numberChar = 300,
}: ReadMoreProps) => {
  const [hideShortText, setHideShortText] = useState(false);
  const [hideLongText, setHideLongText] = useState(true);
  let cuttedText;

  const handleReadMore = () => {
    setHideShortText(!hideShortText);
    setHideLongText(!hideLongText);
    return;
  };

  if (completeText.length > numberChar) {
    cuttedText = completeText?.slice(0, numberChar)?.trim()?.concat('...');
  }

  if (!cuttedText) {
    return (
      <PBlackStyles
        dangerouslySetInnerHTML={{ __html: completeText }}
        style={{ color: color }}
      />
    );
  }

  return (
    <ReadMoreStyles className="readMoreContainer">
      <PBlackStyles
        dangerouslySetInnerHTML={{ __html: cuttedText }}
        className={hideShortText ? 'd-none' : ''}
        style={{ color: color }}
      />

      <PBlackStyles
        dangerouslySetInnerHTML={{ __html: completeText }}
        className={hideLongText ? 'd-none' : 'mb-0'}
        style={{ color: color }}
      />

      <SeeMoreCoursesButton onClick={() => handleReadMore()} className="my-4">
        {hideShortText ? 'Mostrar menos' : 'Mostrar mais'}
      </SeeMoreCoursesButton>
    </ReadMoreStyles>
  );
};

const ReadMoreStyles = styled.section``;
