/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';

import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { EventDevelopmentComponent } from '../components/events/EventDevelopmentComponent';
import { CoverTitle } from '../components/CoverTitle';
import { NewEventSection } from '../components/events/NewEventSection';
import { PageLoader } from '../components/Loader';
import InstitutionalService from '../services/InstitutionalService';

export default function EventScreen() {
  const institutionalService = new InstitutionalService();
  const [eventsData, setEventsData] = useState<any>();
  const [eventsDocsData, setEventsDocsData] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const backImage = `/images/events_cover.png`;

  const cardsDocuments: { title: string; link: string }[] = [];

  if (eventsDocsData) {
    eventsDocsData?.documents_title?.map((item: any, index: number) => {
      return cardsDocuments.push({
        title: item,
        link: process.env.REACT_APP_WORDPRESS_API +
          eventsDocsData?.documents[index]?.guid,
      });
    });
  }

  useEffect(() => {
    const getEvents = async () => {
      try {
        setLoading(true);
        let response: any = await institutionalService.getWordpressData('/events');
        if (response?.result) {
          setEventsData(JSON.parse(response.result));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Erro ao tentar pegar eventos da API Wordpress. Erro => ',
          error
        );
      }
    };

    const getEventsDocuments = async () => {
      try {
        setLoading(true);
        let response: any = await institutionalService.getWordpressData('/events_documents');
        if (response?.result) {
          setEventsDocsData(JSON.parse(response.result)[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Erro ao tentar pegar eventos da API Wordpress. Erro => ',
          error
        );
      }
    };
    getEvents();
    getEventsDocuments()
  }, []);

  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <Container fluid={true}>
        <CoverTitle
          backgroundImage={backImage}
          pageTitle="Eventos"
          topEmptyRow={{
            height: '25vh',
            mobileHeight: '16rem',
          }}
          bottomEmptyRow={{
            height: '20vh',
            mobileHeight: '8rem',
          }}
        />

        <EventDevelopmentComponent documentsData={cardsDocuments} />

        {isLoading && <PageLoader />}

        <NewEventSection eventsData={eventsData} />
      </Container>
    </BaseLayoutComponent>
  );
}
