import React, { useContext, useEffect } from 'react';
import { CourseDetailsViewModel } from '../domain/models/Course';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';

const CartItemsName = 'CartItemsObject';

interface CartContextData {
  cartItems: CourseDetailsViewModel[];
  isLoadingCart: boolean;
  addToCart: (course: CourseDetailsViewModel) => boolean;
  removeFromCart: (course: CourseDetailsViewModel) => void;
  clearCart: () => void;
  TotalAmount: number;
  TotalAmountOriginal: number;
  getCardItems: () => CourseDetailsViewModel[];
  checkIsFreeCart: () => boolean;
}

export const CartContext = React.createContext<CartContextData>(null as any);

interface CartProviderProps {
  children: React.ReactNode;
}

export function useCartContext() {
  return useContext(CartContext);
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  useEffect(() => {
    loadCardItems();
  }, []);
  const [cartItems, setCartItems] = React.useState<CourseDetailsViewModel[]>(
    []
  );
  const [isLoadingCart, setIsLoadingCart] = React.useState(true);

  const TotalAmount = cartItems.reduce(
    (acc, course) =>
      acc +
      (course.priceWithDiscount || course.priceWithDiscount === 0
        ? course.priceWithDiscount
        : course.price),
    0
  );

  const TotalAmountOriginal = cartItems.reduce(
    (acc, course) => acc + course.price,
    0
  );

  const checkIsFreeCart = () => {
    return cartItems.every(
      (item: any) => item.price === item.priceWithDiscount
    );
  };

  const addToCart = (course: CourseDetailsViewModel): boolean => {
    let item = cartItems.find((item) => item.id === course.id);

    if (item === undefined) {
      let newItems = [...cartItems, course];

      let cartString = JSON.stringify(newItems);

      var ciphertext = aes
        .encrypt(
          cartString,
          process.env.REACT_APP_BRAINZ_CRYPTO_SECRET_KEY as string
        )
        .toString();
      sessionStorage.setItem(CartItemsName, ciphertext);
      setCartItems(newItems);

      return true;
    }

    return false;
  };

  const removeFromCart = (course: CourseDetailsViewModel) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== course.id);

    let cartString = JSON.stringify(updatedCartItems);
    var ciphertext = aes
      .encrypt(
        cartString,
        process.env.REACT_APP_BRAINZ_CRYPTO_SECRET_KEY as string
      )
      .toString();

    sessionStorage.setItem(CartItemsName, ciphertext);

    setCartItems(updatedCartItems);
  };

  const clearCart = () => {
    sessionStorage.removeItem(CartItemsName);

    setCartItems([]);
  };

  function loadCardItems() {
    let cartItems = getCardItems();
    setCartItems(cartItems);
    setIsLoadingCart(false);
  }

  function getCardItems(): CourseDetailsViewModel[] {
    let cartItems: CourseDetailsViewModel[] = [];
    const cartItemsString = sessionStorage.getItem(CartItemsName);
    if (cartItemsString != null) {
      var bytes = aes.decrypt(
        cartItemsString,
        process.env.REACT_APP_BRAINZ_CRYPTO_SECRET_KEY as string
      );
      var originalText = bytes.toString(enc);

      cartItems = JSON.parse(originalText);
    }
    return cartItems;
  }

  const cartContextValue = {
    cartItems,
    addToCart,
    removeFromCart,
    TotalAmount,
    isLoadingCart,
    getCardItems,
    clearCart,
    TotalAmountOriginal,
    checkIsFreeCart,
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );
};
