import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CourseDetailsViewModel } from '../domain/models/Course';
import TrilhasIntegrationService from '../services/TrilhasIntegrationService';
import { Col, Container, Row } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';
import { useCartContext } from './CartContext';
import { useInstitutionalAuthContext } from './InstitutionalAuthContext';

const CourseDetailContext = createContext({} as CourseDetailContextData);

const trilhasIntegrationService = new TrilhasIntegrationService();

interface CourseDetailContextProviderProps {
  courseId: string;
  children: ReactNode;
}

export function CourseDetailContextProvider(
  props: CourseDetailContextProviderProps
) {
  const { addToCart, cartItems, clearCart } = useCartContext();

  const { showModal } = useInstitutionalAuthContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [course, setCourse] = useState<CourseDetailsViewModel>(null as any);

  useEffect(() => {
    LoadCourse();
  }, []);

  async function LoadCourse() {
    let response = await trilhasIntegrationService.getCourseDetails(
      props.courseId
    );
    if (response?.success) {
      setCourse(response.result);
    }
  }

  function subscribeToCourse(isFreeCourse = false) {
    // Curso gratuito
    if (isFreeCourse) {
    clearCart();
    showModal();
    } else {
    if (addToCart(course)) {
        setShowConfirmationModal(true);
    }
    }
  }

  function handleCloseConfirmationModal() {
    setShowConfirmationModal(false);
  }

  function isInCart(): boolean {
    let index = cartItems.findIndex((item) => item.id === course.id);
    if (index >= 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      <CourseDetailContext.Provider
        value={{
          course,
          showConfirmationModal,
          subscribeToCourse,
          handleCloseConfirmationModal,
          isInCart,
        }}
      >
        {course === null ? (
          <Container fluid>
            <Row style={{ minHeight: '100vh', background: '#00000030' }}>
              <Col xs="auto" className="mx-auto my-auto">
                <TailSpin color="#72b81f" height={150} width={150} />
              </Col>
            </Row>
          </Container>
        ) : (
          <>{props.children}</>
        )}
      </CourseDetailContext.Provider>
    </>
  );
}

export function useCourseDetailContext() {
  return useContext(CourseDetailContext);
}

interface CourseDetailContextData {
  course: CourseDetailsViewModel;
  showConfirmationModal: boolean;
  subscribeToCourse: (isFreeCourse?: boolean) => void;
  handleCloseConfirmationModal: () => void;
  isInCart: () => boolean;
}
