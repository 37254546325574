import { Col, Row } from 'react-bootstrap';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import { MainJumbotron } from '../../styles/CoursesStyles';
import { EmptyRow, PageTitle } from '../../styles/GlobalStyles';
import { CourseSearchBarComponent } from './CourseSearchBarComponent';

interface CourseMainJumbotronComponentProps {
  backImage: string;
}

export function CourseMainJumbotronComponent({
  backImage,
}: CourseMainJumbotronComponentProps) {
  return (
    <Row>
      <MainJumbotron backgroundImage={backImage}>
        <EmptyRow height="35vh" mobileHeight="17rem" />

        <Row className={isMobile ? 'mx-0 px-0' : 'mx-auto'}>
          <Col xl={8} className={isMobile ? 'mx-0 px-4' : 'mx-auto'}>
            <PageTitle>Cursos</PageTitle>
          </Col>
        </Row>
        <EmptyRow height="10vh" mobileHeight="5rem" />

        <CourseSearchBarComponent />
      </MainJumbotron>
    </Row>
  );
}
