import BaseLayoutComponent from "../components/layouts/BaseLayoutComponent";
import { ProgramsComponent } from "../components/teaching/ProgramsComponent";

export default function ProgramsScreen() {
  return (
    <BaseLayoutComponent showInstitutionalInfo>
      <ProgramsComponent />
    </BaseLayoutComponent>
  );
}
