import { Col, Container, Row } from 'react-bootstrap';
import { DevelopedBy, FooterContainer } from '../../styles/FooterStyles';
import {
  H3Styles,
  InstitutionalInformationIconDiv,
  PBlackStyles,
} from '../../styles/GlobalStyles';
import { FooterPageInfoViewModel } from '../../domain/models/FooterPageInfoViewModel';

interface FooterProps {
  footerPageInfo: FooterPageInfoViewModel;
  showInstitutionalInfo: boolean;
}
export default function FooterComponent({
  showInstitutionalInfo,
  footerPageInfo,
}: FooterProps) {
  const currentYear = new Date().getUTCFullYear();


  const email = footerPageInfo?.email;
  const emailTablet = email.replace("@", "@<br/>");

  return (
    <FooterContainer as={Container} fluid={true} className="footerContainer">
      {/* Desktop and Tablet */}
      <Row className="py-5 d-none d-md-block desktopContainer">
        <Col md={11} xl={10} className="mx-auto">
          {showInstitutionalInfo && (
            <>
              <Row className="mb-3">
                <Col>
                  <Row>
                    <Col md="auto">
                      <InstitutionalInformationIconDiv color="#72B81F">
                        <Row className="h-100">
                          <Col md="auto" className="mx-auto my-auto">
                            <i className="bi bi-signpost-split" />
                          </Col>
                        </Row>
                      </InstitutionalInformationIconDiv>
                    </Col>
                    <Col>
                      <Row>
                        <H3Styles>ENDEREÇO</H3Styles>
                      </Row>
                      <Row>
                        <Col md={10}>
                          <PBlackStyles
                            dangerouslySetInnerHTML={{
                              __html: footerPageInfo.address,
                            }}
                          />
                        </Col>


                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col md="auto">
                      <InstitutionalInformationIconDiv color="#72B81F">
                        <Row className="h-100">
                          <Col md="auto" className="mx-auto my-auto">
                            <i className="bi bi-at" />
                          </Col>
                        </Row>
                      </InstitutionalInformationIconDiv>
                    </Col>
                    <Col>
                      <Row>
                        <H3Styles>E-MAIL</H3Styles>
                      </Row>
                      <Row>
                        <Col md={10} className="d-md-none d-xl-block">
                          <PBlackStyles>{email}</PBlackStyles>
                        </Col>
                        <Col md={10} className="d-md-block d-xl-none">
                          <PBlackStyles dangerouslySetInnerHTML={{
                            __html: emailTablet,
                          }} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6} xl={6}>
                  <Row>
                    <Col md="auto">
                      <InstitutionalInformationIconDiv color="#72B81F">
                        <Row className="h-100">
                          <Col md="auto" className="mx-auto my-auto">
                            <i className="bi bi-telephone" />
                          </Col>
                        </Row>
                      </InstitutionalInformationIconDiv>
                    </Col>
                    <Col>
                      <Row>
                        <H3Styles>TELEFONE</H3Styles>
                      </Row>
                      <Row>
                        <Col md={10}>
                          <PBlackStyles>{footerPageInfo.phone}</PBlackStyles>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={6} xl={6}>
                  <a href="https://wa.me/5567999965117">
                    <Row>
                      <Col md="auto">
                        <InstitutionalInformationIconDiv color="#72B81F">
                          <Row className="h-100">
                            <Col md="auto" className="mx-auto my-auto">
                              <i className="bi bi-whatsapp" />
                            </Col>
                          </Row>
                        </InstitutionalInformationIconDiv>
                      </Col>
                      <Col>
                        <Row>
                          <H3Styles>WHATSAPP</H3Styles>
                        </Row>
                        <Row>
                          <Col md={10}>
                            <PBlackStyles>
                              {footerPageInfo.corporateWhatsapp}
                            </PBlackStyles>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </a>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>

      {/* Smartphone only */}
      {showInstitutionalInfo && (
        <Row className="py-3 d-md-none mb-2">
          <Col xs={11} md="auto" className="mx-auto footerMainCol">
            <Row>
              <Col md="auto">
                <Row>
                  <Col xs={'auto'} className="pe-1">
                    <InstitutionalInformationIconDiv color="#72B81F">
                      <Row className="h-100">
                        <Col xs="auto" className="mx-auto my-auto pt-1">
                          <i className="bi bi-signpost-split" />
                        </Col>
                      </Row>
                    </InstitutionalInformationIconDiv>
                  </Col>
                  <Col className="my-auto">
                    <h3>ENDEREÇO</h3>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: footerPageInfo.address,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="auto" className="mt-4">
                <Row>
                  <Col xs={'auto'} className="pe-1">
                    <InstitutionalInformationIconDiv color="#72B81F">
                      <Row className="h-100">
                        <Col xs="auto" className="mx-auto my-auto pt-1">
                          <i className="bi bi-at" />
                        </Col>
                      </Row>
                    </InstitutionalInformationIconDiv>
                  </Col>
                  <Col className="my-auto">
                    <h3>E-MAIL</h3>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p
                      dangerouslySetInnerHTML={{ __html: email }}
                    />

                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="auto" className="mt-4">
                <Row>
                  <Col xs={'auto'} className="pe-1">
                    <InstitutionalInformationIconDiv color="#72B81F">
                      <Row className="h-100">
                        <Col xs="auto" className="mx-auto my-auto pt-1">
                          <i className="bi bi-telephone" />
                        </Col>
                      </Row>
                    </InstitutionalInformationIconDiv>
                  </Col>
                  <Col className="my-auto">
                    <h3>TELEFONE</h3>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p
                      dangerouslySetInnerHTML={{ __html: footerPageInfo.phone }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md="auto" className="mt-4">
                <a href="https://wa.me/5567999965117">
                  <Row>
                    <Col xs={'auto'} className="pe-1">
                      <InstitutionalInformationIconDiv color="#72B81F">
                        <Row className="h-100">
                          <Col xs="auto" className="mx-auto my-auto">
                            <i className="bi bi-whatsapp" />
                          </Col>
                        </Row>
                      </InstitutionalInformationIconDiv>
                    </Col>
                    <Col className="my-auto">
                      <h3>WHATSAPP</h3>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: footerPageInfo.corporateWhatsapp,
                        }}
                      />
                    </Col>
                  </Row>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <DevelopedBy className="copyrightSection">
        <span>
          © {currentYear} – Todos os direitos reservados Brainz Group.
        </span>
      </DevelopedBy>
    </FooterContainer>
  );
}
