import { Row } from 'react-bootstrap';
import { CardStyles } from '../../styles/GlobalStyles';
import styled from 'styled-components';

export const ExecutiveBoardComponentStyles = styled(Row)`
  padding: 5vh 0px;
`;

export const ExecutiveBoardCardStyles = styled(CardStyles)`
  margin-bottom: 24px;

  img {
    max-height: 273px;
    object-fit: cover;
    object-position: 100% 5%;
  }
  .card-title,
  .card-subtitle {
    color: #000;
    text-align: center;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
  }
  .card-subtitle {
    font-weight: 400;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
`;

export const InstitutionalOrganizationChartComponentStyles = styled(Row)`
  background: #e3f0d4;
  padding: 5vmin 0px;
  img {
    width: 90vmin;
  }

  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 28px;
      text-align: center;
    }
  }
`;

export const MissonAndValuesComponentStyles = styled(Row)`
  background: #e9e9e9;
  padding: 30px 0 47px;

  .our-values {
    margin-top: 28px;

    .row.our-values {
      padding: 2vmin 4vmin;
    }
    .main-card-body {
      padding: 0 !important;
    }
    .card-body {
      min-height: 650px;
    }
    i {
      font-size: 26px;
    }
    span {
      text-align: justify;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
    }

    @media screen and (max-width: 1210px) {
      margin-top: 20px;
    }
  }
`;
