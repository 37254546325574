import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components"
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import { useInstitutionalContext } from "../../context/InstitutionalContext";
import { InstitutionalUserAuthModalComponent } from "./InstitutionalUserAuthModalComponent";
import { PageLoader } from "@brainz_group/webappframework";

interface LayoutProps {
  children: ReactNode;
  showInstitutionalInfo: boolean

}
export default function BaseLayoutComponent({showInstitutionalInfo,children}:LayoutProps){
    const {
        verifyPageScroll,
        isTopScroll,
        institutionalPageInfo,
        isLoading,
        isWaiting
    } = useInstitutionalContext()

    if(institutionalPageInfo == undefined){
        return <></>
    }
    if(isLoading){
        return(
            <PageLoader
                spinnerColor="#72b81f"
            />
        )
    }

    return(
        <Wrapper id="PageWrapper" onScroll={(e)=>verifyPageScroll(e)}>
            {
                isWaiting&&
                <PageLoader
                    spinnerColor="#72b81f"
                />
            }
            <HeaderComponent isTopScroll={isTopScroll}/>
            <Body >
                {children}
                <FooterComponent 
                    footerPageInfo={institutionalPageInfo.footer} 
                    showInstitutionalInfo={showInstitutionalInfo}    
                />
            </Body>
            <InstitutionalUserAuthModalComponent/>
        </Wrapper>)
}

export const Wrapper = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y:scroll;
    overflow-x:hidden;

`

export const Body = styled.div`
    font-family: 'Steradian', sans-serif;
    max-width: 100vw;
    margin:0px;

    position: relative;
    top:-125px;
    height: calc(100% - 125px);

`