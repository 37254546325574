import { ReactNode, createContext, useContext, useState } from "react";

const ClinicalResearchContext = createContext({} as ClinicalResearchContextData)


interface ClinicalResearchContextProviderProps {
    children: ReactNode;
}

export function ClinicalResearchContextProvider(props: ClinicalResearchContextProviderProps) {
    const [showClinicalResearchModal, setShowClinicalResearchModal] = useState(false)
    const [showWhoConductsResearchModal, setShowWhoConductsResearchModal] = useState(false)
    const [showWhoCanParticipateModal, setShowWhoCanParticipateModal] = useState(false)
    const [showHowToParticipateModal, setShowHowToParticipateModal] = useState(false)
    const [showAdvantagesModal, setShowAdvantagesModal] = useState(false)
    const [showNurembergModal, setShowNurembergModal] = useState(false)
    const [showHelsinqueModal, setShowHelsinqueModal] = useState(false)
    const [showCIOMSModal, setShowCIOMSModal] = useState(false)

    return (
        <>
            <ClinicalResearchContext.Provider value={{
                showClinicalResearchModal, setShowClinicalResearchModal,
                showWhoConductsResearchModal, setShowWhoConductsResearchModal,
                showWhoCanParticipateModal, setShowWhoCanParticipateModal,
                showHowToParticipateModal, setShowHowToParticipateModal,
                showAdvantagesModal, setShowAdvantagesModal,
                showNurembergModal, setShowNurembergModal,
                showHelsinqueModal, setShowHelsinqueModal,
                showCIOMSModal, setShowCIOMSModal
            }}>
                {
                    props.children
                }
            </ClinicalResearchContext.Provider>
        </>
    )
}

export function useClinicalResearchContext() {
    return useContext(ClinicalResearchContext);
}

interface ClinicalResearchContextData {
    showClinicalResearchModal:boolean,
    setShowClinicalResearchModal:React.Dispatch<React.SetStateAction<boolean>>,

    showWhoConductsResearchModal: boolean, 
    setShowWhoConductsResearchModal: React.Dispatch<React.SetStateAction<boolean>>,

    showWhoCanParticipateModal: boolean, 
    setShowWhoCanParticipateModal: React.Dispatch<React.SetStateAction<boolean>>,

    showHowToParticipateModal:boolean, 
    setShowHowToParticipateModal: React.Dispatch<React.SetStateAction<boolean>>,

    showAdvantagesModal:boolean, 
    setShowAdvantagesModal: React.Dispatch<React.SetStateAction<boolean>>,

    showNurembergModal:boolean, 
    setShowNurembergModal: React.Dispatch<React.SetStateAction<boolean>>,

    showHelsinqueModal:boolean, 
    setShowHelsinqueModal: React.Dispatch<React.SetStateAction<boolean>>,
    
    showCIOMSModal:boolean, 
    setShowCIOMSModal: React.Dispatch<React.SetStateAction<boolean>>
}