import { PaginationControl } from 'react-bootstrap-pagination-control';
import './styles.css';

export interface PaginationProps {
  currentRecord: number;
  currentPage: number;
  pageSize: number;
  recordsOnPage: number;
  totalRecords: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

export interface BrainzPaginationProps {
  setPaginationParams: any;
  paging: PaginationProps;
  hidePageSize?: boolean;
}

const BrainzPagination = ({
  setPaginationParams,
  paging,
}: BrainzPaginationProps) => {
  const {
    currentPage,
    pageSize,
    totalRecords,
    totalPages,
    hasNext,
    hasPrevious,
  } = paging;

  const handleOnChange = (page: number, size: number) => {
    setPaginationParams({
      page,
      pageSize: size,
    });
  };

  // Caso tenha apenas uma página
  if (currentPage === totalPages && !hasNext && !hasPrevious) return <></>;

  return (
    <section className="customPagination">
      <PaginationControl
        page={currentPage}
        total={totalRecords}
        limit={pageSize}
        changePage={(page: any) => {
          handleOnChange(page, pageSize);
        }}
        ellipsis={1}
        next
        last
      />
    </section>
  );
};

export default BrainzPagination;
