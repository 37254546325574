import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { SecondaryButton } from './GlobalStyles';

export const HomeMainJumbotron = styled(Col)<{
  backgroundImage: string;
  backgroundMobile: string;
  backgroundTablet: string;
}>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 600px) {
    background-image: url(${(props) => props.backgroundMobile});
    background-size: contain !important;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  @media screen and (min-width: 601px) and (max-width: 1210px) {
    background-image: url(${(props) => props.backgroundTablet});
    background-size: contain !important;
    background-position: bottom;
  }
`;

export const CourseTitle = styled.h1`
  color: #ffffff;
  font-family: 'Steradian';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 2px;

  @media screen and (max-width: 600px) {
    color: #000 !important;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1.68px;
  }

  @media screen and (min-width: 601px) and (max-width: 1210px) {
    color: #000 !important;
    font-size: 20;
    line-height: 28px;
  }
`;

export const SeeMoreCoursesButton = styled(SecondaryButton)`
  padding: 12px 28px;

  background: #72b81f;
  border: 1px solid #72b81f;
  border-radius: 100px;

  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  width: auto;

  display: flex;
  justify-content: center;

  text-align: center;

  &:hover {
    background-color: transparent;
    color: #72b81f;
    border-color: #72b81f;
  }
  @media screen and (max-width: 600px) {
    font-weight: 500;
    padding: 14px 24px;
    /* width: 100%; */
  }

  /* @media screen and (min-width: 601px) and (max-width: 1210px) {
    width: 100%;
  } */
`;

export const FoundationRow = styled(Row)`
  background: #e9e9e9;
  .sub-title {
    font-family: 'Steradian';
    font-style: normal;
    font-weight: 400;
    font-size: 2.2vmin;

    letter-spacing: 0.155em;

    color: #424242;
    @media screen and (max-width: 900px) {
      font-size: 4vmin;
    }
  }
  img {
    max-width: 60vmin;
    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
`;
