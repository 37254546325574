import {
  ReactNode,
  createContext,
  useContext,
  useState,
  FocusEvent,
  useEffect,
} from 'react';
import axios, { AxiosResponse } from 'axios';
import { PurcharseFormModel } from '../domain/models/PurcharseFormModel';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import * as formik from 'formik';
import { AddressInfoModel } from '../domain/models/AddressInfoModel';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { useInstitutionalAuthContext } from './InstitutionalAuthContext';
import { useCartContext } from './CartContext';
import PaymentService from '../services/PaymentService';

const paymentService = new PaymentService();

const PurcharseFinalizationContext = createContext(
  {} as PurcharseFinalizationContextData
);

registerLocale('ptBR', ptBR);

interface PurcharseFinalizationContextProviderProps {
  children: ReactNode;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setErrors: (errors: formik.FormikErrors<any>) => void;
  values: PurcharseFormModel;
  touched: FormikTouched<PurcharseFormModel>;
  errors: FormikErrors<PurcharseFormModel>;
  isValid: boolean;
}

export function PurcharseFinalizationContextProvider({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  isValid,
  touched,
  errors,
  children,
}: PurcharseFinalizationContextProviderProps) {
  const { getAuthEmail } = useInstitutionalAuthContext();
  const { getCardItems, clearCart, TotalAmount } = useCartContext();

  const [birthDate, setBirthDate] = useState<Date>(null as any);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    let email = getAuthEmail();

    if (email === null) {
      window.location.href = window.location.origin;
    }
  }, []);

  const onCepBlur = async (e: FocusEvent<HTMLInputElement>) => {
    let cep = e.target.value.replaceAll('-', '').replaceAll('_', '');
    handleBlur(e);

    if (cep.length === 8) {
      let info: AxiosResponse<AddressInfoModel> = await axios.get(
        'https://viacep.com.br/ws/' + cep + '/json/'
      );
      if (info.data.erro) {
        console.error('Erro ao buscar o CEP. Erro => ', info.data.erro);
      } else {
        setFieldValue('neighborhood', info.data.bairro);
        setFieldValue('city', info.data.localidade);
        setFieldValue('address', info.data.logradouro);
        setFieldValue('state', info.data.uf);
      }
    }
  };

  const onChangeDatePickerChange = (date: Date) => {
    setFieldValue('birthDate', date);
  };

  const onSubmit = () => {
    let cartItems = getCardItems();

    const itemsData = cartItems?.map((item) => {
      return {
        amount: item.priceWithDiscount
          ? Math.trunc(item.priceWithDiscount * 100)
          : Math.trunc(item.price * 100),
        description: item.title,
        quantity: 1,
        code: item.id,
      };
    });

    const dddPhoneMatch = values.phone.match(/\((\d+)\)/);
    const dddPhone = dddPhoneMatch![1];

    const phoneMatch = values.phone.match(/\)\s*(\d+)-(\d+)/);
    const phoneData = `${phoneMatch![1]}${phoneMatch![2]}`;

    const payload: any = {
      customer: {
        name: values.name,
        email: values.email,
        type: 'individual',
        document: values.cpf?.replace(/[.-]/g, ''),
        birthdate: values.birthDate,
        address: {
          country: 'BR',
          state: values.state,
          city: values.city,
          zipCode: values.cep,
          line1: values.address,
          line2: values.complement,
        },
        phones: {
          homePhone: {
            countryCode: '55',
            areaCode: dddPhone ?? '11',
            number: phoneData,
          },
          mobilePhone: {
            countryCode: '55',
            areaCode: dddPhone ?? '11',
            number: phoneData,
          },
        },
      },
      items: itemsData,
      payments: [
        {
          paymentMethod: 'checkout',
          checkout: {
            expiresIn: 1200,
            defaultPaymentMethod: 'credit_card',
            customerEditable: true,
            billingAddress: {
              line1: values.address,
              zipCode: values.cep,
              city: values.city,
              state: values.state,
              country: 'BR',
            },
            billingAddressEditable: false,
            skipCheckoutSuccessPage: true,
            acceptedPaymentMethods: ['credit_card', 'boleto', 'pix'],
            acceptedBrands: [
              'Visa',
              'Mastercard',
              'American Express',
              'Elo',
              'Hipercard',
            ],
            successUrl: '',
            creditCard: {
              capture: true,
              statementDescriptor: 'Curso',
              installments: [
                {
                  number: 1,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 2,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 3,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 4,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 5,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 6,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 7,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 8,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 9,
                  total: Math.round(TotalAmount * 100),
                },
                {
                  number: 10,
                  total: Math.round(TotalAmount * 100),
                },
              ],
            },
            boleto: {
              instructions:
                'Sr. Caixa, favor não aceitar pagamento após o vencimento',
              dueAt: currentDate,
            },
            pix: {
              expiresIn: '3600',
            },
          },
        },
      ],
    };

    setIsLoading(true);
    paymentService
      .paymentFinalization(payload)
      .then((result: any) => {
        if (result.success) {
          const paymentURL =
            result?.result?.result?.checkouts[0]?.paymentUrl ?? '';

          if (paymentURL && paymentURL !== '') {
            window.location.href = paymentURL;
            return;
          }
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error('Erro ao finalizar pagamento. Erro => ', error);
        setIsLoading(false);
      });
  };
  const onSubmitFree = () => {
    const payload: any = {
      document: values.cpf?.replace(/[.-]/g, ''),
      name: values.name,
      email: values.email,
      phone: values.phone,
      birthdate: values.birthDate,
      courseId: [new URL(window.location.href).searchParams.get('courseId')],
      zipCode: values.cep,
      street: values.address,
      number: values.complement,
      state: values.state,
      complement: values.complement,
    };

    setIsLoading(true);
    paymentService
      .paymentFinalizationFree(payload)
      .then((result: any) => {
        if (result.success) {
          clearCart();
          const paymentURL = '/purchase-success';
          window.location.href = paymentURL;
        }
        setIsLoading(false);
        return;
      })
      .catch((error: any) => {
        console.error('Erro ao finalizar pagamento. Erro => ', error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <PurcharseFinalizationContext.Provider
        value={{
          birthDate,
          setBirthDate,
          showFailedModal,
          setShowFailedModal,
          isLoading,
          onChangeDatePickerChange,
          onCepBlur,
          onSubmit,
          onSubmitFree,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isValid,
        }}
      >
        {children}
      </PurcharseFinalizationContext.Provider>
    </>
  );
}

export function usePurcharseFinalizationContext() {
  return useContext(PurcharseFinalizationContext);
}

interface PurcharseFinalizationContextData {
  showFailedModal: boolean;
  setShowFailedModal: React.Dispatch<React.SetStateAction<boolean>>;

  isLoading: boolean;

  birthDate: Date;
  setBirthDate: React.Dispatch<React.SetStateAction<Date>>;

  onCepBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onChangeDatePickerChange: (date: Date) => void;
  onSubmit: () => void;
  onSubmitFree: () => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: PurcharseFormModel;
  touched: FormikTouched<PurcharseFormModel>;
  errors: FormikErrors<PurcharseFormModel>;
  isValid: boolean;
}

export interface PaymentPayload {
  customer: Customer;
  shipping?: Shipping;
  items?: Item[];
  antifraud?: AntiFraud;
  location?: Location;
  device?: Device;
  payments?: Payment[];
  ip?: string;
  session_id?: string;
}

export interface AntiFraud {
  type: string;
  clearsale: ClearSale;
}

export interface ClearSale {
  customsla: string;
}

export interface Customer {
  address: Address;
  phones: Phones;
  name: string;
  email: string;
  type: string;
  code: string;
  document: string;
  documentType: string;
  gender: string;
  birthdate: string;
}

export interface Address {
  country: string;
  state: string;
  city: string;
  zipCode: string;
  line1: string;
  line2?: string;
}

export interface Phones {
  mobilePhone: MobilePhone;
}

export interface MobilePhone {
  countryCode: string;
  areaCode: string;
  number: string;
}

export interface Device {
  platform: string;
}

export interface Item {
  amount: number;
  description: string;
  quantity: number;
  code: string;
  courseId: string;
  totalValue: number;
}

export interface Location {
  latitude: string;
  longitude: string;
}

export interface Payment {
  paymentMethod: string;
  creditCard: CreditCard;
}

export interface CreditCard {
  holderDocument: string;
  recurrence: boolean;
  installments: number;
  statementDescriptor: string;
  card: Card;
  operation_type: string;
}

export interface Card {
  number: string;
  holderName: string;
  expMonth: number;
  expYear: number;
  cvv: string;
  brand?: string;
  billingAddress: Address;
}

export interface Shipping {
  address: Address;
  amount: number;
  description: string;
  recipientName: string;
  recipientPhone: string;
}
