import { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import ptBR from 'date-fns/locale/pt-BR';

import {
  H1Styles,
  H2Styles,
  H3Styles,
  SecondaryButton,
} from '../../styles/GlobalStyles';
import { usePurcharseFinalizationContext } from '../../context/PurcharseFinalizationContext';
import { useCartContext } from '../../context/CartContext';
import {
  PaymentInformationFormStyles,
  PaymentConfirmationCardStyles,
} from './styles';
import { PageLoader } from '../Loader';
import { CoursePriceContainer } from '../courseDetail/CourseDetailComponent';

registerLocale('ptBR', ptBR);

export function PaymentInformationForm() {
  const {
    onChangeDatePickerChange,
    onCepBlur,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isValid,
    onSubmit,
    onSubmitFree,
    isLoading,
  } = usePurcharseFinalizationContext();

  const { TotalAmount, TotalAmountOriginal, checkIsFreeCart, cartItems } =
    useCartContext();
  const valuesRendered = { ...values };
  const [accepted, setAccepted] = useState(false);
  const [isFreeCourse, setIsFreeCourse] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };
  const isInvalid = !isValid || valuesRendered.cpf === '';

  useEffect(() => {
    setIsFreeCourse(checkIsFreeCart());
  }, [cartItems]);

  const handleRenderTotalAmount = () => {
    if (TotalAmount !== TotalAmountOriginal) {
      return (
        <CoursePriceContainer>
          <Row>
            <div className="originalPrice">
              R$ {TotalAmountOriginal.toFixed(2)}
            </div>
          </Row>
          <Row>
            <H1Styles className="newPrice">
              R$ {TotalAmount.toFixed(2)}
            </H1Styles>
          </Row>
        </CoursePriceContainer>
      );
    } else {
      return (
        <CoursePriceContainer>
          <Row>
            <H1Styles className="">R$ {TotalAmount.toFixed(2)}</H1Styles>
          </Row>
        </CoursePriceContainer>
      );
    }
  };

  return (
    <PaymentInformationFormStyles>
      <Col xl={8} className="mx-auto">
        <Form onSubmit={handleSubmit}>
          {isLoading && <PageLoader />}
          <Row>
            <Col
              xs={{ order: 2, span: 11 }}
              xl={{ order: 2, span: 7 }}
              className="mx-auto"
            >
              <Row className="mt-4 ">
                <H2Styles>Dados Pessoais</H2Styles>
                <Form.Group className="mt-2 mb-3" controlId="formBasicEmail">
                  <Form.Label>Nome Completo</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    value={valuesRendered.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    autoComplete="new-password"
                    aria-autocomplete="none"
                    value={valuesRendered.email}
                    disabled
                  />
                </Form.Group>
                <Row>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    as={Col}
                    xs={12}
                    md={8}
                  >
                    <Form.Label>CPF</Form.Label>
                    <Form.Control
                      type="text"
                      as={InputMask}
                      mask="999.999.999-99"
                      autoComplete="new-password"
                      aria-autocomplete="none"
                      name="cpf"
                      value={valuesRendered.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.cpf && !!errors.cpf}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.cpf}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={4}
                    className={
                      'mb-3 px-0 ' +
                      (touched.birthDate && !!errors.birthDate && 'is-invalid')
                    }
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Data de nascimento</Form.Label>
                    <br />
                    <DatePicker
                      className={
                        'form-control ' +
                        (touched.birthDate &&
                          !!errors.birthDate &&
                          'is-invalid')
                      }
                      placeholderText="dd/mm/aaaa"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale="ptBR"
                      customInput={
                        <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy" />
                      }
                      name="birthDate"
                      selected={valuesRendered.birthDate}
                      onBlur={handleBlur}
                      onChange={onChangeDatePickerChange}
                    />
                    {touched.birthDate && !!errors.birthDate && (
                      <Form.Control.Feedback type="invalid">
                        {errors.birthDate!.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    as={Col}
                    xs={12}
                    md={12}
                  >
                    <Form.Label>Telefone com DDD</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      as={InputMask}
                      mask="(99) 99999-9999"
                      value={valuesRendered.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.phone && !!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Row>

              <Row className="my-3">
                <H2Styles>Endereço</H2Styles>
                <Row>
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>CEP</Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="99999-999"
                      type="text"
                      autoComplete="new-password"
                      aria-autocomplete="none"
                      name="cep"
                      value={valuesRendered.cep}
                      onBlur={async (e: React.FocusEvent<HTMLInputElement>) =>
                        onCepBlur(e)
                      }
                      onChange={handleChange}
                      isInvalid={touched.cep && !!errors.cep}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.cep?.toString()}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Estado</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="new-password"
                      aria-autocomplete="none"
                      name="state"
                      value={valuesRendered.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.state && !!errors.state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={valuesRendered.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.city && !!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-3"
                    md={4}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Bairro</Form.Label>
                    <Form.Control
                      type="text"
                      name="neighborhood"
                      value={valuesRendered.neighborhood}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.neighborhood && !!errors.neighborhood}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.neighborhood}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={valuesRendered.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.address && !!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="new-password"
                      aria-autocomplete="none"
                      name="complement"
                      value={valuesRendered.complement}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    //isInvalid={touched.complement && !!errors.complement}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.complement?.toString()}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-2 mb-3" controlId="formBasicEmail">
                    <Form.Label>Termos de Privacidade</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={7}
                      readOnly
                      value={`Este formulário contém dados pessoais e será utilizado para identificar a intenção de participação no curso/treinamento da Fundação Miguel Couto de Ensino, Pesquisa e Extensão.
Diante disso, declaro minha ciência e autorizo a Fundação Miguel Couto a realizar a coleta, o armazenamento, o compartilhamento e o processamento dos dados pessoais informados, de acordo com a Lei nº 13.709/2018 - Lei Geral de Proteção de Dados Pessoais (LGPD).`}
                    />
                  </Form.Group>
                  <Form.Group controlId="acceptCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Li e concordo com os Termos de Privacidade do site"
                      checked={accepted}
                      onChange={handleCheckboxChange}
                    />
                  </Form.Group>
                </Row>
              </Row>
            </Col>

            <Col
              xs={{ order: 1, span: 12 }}
              xl={{ order: 2, span: 5 }}
              className="ms-auto payment-confirmation"
            >
              <PaymentConfirmationCardStyles>
                <Card.Body>
                  {!isFreeCourse && (
                    <Row className="mb-4">
                      <Col>
                        <H3Styles className="mb-3">Total</H3Styles>
                        {handleRenderTotalAmount()}
                      </Col>
                    </Row>
                  )}
                  <SecondaryButton
                    type="button"
                    onClick={() => {
                      if (isInvalid) return;
                      if (isFreeCourse || TotalAmount === 0) {
                        return onSubmitFree();
                      }

                      onSubmit();
                    }}
                    disabled={isInvalid || !accepted}
                  >
                    {isFreeCourse || TotalAmount === 0 ? 'Finalizar' : 'Pagar'}
                  </SecondaryButton>
                </Card.Body>
              </PaymentConfirmationCardStyles>

              <Row>
                <Col>
                  <div className="text-observation m-2">Compra por boleto: Confirmação em até 3 dias úteis.
                    Outras formas de pagamento: Liberação em até 24 horas
                    após a confirmação.
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </PaymentInformationFormStyles>
  );
}
