import { Modal, ModalBody } from "react-bootstrap";
import { useInstitutionalAuthContext } from "../../context/InstitutionalAuthContext";
import styled from "styled-components";
import { InstitutionalUserAuthModalStateEnum } from "../../domain/enums/InstitutionalUserAuthModalStateEnum";
import { GoogleMicrosoftModalDialogComponent } from "./InstitutionalUserAuthModal/GoogleMicrosoftModalDialogComponent";
import { CodeValidationComponent } from "./InstitutionalUserAuthModal/CodeValidationComponent";
import { BaseModalStyles, SecondaryButton } from "../../styles/GlobalStyles";

export function InstitutionalUserAuthModalComponent({ }: InstitutionalUserAuthModalComponentProps) {
    const {
        showAuthModal,
        modalState,
        onModalClose
    } = useInstitutionalAuthContext()
    return (
        <InstitutionalUserAuthModalComponentStyles
            show={showAuthModal}
            onHide={() => onModalClose()}
            backdrop='static'
            centered
        >
            <Modal.Header closeButton >
                <h2 className="d-lg-none ps-2 my-2">
                    Login
                </h2>
            </Modal.Header>
            <ModalBody>
                {
                    RenderModalBody(modalState)
                }
            </ModalBody>
        </InstitutionalUserAuthModalComponentStyles>
    );
}

export function RenderModalBody(modalState: InstitutionalUserAuthModalStateEnum) {
    switch (modalState) {
        case InstitutionalUserAuthModalStateEnum.SignIn:
            return <GoogleMicrosoftModalDialogComponent />
        case InstitutionalUserAuthModalStateEnum.CodeValidation:
            return <CodeValidationComponent />
        default:
            return <></>
    }
}

interface InstitutionalUserAuthModalComponentProps {
}

const InstitutionalUserAuthModalComponentStyles = styled(BaseModalStyles)`
    .secondRow{
        border-top:1px solid #CDCDCD;
    }
    input{
        border-radius: 6px;
        border: 1px solid #CCC;
        background: #FFF;
    }
    input{
        min-height:6vmin;
        border:none;
        border-radius: 0.6vmin;
        border: 0.1vmin solid #CCC;
        background: #FFF;
    ::placeholder,
    ::-webkit-input-placeholder {
        padding-left:1vmin;
        color: #424242;
        font-family: 'Steradian' ;
        font-size: 1.6vmin;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      
    }
    :-ms-input-placeholder {
        color: #424242;
        font-family: 'Steradian' ;
        font-size: 1.6vmin;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  }
  .form-control {
        text-align:center;
        color: #424242;
        font-family: 'Steradian' ;
        font-size: 2.5vmin;
        font-style: normal;
        font-weight: 400;
        padding:1vmin 1vmin;
        border-radius: 6px;
        border: 1px solid #CCC;
        background: #FFF;
        &:focus{
            border: 1px solid #CCC;
            box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.20)
        }
    }
    .info-detail{
        color: #757575;
        text-align: center;
        font-family: 'Steradian' ;
        font-size: 1.7vmin;
        font-style: normal;
        font-weight: 400;
    }
    .timer{
        color: #757575;
        text-align: center;
        font-family: 'Steradian';
        font-size: 2.5vmin;
        font-style: normal;
        font-weight: 700;
        line-height: 132.523%; /* 26.505px */
    }
    .form-control {
        color: #424242;
        font-family: 'Steradian' ;
        font-size: 1.7vmin;
        font-style: normal;
        font-weight: 400;
        padding:1vmin 2vmin;
        border-radius: 6px;
        border: 1px solid #CCC;
        background: #FFF;
        &:focus{
            border: 1px solid #CCC;
            box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.20)
        }
        &.is-invalid{
            border: 1px solid #dc3545;
            box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.20)
        }
    }
    .invalid-feedback{
        font-size:1.5vmin;
    }
    @media screen and (max-width: 1199px) {
        .info-detail{
            font-size: 0.9rem;
        }
        .timer{
            font-size: 1.5rem;
        }
        .invalid-feedback{
            font-size:0.8rem;
        }
        .form-control {
            font-size: 1.2rem;
        }
    }
`

export const AuthButtonStyles = styled(SecondaryButton)`
    font-size:1rem;
    i{
        font-size:2.5vmin;

    }
    @media screen and (max-width:1199px){
    font-size:1rem;
        i{
            font-size:1.5rem;

        }
    }
`

