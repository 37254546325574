import { Container } from 'react-bootstrap';
import { PurcharseFinalizationJumbotronComponent } from './PurcharseFinalizationJumbotronComponent';
import { PaymentInformationForm } from './PaymentInformationForm';

export function PurcharseFinalizationComponent({}: PurcharseFinalizationComponentProps) {
  return (
    <>
      <Container fluid>
        <PurcharseFinalizationJumbotronComponent />
        <PaymentInformationForm />
      </Container>
    </>
  );
}
interface PurcharseFinalizationComponentProps {}
