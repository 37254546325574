import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';

import { CourseTitle } from '../../styles/HomeStyles';
import { ContentCard, SeeMoreButton } from '../../styles/GlobalStyles';
import { MobileCarrouselComponent } from './MobileCarrouselComponent';
import { CourseCardViewModel } from '../../domain/models/CourseCardViewModel';

export function CourseMobileCarrouselComponent({
  courses,
}: CourseMobileCarrouselComponentProps) {
  return (
    <>
      <Row className="d-block d-xl-none pt-4">
        <Col>
          <CourseTitle>CURSOS</CourseTitle>
        </Col>
      </Row>

      <MobileCarrouselComponent>
        {courses.map((course) => (
          <ContentCard key={course.id} className="border-0">
            <Card.Img
              variant="top"
              src={course.coverImage}
              alt={course.title}
            />
            <Card.Body>
              <Card.Title className="carrousel">{course.title}</Card.Title>
              <Card.Text>
                {course.description.length > 100
                  ? course.description.substring(0, 100) + '...'
                  : course.description}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xl={7} className="pb-2">
                  <SeeMoreButton
                    as={Link}
                    to={`/curso-detalhes?courseId=${course.id}`}
                    className="btn btn-default"
                  >
                    <span className="mx-auto">Ver mais</span>
                  </SeeMoreButton>
                </Col>
              </Row>
            </Card.Footer>
          </ContentCard>
        ))}
      </MobileCarrouselComponent>
    </>
  );
}
interface CourseMobileCarrouselComponentProps {
  courses: CourseCardViewModel[];
}

