// @ts-nocheck
import Container from 'react-bootstrap/Container';
import { isMobile } from 'react-device-detect';

import BaseLayoutComponent from '../components/layouts/BaseLayoutComponent';
import { useInstitutionalContext } from '../context/InstitutionalContext';
import { SendMessageComponent } from '../components/contactUs/SendMessageComponent';
import FooterComponent from '../components/layouts/FooterComponent';
import { InstitutionalInformationComponent } from '../components/contactUs/InstitutionalInformationComponent';
import { CoverTitle } from '../components/CoverTitle';

export default function ContactUsScreen() {
  const backImage = `/images/contact_us_cover.png`;

  const { institutionalPageInfo } = useInstitutionalContext();

  if (isMobile) {
    return (
      <BaseLayoutComponent showInstitutionalInfo={false}>
        <Container fluid={true}>
          <CoverTitle
            backgroundImage={backImage}
            pageTitle="Fale conosco"
            topEmptyRow={{
              height: '30vh',
              mobileHeight: '20rem',
            }}
            bottomEmptyRow={{
              height: '15vh',
              mobileHeight: '10rem',
            }}
          />
          <section className="contactPageFooter">
            <FooterComponent
              footerPageInfo={institutionalPageInfo?.footer}
              showInstitutionalInfo={true}
            />
          </section>

          <SendMessageComponent />
        </Container>
      </BaseLayoutComponent>
    );
  }

  return (
    <BaseLayoutComponent showInstitutionalInfo={false}>
      <Container fluid={true}>
        <CoverTitle
          backgroundImage={backImage}
          pageTitle="Fale Conosco"
          topEmptyRow={{
            height: '30vh',
            mobileHeight: '20rem',
          }}
          bottomEmptyRow={{
            height: '15vh',
            mobileHeight: '10rem',
          }}
        />
        <section className="d-lg-none contactPageFooter">
          <FooterComponent
            footerPageInfo={institutionalPageInfo?.footer}
            showInstitutionalInfo={true}
          />
        </section>
        <InstitutionalInformationComponent
          phone={institutionalPageInfo!.footer.phone}
          address={institutionalPageInfo!.footer.address}
          corporateWhatsapp={institutionalPageInfo!.footer.corporateWhatsapp}
          email={institutionalPageInfo!.footer.email}
        />
        <SendMessageComponent />
      </Container>
    </BaseLayoutComponent>
  );
}
