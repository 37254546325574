import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import styled from 'styled-components';

export const EventPageTitleStyles = styled(Row)<{ backGroundImage: string }>`
  background-image: url(${(props) => props.backGroundImage});
  min-height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const EventCardStyles = styled(Col)`
  background: #fff;
  box-shadow: 0px 4px 20px rgba(66, 66, 66, 0.1);
  border-radius: 10px;

  min-width: 17vmin;
  min-height: 15vmin;

  img {
    max-width: 12vmin;
  }

  @media screen and (max-width: 1199px) {
    min-width: 162px;
    width: 200px;
    min-height: 118px;
    height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 162px;
      max-height: 100px;
    }
  }
`;

export const Card5ComponentStyles = styled(Row)`
  .tiny {
    max-height: 18vmin;
  }
`;

export const Card4ComponentStyles = styled(Row)`
  background-color: #e9e9e9;
`;

export const Card3ComponentStyles = styled(Row)`
  background-color: #424242;
  .square-image > img {
    object-fit: cover;
    max-width: 21vmin !important;
    max-height: 21vmin;
  }
  .wide-image > img {
    object-fit: cover;
    width: 44vmin !important;
    height: 21vmin;
  }
  .main-image > img {
    object-fit: cover;
    width: 22vmin;
    height: 44.5vmin;
  }

  @media screen and (max-width: 1199px) {
    padding-top: 35px;
    padding-bottom: 0;

    img {
      height: auto;
      width: 100%;
      background-position: center;
      object-fit: cover;
      padding-top: 24px;
    }

    .twoImagesSection {
      display: flex;
      .col-md-auto {
        width: 50%;
      }
    }
  }
`;

export const Card2ComponentStyles = styled(Row)`
  .textColCard {
    padding-right: 46px;
  }

  img {
    width: 60vmin;
    height: 30vmin;
  }
  .square-image {
    object-fit: cover;
    width: 19vmin;
    height: 19vmin;
  }
  .wide-image {
    object-fit: cover;
    width: 40vmin;
    height: 19vmin;
  }
  .main-image {
    object-fit: cover;
    width: 40vmin;
    height: 40vmin;
  }
  a {
    text-decoration: none;
    width: 20vmin;
    height: 5vmin;

    margin-left: 2vmin;
    padding: 15px 50px;

    background: #000;
    border-radius: 10vmin;

    font-family: 'Steradian', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2vmin;
    line-height: 3vmin;
    text-align: center;
    color: #fff;

    &:hover {
      text-decoration: underline;
      background-color: #fff;
      color: #000;
    }
  }

  @media screen and (max-width: 1199px) {
    .textColCard {
      padding-right: 0;
    }
    padding-top: 35px;
    padding-bottom: 0;

    img {
      height: auto !important;
      width: 100% !important;
      background-position: center;
      object-fit: cover;
      padding-top: 24px;
    }

    .twoImagesSection {
      display: flex !important;
      .col-md-auto {
        width: 50%;
      }
    }
  }
`;

export const Card1Styles = styled(Row)`
  padding-top: 8vmin;
  padding-bottom: 8vmin;
  background-color: #424242;
  color: #fff !important;

  .main-image {
    height: 37vmin;
    width: 45vmin;
    background-position: center;
    object-fit: cover;
    object-position: -5vmin;
  }
  .tiny-image {
    height: 17.8vmin;
    width: 17.8vmin;
    background-position: center;
    object-fit: cover;
  }
  .wide-image {
    height: 18vmin;
    width: 38.5vmin;
    background-position: center;
    object-fit: cover;
  }

  @media screen and (max-width: 1199px) {
    padding-top: 35px;
    padding-bottom: 0;

    img {
      height: auto !important;
      width: 100% !important;
      background-position: center;
      object-fit: cover;
      padding-top: 24px;
    }

    .twoImagesSection {
      display: flex !important;
      .col-md-auto {
        width: 50%;
      }
    }
  }
`;

export const AllEventsSectionStyles = styled(Row)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const NewEventSectionStyles = styled(Row)`
  padding-top: 8vmin;
  padding-bottom: 8vmin;

  background-color: blue;

  h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 1rem;
  }

  span,
  p {
    text-align: justify;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
  }

  ol {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    span,
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 1199px) {
    padding-top: 35px;
    padding-bottom: 0;

    img {
      height: auto !important;
      width: 100% !important;
      background-position: center;
      object-fit: cover;
      padding-top: 24px;
    }

    .twoImagesSection {
      display: flex !important;
      .col-md-auto {
        width: 50%;
      }
    }
  }
`;
