import { Card, Col, Row } from 'react-bootstrap';

import { useInstitutionalContext } from '../../context/InstitutionalContext';
import { H1Styles, SubTitleStyles } from '../../styles/GlobalStyles';
import { EventCardStyles } from './styles';
import { DocumentCard } from '../../styles/TransparencyPortalStyles';

interface EventSuport {
  id: number;
  title: string;
  image: string;
}

export const EventDevelopmentComponent = ({ documentsData }: any) => {
  const { institutionalPageInfo } = useInstitutionalContext();

  const EventSuports: EventSuport[] = [
    {
      id: 1,
      title: 'Gerenciamento de ​Inscrições',
      image: '/images/events/gerenciamento-inscricoes.png',
    },
    {
      id: 2,
      title: 'Atendimento aos ​Participantes',
      image: '/images/events/atendimento-participantes.png',
    },
    {
      id: 3,
      title: 'Negociação e Gestão ​de Patrocínios',
      image: '/images/events/negociacao-gestao.png',
    },
    {
      id: 4,
      title: 'Contratação de ​Fornecedores e Pessoal',
      image: '/images/events/contratacao-de-pessoal.png',
    },
    {
      id: 5,
      title: 'Previsão orçamentária e ​Prestação de Contas',
      image: '/images/events/previsao-orcamentaria.png',
    },
    {
      id: 6,
      title: 'Análise de contratos ​de Patrocínios e de ​Fornecedores',
      image: '/images/events/analise-de-contratos.png',
    },
    {
      id: 7,
      title: 'Auxílio da divulgação ​do Evento nos canais ​da Fundação',
      image: '/images/events/auxilio-da-divulgacao.png',
    },
  ];

  return (
    <Row className="my-2 my-xl-5 pb-3">
      <Col xl={8} className="mx-auto">
        <Row>
          <Col xs={11} xl={6} className="mx-auto text-xl-center">
            <H1Styles className="mt-3">
              {institutionalPageInfo?.events.description}
            </H1Styles>
          </Col>
        </Row>

        <Row>
          <Col xs={11} xl={9} className="mx-auto">
            <Row className="d-flex justify-content-center">
              {EventSuports.map((item) => {
                return (
                  <Col
                    xs={6}
                    md={4}
                    xl={3}
                    className="mt-4 mt-xl-5"
                    key={item.id}
                  >
                    <Row className="mx-auto">
                      <EventCardStyles xl={10} className="mx-auto">
                        <Row className="align-middle d-flex my-auto h-100">
                          <Col xl="auto" className="mx-auto my-auto">
                            <img src={item.image} alt={item.title} />
                          </Col>
                        </Row>
                      </EventCardStyles>
                    </Row>
                    <Row>
                      <Col xl={11} className="mx-auto text-xl-center mt-2">
                        <SubTitleStyles className="mx-auto text-center">
                          {item.title}
                        </SubTitleStyles>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
            <Row className="mt-3 mt-xl-5">
              {documentsData?.map((document: any) => {
                return (
                  <Col xl={6} className="mx-auto" key={document?.title}>
                    <DocumentCard
                      href={document?.link}
                      target="blank"
                      download
                      className="download"
                    >
                      <Card className="border-0">
                        <Card.Body>
                          <Card.Title>{document?.title}</Card.Title>
                        </Card.Body>
                      </Card>
                    </DocumentCard>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
