import { Col, Row } from 'react-bootstrap';
import { ChevronLeft } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { MainJumbotron } from '../../styles/CourseDetailsStyles';
import {
  EmptyRow,
  PageDescription,
  PageTitle,
} from '../../styles/GlobalStyles';
import { CourseDetailsViewModel } from '../../domain/models/Course';

interface CourseDetailMainJumbotronComponentProps {
  course: CourseDetailsViewModel;
}

export function CourseDetailMainJumbotronComponent({
  course,
}: CourseDetailMainJumbotronComponentProps) {
  return (
    <Row>
      <MainJumbotron backgroundImage={course.coverImage}>
        <EmptyRow height="30vh" mobileHeight="16rem" />
        <Row>
          <Col xs={11} xl={8} className="mx-auto">
            <PageDescription>
              <Link
                to="/cursos"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <ChevronLeft /> {'Voltar para cursos'}
              </Link>
            </PageDescription>
            <br />
            <PageTitle>{course.title}</PageTitle>
          </Col>
        </Row>
        <EmptyRow height="20vh" mobileHeight="6rem" />
      </MainJumbotron>
    </Row>
  );
}
