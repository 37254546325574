import { Col, Container, Row } from "react-bootstrap";
import { AuthButtonStyles } from "../InstitutionalUserAuthModalComponent";
import { useInstitutionalAuthContext } from "../../../context/InstitutionalAuthContext";
import { TailSpin } from "react-loader-spinner";
import { H2Styles } from "../../../styles/GlobalStyles";

export function GoogleMicrosoftModalDialogComponent() {
    const {
        signUpWithMicrosoft,
        signUpWithGoogle,
        isIndentifing
    } = useInstitutionalAuthContext()

    return (
        <>
            {
                isIndentifing?
                (<Container>
                    <Row style={{minHeight:'20vh'}}>
                        <Col xs="auto" className="mx-auto my-auto">
                            <TailSpin
                                color={"#72b81f"}
                                height={70}
                                width={70}
                            />
                        </Col>
                    </Row>
                </Container>)
                :
                (<Container>
                    <Row>
                        <Col>
                            <Row>
                                <h2 className="d-none d-lg-block my-2">
                                    Login
                                </h2>
                            </Row>
                            <Row>
                                <span className="mb-2">
                                    Autentique-se com a sua conta
                                </span>
                            </Row>
                            <Row>
                                <Col xs={12} lg={6} className="my-2 my-lg-4">
                                    <AuthButtonStyles className="w-100 text-center " onClick={()=>signUpWithMicrosoft()}>
                                        <Row>
                                            <Col xs='auto' className="mx-auto pt-lg-1">
                                                <Row>
                                                    <Col xs='auto' className="my-auto">
                                                        <i className="bi bi-microsoft"/>
                                                    </Col>
                                                    <Col xs='auto' className="my-auto">
                                                        Microsoft
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </AuthButtonStyles>
                                </Col>
                                <Col xs={12} lg={6}  className="my-2 my-lg-4">
                                    <AuthButtonStyles className="w-100 text-center " onClick={()=>signUpWithGoogle()}>
                                        <Row>
                                            <Col xs='auto' className="mx-auto pt-lg-1">
                                                <Row>
                                                    <Col xs='auto' className="my-auto">
                                                        <i className="bi bi-google me-2"/>
                                                    </Col>
                                                    <Col xs='auto' className="my-auto">
                                                        Google
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </AuthButtonStyles>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>)
            }
        </>
        );
}




