import { Row, Col } from 'react-bootstrap';
import { TailSpin } from 'react-loader-spinner';

import CourseCaroussel from '../course/CourseCaroussel';
import { CourseTitle, HomeMainJumbotron } from '../../styles/HomeStyles';
import {
  EmptyRow,
  PageDescription,
  PageTitle,
} from '../../styles/GlobalStyles';
import { useInstitutionalContext } from '../../context/InstitutionalContext';

export function MainJumbotron() {
  const { institutionalPageInfo, courses, getHomeCourses } =
    useInstitutionalContext();

  return (
    <Row>
      {/* Desktop */}
      <HomeMainJumbotron
        className="d-none d-xl-block"
        backgroundImage={institutionalPageInfo!.home.imageInstitutional}
      >
        <EmptyRow className="d-none d-xl-block" height="30vh" />
        <EmptyRow className="d-xl-none" height="39vh" />
        <Row>
          <Col xs={7} xl={8} className="mx-xl-auto">
            <PageTitle>Fundação Miguel Couto</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col xl={8} className="mt-3 mt-xl-none mx-xl-auto">
            <PageDescription>
              {institutionalPageInfo!.home.institutional}
            </PageDescription>
          </Col>
        </Row>
        <EmptyRow height="10vh" />
        <Row className="d-none d-xl-block">
          <Col xl={8} className="mx-auto">
            <Row>
              <CourseTitle className="mb-3">CURSOS</CourseTitle>
            </Row>
            <Row
              style={{
                minHeight: '40vh',
              }}
            >
              {courses.length > 0 ? (
                <CourseCaroussel courses={courses} />
              ) : (
                <Col xl="auto" className="mx-auto my-auto">
                  <TailSpin color={'#72b81f'} height={100} width={100} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </HomeMainJumbotron>

      {/* Tablets */}
      <HomeMainJumbotron
        className="d-none d-md-block d-xl-none"
        backgroundTablet={'/mobile/home/home-header-tablet.png'}
      >
        <EmptyRow className="d-xl-none" height="39vh" mobileHeight="20rem" />
        <Row>
          <Col xs={7} className="mx-xl-auto">
            <PageTitle>Fundação Miguel Couto</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col xl={8} className="mt-3 mt-xl-none mx-xl-auto">
            <PageDescription>
              {institutionalPageInfo!.home.institutional}
            </PageDescription>
          </Col>
        </Row>
        <EmptyRow height="15vh" mobileHeight="12rem" />
      </HomeMainJumbotron>

      {/* Mobile Only */}
      <HomeMainJumbotron
        className="d-md-none"
        backgroundMobile={'/mobile/home/home-header-mobile.jpg'}
      >
        <EmptyRow className="d-xl-none" mobileHeight="18rem" />
        <Row>
          <Col xs={8}>
            <PageTitle>Fundação Miguel Couto</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col xl={8} className="mt-3">
            <PageDescription>
              {institutionalPageInfo!.home.institutional}
            </PageDescription>
          </Col>
        </Row>
        <EmptyRow height="15vh" mobileHeight="6rem" />
      </HomeMainJumbotron>
    </Row>
  );
}
