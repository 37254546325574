import { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { BrowserView } from 'react-device-detect';

import { H3Styles, PBlackStyles } from '../../styles/GlobalStyles';
import { DocumentCard, TopicStyles } from '../../styles/TransparencyPortalStyles';
import { CoverTitle } from '../CoverTitle';
import { PageLoader } from '../Loader';
import { handleFormatTextToBrokeLine } from '../../utils/functions';
import InstitutionalService from '../../services/InstitutionalService';

export function TransparencyPortalComponent() {
  const institutionalService = new InstitutionalService();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [topicDocument, setTopicDocument] = useState<any>({});

  const backImage = `/images/transparency_portal_cover.png`;

  const cards: { title: string; link: string }[] = [];
  if (data) {
    data.document_name?.map((item: any, index: number) => {
      return cards.push({
        title: item,
        link:
          process.env.REACT_APP_WORDPRESS_API + data?.documents[index]?.guid,
      });
    });
  }
  function redirectDonload(link: string) {
    const url = process.env.REACT_APP_WORDPRESS_API + link;
    window.open(url, '_blank');
  }

  function filtrarObjeto(objeto: any) {
    const novoObjeto: any = {};
    for (const chave in objeto) {
      if (chave.startsWith('_') && chave !== '_links') {
        const novoNome = chave
        // .substring(1) // Exclui o primeiro _
        // .replace(/_/g, ' ') // Substitui _ por espaço
        // .replace(/\b\w/g, (match) => match.toUpperCase()); // Primeira letra de cada palavra maiúscula

        novoObjeto[novoNome] = objeto[chave];
      }
    }
    return novoObjeto;
  }

  useEffect(() => {
    const getPortalData = async () => {
      try {
        setLoading(true);
        let response: any = await institutionalService.getWordpressData(
          '/portal_transparency'
        );
        if (response?.result) {
          const objetoFiltrado = filtrarObjeto(JSON.parse(response.result)[0])
          setTopicDocument(objetoFiltrado)
          setData(JSON.parse(response.result)[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Erro ao tentar pegar eventos da API Wordpress. Erro => ',
          error
        );
      }
    };
    getPortalData();
  }, []);

  return (
    <Container fluid={true}>
      <CoverTitle
        backgroundImage={backImage}
        pageTitle={data?.title?.rendered ?? 'Portal da Transparência'}
        topEmptyRow={{
          height: '25vh',
          mobileHeight: '20rem',
        }}
        bottomEmptyRow={{
          height: '20vh',
          mobileHeight: '10rem',
        }}
      />

      {!data || isLoading ? (
        <PageLoader />
      ) : (
        <Row>
          <Col xl={8} className="mx-auto mt-5 insitutionalPortalMainCol">
            <Row>
              <Col className="mt-5 mb-5 insitutionalPortalDescriptionCol">
                <PBlackStyles
                  className="transparencyPortal"
                  dangerouslySetInnerHTML={{
                    __html: handleFormatTextToBrokeLine(data?.page_description),
                  }}
                />
              </Col>
            </Row>

            <BrowserView>
              <Row>
                <Col className="mt-3 mb-5">
                  <H3Styles>Documentos disponíveis pra download</H3Styles>
                </Col>
              </Row>
            </BrowserView>
            <Row className="mb-5">
              <H3Styles className="mt-2">Estatuto</H3Styles>
              {Object.keys(topicDocument).map((topico, index) => (
                <div key={index}>
                  {topicDocument[topico].map((subtopico: any) => {
                    if (topico === '_estatuto') {
                      return (
                        <ul className='mb-4'>
                          <li className='m-3'>
                            <TopicStyles onClick={() => redirectDonload(subtopico.guid)}>{subtopico.post_title.charAt(0).toUpperCase() + subtopico.post_title.slice(1)}</TopicStyles>
                          </li>
                        </ul>
                      )
                    }
                  })}
                </div>
              ))}

              <H3Styles className="mt-2">Atas de Posse</H3Styles>
              {Object.keys(topicDocument).map((topico, index) => (
                <div key={index}>
                  {topicDocument[topico].map((subtopico: any) => {
                    if (topico === '_atas_de_posse') {
                      return (
                        <ul className='mb-4'>
                          <li className='m-3'>
                            <TopicStyles onClick={() => redirectDonload(subtopico.guid)}>{subtopico.post_title.charAt(0).toUpperCase() + subtopico.post_title.slice(1)}</TopicStyles>
                          </li>
                        </ul>
                      )
                    }
                  })}
                </div>
              ))}


              <H3Styles className="mt-2">Certidões</H3Styles>
              {Object.keys(topicDocument).map((topico, index) => (
                <div key={index}>
                  {topicDocument[topico].map((subtopico: any) => {
                    if (topico === '_certidoes') {
                      return (
                        <ul className='mb-4'>
                          <li className='m-3'>
                            <TopicStyles onClick={() => redirectDonload(subtopico.guid)}>{subtopico.post_title.charAt(0).toUpperCase() + subtopico.post_title.slice(1)}</TopicStyles>
                          </li>
                        </ul>
                      )
                    }
                  })}
                </div>
              ))}

              <H3Styles>Balanço Patrimonial</H3Styles>
              {Object.keys(topicDocument).map((topico, index) => (
                <div key={index}>
                  {topicDocument[topico].map((subtopico: any) => {
                    if (topico === '_balanco_patrimonial') {
                      return (
                        <ul className='mb-4'>
                          <li className='m-3'>
                            <TopicStyles onClick={() => redirectDonload(subtopico.guid)}>{subtopico.post_title.charAt(0).toUpperCase() + subtopico.post_title.slice(1)}</TopicStyles>
                          </li>
                        </ul>
                      )
                    }
                  })}
                </div>
              ))}


              <H3Styles className="mt-2">Parecer Auditoria Independente</H3Styles>
              {Object.keys(topicDocument).map((topico, index) => (
                <div key={index}>
                  {topicDocument[topico].map((subtopico: any) => {
                    if (topico === '_parecer_auditoria_independente') {
                      return (
                        <ul className='mb-4'>
                          <li className='my-3'>
                            <TopicStyles onClick={() => redirectDonload(subtopico.guid)}>{subtopico.post_title.charAt(0).toUpperCase() + subtopico.post_title.slice(1)}</TopicStyles>
                          </li>
                        </ul>
                      )
                    }
                  })}
                </div>
              ))}
              <H3Styles className="mt-2">Parecer de Conselho Fiscal</H3Styles>
              {Object.keys(topicDocument).map((topico, index) => (
                <div key={index}>
                  {topicDocument[topico].map((subtopico: any) => {
                    if (topico === '_parecer_de_conselho_fiscal') {
                      return (
                        <ul className='mb-4'>
                          <li className='my-3'>
                            <TopicStyles onClick={() => redirectDonload(subtopico.guid)}>{subtopico.post_title.charAt(0).toUpperCase() + subtopico.post_title.slice(1)}</TopicStyles>
                          </li>
                        </ul>
                      )
                    }
                  })}
                </div>
              ))}



              {/* {cards?.map((document, index) => (
                <Col xs={12} md={12} xl={4} key={document.title}>
                  <DocumentCard
                    href={document.link}
                    target="blank"
                    download
                    className="download"
                  >
                    <Card key={index} className="border-0">
                      <Card.Body>
                        <Card.Title>
                          {document.title}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </DocumentCard>
                </Col>
              ))} */}
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
}
