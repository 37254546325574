import React from "react";
import { Row, Col, Card } from 'react-bootstrap';
import { CardStyles, H2Styles, PGreyStyles } from '../../styles/GlobalStyles';
import styled from "styled-components";
import { FormatWorkload } from "../../domain/util/FormatWorkload";
import { CourseDetailsViewModel } from "../../domain/models/Course";

export function CourseCartCardComponent({ course }: CourseCartCardComponentProps) {
  return (
    <CartCardStyles>
      <Card.Body>
        <Row>
          <Col xs={12} className="d-lg-none">
            <img src={course.coverImage} />
          </Col>
          <Col className="px-4 py-3 py-md-4 px-md-5">
            <Row>
              <H2Styles>
                {course.title}
              </H2Styles>
            </Row>
            <Row>
              <PGreyStyles>
                {course.description}
              </PGreyStyles>
            </Row>
            <Row>
              <span><i className="bi bi-alarm me-2" /> {FormatWorkload(course.workLoad)}</span>
            </Row>
          </Col>
          <Col lg={5} className="d-none d-lg-flex">
            <img src={course.coverImage} />
          </Col>
        </Row>
      </Card.Body>
    </CartCardStyles>);
}
interface CourseCartCardComponentProps {
  course: CourseDetailsViewModel;
}
const CartCardStyles = styled(CardStyles)`
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    padding:0px;
    .card-body{
        padding:0px
        
    }
    span{
        text-align: justify;
        font-weight: 600;
        font-size: 1.9vmin;
        line-height: 30px;
        color: #424242;
    }
    img{
        object-fit:cover;
        width:100%;
        height: 26.1vmin;
        border-radius: 0px 1vmin 1vmin 0px;
    }
    @media screen and (max-width:1199px){
        h2{
            font-size: 1.3rem;

        }
        p{
            font-size: 1rem;

        }
        span{
            font-size: 1rem!important;
            i{
                font-size: 1.2rem;
            }
        }
        img{
            object-fit:cover;
            width:100%;
            height: 10rem;
            border-radius: 0.5rem 0.5rem 0px 0px;
        }
    }
    @media screen and (max-width:899px){
        h2{
            font-size: 1.3rem;

        }
        p{
            font-size: 1rem;

        }
        span{
            font-size: 1rem!important;
            i{
                font-size: 1.2rem;
            }
        }
        img{
            object-fit:cover;
            width:100%;
            height: 8rem;
            border-radius: 0.5rem 0.5rem 0px 0px;
        }
    }
`;
