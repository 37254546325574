import { Row } from 'react-bootstrap';
import { MobileView, isMobile } from 'react-device-detect';
import { BsChevronLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export interface MobileTopRedirectProps {
  url: string;
  goBackText: string;
}

export const MobileTopRedirect = ({
  url,
  goBackText,
}: MobileTopRedirectProps) => {
  const stylesBack: any = {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 400,
    width: '80%',
    letterSpacing: '2.17px',
    marginBottom: '21px',
    textDecoration: 'unset',
    color: '#fff',
  };

  return (
    <MobileView>
      <Row>
        <Link to={url} style={{ ...stylesBack }}>
          <BsChevronLeft color="#fff" size={32} />
          {goBackText}
        </Link>
      </Row>
    </MobileView>
  );
};
