import { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import {
  CardStyles,
  H1Styles,
  H3Styles,
  InstitutionalInformationIconDiv,
  PBlackStyles,
} from '../../styles/GlobalStyles';
import { DocumentCard } from '../../styles/TransparencyPortalStyles';
import { useInstitutionalContext } from '../../context/InstitutionalContext';
import { CoverTitle } from '../CoverTitle';
import InstitutionalService from '../../services/InstitutionalService';
import { PageLoader } from '../Loader';
import { handleFormatTextToBrokeLine } from '../../utils/functions';

export function CoremuComponent() {
  const { institutionalPageInfo } = useInstitutionalContext();
  const institutionalService = new InstitutionalService();
  const [coremuData, setCoremuData] = useState<any>();
  const [isLoading, setLoading] = useState(false);

  const cardsPositions: { title: string; description: string }[] = [];
  const cardsDocuments: { title: string; link: string }[] = [];

  if (coremuData) {
    coremuData?.name_position?.map((item: any, index: number) => {
      return cardsPositions.push({
        title: item,
        description: coremuData.position[index],
      });
    });
  }

  if (coremuData) {
    coremuData?.documents_title?.map((item: any, index: number) => {
      return cardsDocuments.push({
        title: item,
        link:
          process.env.REACT_APP_WORDPRESS_API +
          coremuData?.documents[index]?.guid,
      });
    });
  }

  useEffect(() => {
    const getCoremuData = async () => {
      try {
        setLoading(true);
        let response: any =
          await institutionalService.getWordpressData('/ensino');
        if (response?.result) {
          setCoremuData(JSON.parse(response.result)[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(
          'Erro ao tentar pegar COREMU da API Wordpress. Erro => ',
          error
        );
      }
    };
    getCoremuData();
  }, []);

  if (institutionalPageInfo === undefined) {
    return <></>;
  }

  const backImage = institutionalPageInfo?.teaching.image;
  return (
    <Container fluid={true}>
      <CoverTitle
        backgroundImage={backImage}
        pageTitle="COREMU"
        topEmptyRow={{
          height: '25vh',
          mobileHeight: '16rem',
        }}
        bottomEmptyRow={{
          height: '20vh',
          mobileHeight: '8rem',
        }}
        hasGoBack
        goBackOptions={{
          text: 'Voltar para Residência Multiprofissional',
          url: '/ensino',
        }}
      />

      {isLoading || !coremuData ? (
        <PageLoader />
      ) : (
        <>
          <Row>
            <Col
              xl={8}
              className={
                isMobile ? 'insitutionalPortalMainCol mb-0' : 'mx-auto mt-5'
              }
            >
              <Row>
                <Col className="insitutionalPortalMainHeading">
                  <H1Styles>{coremuData?.coremu_subtitle}</H1Styles>
                </Col>
              </Row>
              <Row>
                <Col className={isMobile ? 'mb-0' : 'mt-3 mb-5'}>
                  <PBlackStyles
                    dangerouslySetInnerHTML={{
                      __html: handleFormatTextToBrokeLine(
                        coremuData?.coremu_description
                      ),
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              xl={8}
              className={isMobile ? 'insitutionalPortalMainCol' : 'mx-auto '}
            >
              <Row>
                <Col className="">
                  <H1Styles>{coremuData?.title_member}</H1Styles>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3 mb-s">
                  <PBlackStyles>
                    <strong>{coremuData?.subtitle_member}</strong>
                  </PBlackStyles>
                </Col>
              </Row>
              <Row>
                {cardsPositions?.map((item) => {
                  return (
                    <Col md={6} className="p-3 mx-auto" key={item.title}>
                      <CoremuMemberCard>
                        <Card.Body>
                          <Row className="h-100">
                            <Col className="my-auto">
                              <Row>
                                <h3>{item.title}</h3>
                              </Row>
                              <Row>
                                <span>{item.description}</span>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </CoremuMemberCard>
                    </Col>
                  );
                })}
              </Row>

              <Row className="my-5">
                {cardsDocuments?.map((document) => {
                  return (
                    <Col md={6} lg={4}>
                      <DocumentCard
                        href={document?.link}
                        target="blank"
                        download
                        className="download"
                        key={document?.title}
                      >
                        <Card className="border-0">
                          <Card.Body>
                            <Card.Title>{document?.title}</Card.Title>
                          </Card.Body>
                        </Card>
                      </DocumentCard>
                    </Col>
                  );
                })}
              </Row>

              <Row className="my-5">
                <Col>
                  <Row className="mb-4">
                    <H1Styles>Contato</H1Styles>
                  </Row>

                  <ContactCoremuSection>
                    <section>
                      <InstitutionalInformationIconDiv color="#72B81F">
                        <Row className="h-100">
                          <Col
                            xl="auto"
                            className="mx-auto my-auto d-flex justify-content-center"
                          >
                            <i className="bi bi-at" />
                          </Col>
                        </Row>
                      </InstitutionalInformationIconDiv>
                      <div>
                        <H3Styles>E-MAIL</H3Styles>

                        <PBlackStyles>
                          coremu@fundacaomiguelcouto.com.br
                        </PBlackStyles>
                      </div>
                    </section>

                    <br />

                    <section>
                      <InstitutionalInformationIconDiv color="#72B81F">
                        <Row className="h-100">
                          <Col
                            xl="auto"
                            className="mx-auto my-auto d-flex justify-content-center"
                          >
                            <i className="bi bi-telephone" />
                          </Col>
                        </Row>
                      </InstitutionalInformationIconDiv>
                      <div>
                        <H3Styles>TELEFONE</H3Styles>

                        <PBlackStyles>+55 (67) 3318-8827</PBlackStyles>
                      </div>
                    </section>
                  </ContactCoremuSection>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

const ContactCoremuSection = styled.section`
  section {
    display: flex;
    gap: 20px;

    p {
      word-break: break-all;
    }
  }

  @media screen and (max-width: 1199px) {
  }
`;

const CoremuMemberCard = styled(CardStyles)`
  .h-100 {
    min-height: 168px;
  }
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  h3 {
    color: #000;
    text-align: center;

    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
  }
  span {
    color: #000;
    text-align: center;

    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
  }

  /* Mobile */
  @media screen and (max-width: 600px) {
    h3 {
      font-size: 16px;
      line-height: 20px;
    }

    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
